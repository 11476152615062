import Script from "next/script";

const ToltLoader = () => {
  return (
    <Script
      strategy="lazyOnload"
      src="https://cdn.tolt.io/tolt.js"
      data-tolt="24f06c66-25cd-4ea9-9193-7da75ca629b5"
    />
  );
};
export default ToltLoader;
