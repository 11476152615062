import { useCallback, useEffect, useRef, useState } from "react";

import LockIcon from "@mui/icons-material/Lock";
import Image from "next/image";
import { signOut, useSession } from "next-auth/react";

import Button from "@musicfy/components/Button";
import Dialog from "@musicfy/components/Dialog";
import Icon from "@musicfy/components/Icon";
import Input from "@musicfy/components/Input";
import { useToastContext } from "@musicfy/libs/ToastProvider";
import { api } from "@musicfy/utils";

import SubscriptionDetails from "./SubscriptionDetails";
import { type TSettingsPanel } from "../../types";

interface BasicInfoProps {
  setActivePanel: (panel: TSettingsPanel) => void;
  onClose: () => void;
}

const isValidName = (name: string): boolean => {
  const pattern = /^[a-zA-Z-' ]+$/;
  if (!name) {
    return false;
  }
  if (!pattern.test(name)) {
    return false;
  }
  return true;
};

const BasicInfo = ({
  setActivePanel,
  onClose,
}: BasicInfoProps): JSX.Element | null => {
  const { sendToastMessage } = useToastContext();
  const { data, update, status } = useSession();
  const user = data?.user;

  const [name, setName] = useState<string>(user?.name || "");
  const [nameError, setNameError] = useState<string>("");
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] =
    useState<boolean>(false);

  const nameRef = useRef<string>(user?.name || "");

  const updateUserMutation = api.users.updateUser.useMutation({
    onSuccess: () => {
      update();
      sendToastMessage("Name updated successfully");
    },
  });

  const updateName = useCallback(
    (name: string, upload = false) => {
      setName(name);
      if (upload && name.trim() !== nameRef.current.trim()) {
        if (!isValidName(name)) {
          setNameError("Please enter a valid name");
          return;
        }
        updateUserMutation.mutate({
          name: name.trim(),
        });
      }
    },
    [updateUserMutation]
  );

  const onDeleteAccount = useCallback(() => {
    signOut({ callbackUrl: "/" });
    setIsConfirmDeleteOpen(false);
  }, []);

  useEffect(() => {
    if (status === "unauthenticated") {
      onClose();
    }
  }, [onClose, status]);

  if (!user || status === "loading") {
    return null;
  }

  return (
    <>
      <div className="flex flex-col items-center xl:items-start">
        <div className="cursor-pointer flex items-center gap-3">
          <Icon name="logo" className="w-6" />
          <Icon name="logo-text" />
        </div>
        <div className="text-32 mt-6 text-center font-medium xl:hidden">
          Account Settings:
        </div>
      </div>

      <div className="xl:mt-9 mt-6 w-full rounded-8 border border-white/15 lg:p-12 p-6 bg-gradient-to-b from-navy/20 to-navy">
        <div className="text-32 font-medium xl:block hidden">
          Account Settings:
        </div>

        <div className="xl:mt-9 flex flex-col items-center gap-4 xl:flex-row xl:gap-7">
          <div className="w-16 h-16 flex items-center justify-center overflow-hidden rounded-8">
            {!!user.image ? (
              <Image
                unoptimized
                src={user.image}
                alt="User Avatar"
                width={0}
                height={0}
                referrerPolicy="no-referrer"
                className="rounded-full w-full h-full transition-all before:absolute before:left-2 before:top-2 before:h-8 before:w-8 before:rounded-4 before:bg-purple before:content-['']"
              />
            ) : (
              <div className="rounded-4 font-bold flex items-center justify-center w-full h-full bg-purple">
                {user.name?.charAt(0) ?? ""}
              </div>
            )}
          </div>
          <div className="text-center xl:text-left">
            <div className="xl:subheading text-18">{user.name}</div>
            <div
              role="button"
              tabIndex={0}
              className="subtext cursor-pointer transition-all xl:hover:text-white/90 mt-2"
            >
              Change Photo
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-9 mt-9">
          <div className="flex flex-col xl:flex-row xl:items-center xl:justify-between gap-4">
            <div className="text-20 text-gray-500 whitespace-nowrap w-1/3">
              Full Name
            </div>
            <div className="w-full">
              <Input
                containerClassName="w-full"
                value={name}
                placeholder="John Smith"
                disabled={updateUserMutation.isLoading}
                onChange={(e) => updateName(e.target.value)}
                onBlur={(e) => updateName(e.target.value, true)}
                onSubmit={() => updateName(name, true)}
                errorMessage={nameError}
              />
            </div>
          </div>

          <div className="flex flex-col xl:flex-row xl:items-center xl:justify-between gap-4">
            <div className="text-20 text-gray-500 whitespace-nowrap w-1/3">
              Email
            </div>
            <div className="w-full">
              <Input
                postIcon={<LockIcon />}
                value={user.email || ""}
                disabled
              />
            </div>
          </div>

          <SubscriptionDetails setActivePanel={setActivePanel} />
        </div>
      </div>
      <div className="w-fit ml-auto mt-6 mr-2">
        <Button
          variant="text"
          style="danger"
          onClick={() => setIsConfirmDeleteOpen(true)}
        >
          Delete Account
        </Button>
      </div>
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        title="Confirm Account Deletion"
        subtitle="Are you sure you want to delete your account? We will keep this account active for the next 48 hours. After that, your account will be permanently deleted."
        primaryButton={
          <Button style="danger" fullWidth onClick={onDeleteAccount}>
            Delete
          </Button>
        }
        secondaryButton={
          <Button
            style="tertiary"
            fullWidth
            onClick={() => setIsConfirmDeleteOpen(false)}
          >
            Cancel
          </Button>
        }
      />
    </>
  );
};

export default BasicInfo;
