import BasicInfo from "./panels/BasicInfo";
import Payments from "./panels/Payments";

export enum SettingsPanel {
  general = "general",
  payments = "payments",
}

export type TSettingsPanel = `${SettingsPanel}`;

export interface ISettingsPanelInfo {
  title: string;
  Panel: React.FC<{
    setActivePanel: (panel: TSettingsPanel) => void;
    onClose: () => void;
  }>;
}

export const SETTINGS_PANEL_INFO: Record<TSettingsPanel, ISettingsPanelInfo> = {
  [SettingsPanel.general]: {
    title: "Account Settings",
    Panel: BasicInfo,
  },
  [SettingsPanel.payments]: {
    title: "Payment History",
    Panel: Payments,
  },
};

export const isSettingsPanel = (panel: string): panel is TSettingsPanel => {
  return Object.keys(SettingsPanel).includes(panel);
};
