import { useCallback, useState } from "react";

import axios from "axios";

import { env } from "@musicfy/env.mjs";
import { api } from "@musicfy/utils/api";

enum EBucketsProd {
  AUDIO_DATASETS = "musicfy-audio-datasets",
  STEM_CLIPS = "musicfy-stem-clips",
  AUDIO_CLIPS = "musicfy-audio-clips",
  AUDIO_CLIPS_SAVED = "musicfy-audio-clips-saved",
  STEM_CLIPS_SAVED = "musicfy-stem-clips-saved",
  THUMBNAILS = "model-images",
  MODELS = "musicfy-models",
  AUDIO_PREVIEWS = "musicfy-audio-previews",
  VIDEOS = "musicfy-videos",
}
enum EBucketsDev {
  AUDIO_DATASETS = "musicfy-audio-datasets-dev",
  AUDIO_CLIPS = "musicfy-audio-clips-dev",
  STEM_CLIPS = "musicfy-stem-clips-dev",
  AUDIO_CLIPS_SAVED = "musicfy-audio-clips-saved-dev",
  STEM_CLIPS_SAVED = "musicfy-stem-clips-saved-dev",
  THUMBNAILS = "model-images-dev",
  MODELS = "musicfy-models-dev",
  AUDIO_PREVIEWS = "musicfy-audio-previews-dev",
  VIDEOS = "musicfy-videos-dev",
}

export enum EPublicBucketsProd {
  IMAGES = "https://images.musicfy.lol/",
  AUDIO_PREVIEW = "https://audio.musicfy.lol/",
}
enum EPublicBucketsDev {
  IMAGES = "https://pub-3fb9a0f356284991a6988d2e2ce7959e.r2.dev/",
  AUDIO_PREVIEW = "https://pub-98b5432464ac430ea549181cef1d9407.r2.dev/",
}

export const Buckets =
  env.NEXT_PUBLIC_VERCEL_ENV === "production" ? EBucketsProd : EBucketsDev;
export const PublicBuckets =
  env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ? EPublicBucketsProd
    : EPublicBucketsDev;

export type IPublicBucket = EPublicBucketsProd | EPublicBucketsDev;
export type IBucket = EBucketsProd | EBucketsDev;

export interface IFileUpload {
  file: File;
  path: string;
  bucket: IBucket;
  filename: string;
}

const useStorage = () => {
  const putSignedUrl = api.storage.putSignedUrl.useMutation();
  const getSignedUrl = api.storage.getSignedUrl.useMutation();
  const deleteSignedUrl = api.storage.deleteSignedUrl.useMutation();
  const copyFile = api.storage.copyFile.useMutation();
  const getObjectsMutation = api.storage.getObjects.useMutation();

  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  );
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const getObjects = useCallback(
    async ({
      bucket,
      path,
    }: {
      bucket: IBucket;
      path: string;
    }): Promise<TGetObject[]> => {
      const cleanedPath = path.replace(/^\/|\/$/g, "");

      const response = await getObjectsMutation.mutateAsync({
        bucket: bucket,
        path: `${cleanedPath}`,
      });

      return response;
    },
    [getObjectsMutation]
  );

  const generateSignedUrl = useCallback(
    async ({
      method,
      bucket,
      path,
      contentType,
      filename,
    }: {
      method: "GET" | "PUT" | "DELETE";
      bucket: IBucket;
      path: string;
      contentType?: string;
      filename?: string;
    }): Promise<string> => {
      const cleanedPath = path.replace(/^\/|\/$/g, "");

      if (method === "GET") {
        const signedUrl = await getSignedUrl.mutateAsync({
          bucket: bucket,
          path: `${cleanedPath}`,
          filename: filename,
        });

        return signedUrl;
      } else if (method === "PUT") {
        const signedUrl = await putSignedUrl.mutateAsync({
          bucket: bucket,
          path: `${cleanedPath}`,
          contentType: contentType || "application/octet-stream",
        });

        return signedUrl;
      } else {
        const signedUrl = await deleteSignedUrl.mutateAsync({
          bucket: bucket,
          path: `${cleanedPath}`,
        });

        return signedUrl;
      }
    },
    [getSignedUrl, putSignedUrl, deleteSignedUrl]
  );

  const uploadFiles = useCallback(
    async (filesToUpload: IFileUpload[]) => {
      setIsUploadingFiles(true);
      const signedUrls: string[] = [];
      const initialUploadProgress = filesToUpload.reduce(
        (acc, fileToUpload) => ({
          ...acc,
          [fileToUpload.path]: 0,
        }),
        {}
      );

      setUploadProgress((prev) => {
        return {
          ...prev,
          ...initialUploadProgress,
        };
      });

      await Promise.all(
        filesToUpload.map(async (fileToUpload) => {
          const { path, bucket, file } = fileToUpload;
          const signedUrl = await generateSignedUrl({
            method: "PUT",
            bucket: bucket,
            path: `${path}`,
            contentType: file.type,
          });
          signedUrls.push(signedUrl);

          try {
            await axios.put(signedUrl, file, {
              onUploadProgress: (progressEvent) => {
                let percentCompleted =
                  (progressEvent.loaded * 100) / (progressEvent.total || 1);
                percentCompleted = parseFloat(percentCompleted.toFixed(0));

                setUploadProgress((prev) => ({
                  ...prev,
                  [path]: percentCompleted,
                }));
              },
            });

            return fileToUpload;
          } catch (error) {
            console.error("Upload Failed", {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
              message: (error as any)?.message,
            });
            throw error;
          }
        })
      );

      setIsUploadingFiles(false);

      return signedUrls;
    },
    [generateSignedUrl]
  );

  const deleteFiles = useCallback(
    async (filesToDelete: Omit<IFileUpload, "file" | "filename">[]) => {
      const deletedFiles = await Promise.all(
        filesToDelete.map(async (fileToDelete) => {
          const { path, bucket } = fileToDelete;
          const cleanedPath = path.replace(/^\/|\/$/g, "");
          const signedUrl = await generateSignedUrl({
            method: "DELETE",
            bucket: bucket,
            path: `${cleanedPath}`,
          });

          try {
            await axios.delete(signedUrl);
          } catch (error) {
            console.log(error);
          }
        })
      );

      return deletedFiles;
    },
    [generateSignedUrl]
  );

  const moveFile = async ({
    fromBucket,
    fromFullPath,
    toBucket,
    toFullPath,
  }: {
    fromBucket: IBucket;
    fromFullPath: string;
    toBucket: IBucket;
    toFullPath: string;
  }) => {
    const cleanedFromPath = fromFullPath.replace(/^\/|\/$/g, "");
    const cleanedToPath = toFullPath.replace(/^\/|\/$/g, "");

    const signedUrlDelete = await deleteSignedUrl.mutateAsync({
      bucket: fromBucket,
      path: cleanedFromPath,
    });

    try {
      await copyFile.mutateAsync({
        fromBucket,
        fromPath: cleanedFromPath,
        toBucket,
        toPath: cleanedToPath,
      });
      await axios.delete(signedUrlDelete);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return {
    uploadFiles,
    deleteFiles,
    moveFile,
    generateSignedUrl,
    getObjects,
    uploadProgress,
    isUploadingFiles:
      isUploadingFiles ||
      Object.keys(uploadProgress).some(
        (key) => uploadProgress[key] && uploadProgress[key] !== 100
      ),
  };
};

export type TGetObject = {
  url: string;
  filename: string;
  createdAt: Date;
  key: string;
};

export default useStorage;
