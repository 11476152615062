import { useCallback, useEffect, useMemo, useState } from "react";

import { createPortal } from "react-dom";

import { type AudioPlayerRef } from "@musicfy/components/AudioPlayer";
import { useIsTablet } from "@musicfy/utils/hooks";

import DekstopGlobalAudioPlayer from "./DesktopGlobalAudioPlayer";
import MobileGlobalAudioPlayer from "./MobileGlobalAudioPlayer";
import { type IAudioSource } from "../types";

interface IDekstopGlobalAudioPlayerProps {
  audioSources: IAudioSource[];
  autoPlay?: boolean;
  audioPlayerRef: React.MutableRefObject<AudioPlayerRef | null>;
}

const GlobalAudioPlayer = ({
  audioSources,
  audioPlayerRef,
  autoPlay = false,
}: IDekstopGlobalAudioPlayerProps): JSX.Element | null => {
  const isTablet = useIsTablet();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [selectedAudioSourceIndex, setSelectedAudioSourceIndex] =
    useState<number>(0);

  const selectedArtist = useMemo(() => {
    return audioSources[selectedAudioSourceIndex]?.artist as string;
  }, [audioSources, selectedAudioSourceIndex]);

  const selectedFilename = useMemo(() => {
    return audioSources[selectedAudioSourceIndex]?.filename as string;
  }, [audioSources, selectedAudioSourceIndex]);

  const selectedAudioType = useMemo(() => {
    return audioSources[selectedAudioSourceIndex]?.label as string;
  }, [audioSources, selectedAudioSourceIndex]);

  const filenames = useMemo(() => {
    const filteredAudioSources = audioSources.filter(
      (source) => source.artist === selectedArtist
    );

    const _filenames: { label: string; value: string }[] = [];

    filteredAudioSources.forEach((source) => {
      const filename = source.filename;

      if (_filenames.find((f) => f.value === filename) || !filename) {
        return;
      }

      const filenameOption = {
        label: filename,
        value: filename,
      };

      _filenames.push(filenameOption);
    });

    return _filenames;
  }, [audioSources, selectedArtist]);

  const artists = useMemo(() => {
    const _artists: { label: string; value: string }[] = [];

    audioSources?.forEach((source) => {
      const artist = source.artist;

      if (_artists.find((a) => a.label === artist) || !artist) {
        return;
      }

      const artistOption = {
        label: artist,
        value: artist,
      };

      _artists.push(artistOption);
    });

    return _artists;
  }, [audioSources]);

  const audioTypes = useMemo(() => {
    const filteredAudioSources = audioSources.filter(
      (source) =>
        source.artist === selectedArtist && source.filename === selectedFilename
    );

    const _audioTypes: { label: string; value: string }[] = [];

    filteredAudioSources.forEach((source) => {
      const audioType = source.label;

      if (_audioTypes.find((a) => a.value === audioType) || !audioType) {
        return;
      }

      const audioTypeOption = {
        label: audioType,
        value: audioType,
      };

      _audioTypes.push(audioTypeOption);
    });

    return _audioTypes;
  }, [audioSources, selectedArtist, selectedFilename]);

  const audioSource = useMemo(() => {
    return audioSources[selectedAudioSourceIndex] || null;
  }, [audioSources, selectedAudioSourceIndex]);

  const onNextTrackClick = useCallback(() => {
    const nextTrackIndex = audioSources.findIndex(
      (source, i) =>
        (source.artist !== selectedArtist ||
          source.filename !== selectedFilename) &&
        i > selectedAudioSourceIndex
    );

    setSelectedAudioSourceIndex(nextTrackIndex > 0 ? nextTrackIndex : 0);
  }, [
    audioSources,
    selectedArtist,
    selectedAudioSourceIndex,
    selectedFilename,
  ]);

  const onPreviousTrackClick = useCallback(() => {
    let prevArtist = selectedArtist;
    let prevFilename = selectedFilename;

    for (let i = audioSources.length - 1; i >= 0; i--) {
      const source = audioSources[i];

      if (!source || !source.artist || !source.filename) {
        continue;
      }

      if (
        (source.artist !== selectedArtist ||
          source.filename !== selectedFilename) &&
        i < selectedAudioSourceIndex
      ) {
        prevArtist = source.artist;
        prevFilename = source.filename;
        break;
      }
    }

    const filteredAudioSources = audioSources.filter(
      (source) =>
        source.artist === prevArtist && source.filename === prevFilename
    );

    const firstAudioType = filteredAudioSources[0]?.label ?? selectedAudioType;

    const prevTrackIndex = audioSources.findIndex(
      (source) =>
        source.artist === prevArtist &&
        source.filename === prevFilename &&
        source.label === firstAudioType
    );

    setSelectedAudioSourceIndex(prevTrackIndex > 0 ? prevTrackIndex : 0);
  }, [
    audioSources,
    selectedArtist,
    selectedAudioSourceIndex,
    selectedAudioType,
    selectedFilename,
  ]);

  useEffect(() => {
    if (!!audioSources.length) {
      setSelectedAudioSourceIndex(0);
    }
  }, [audioSources]);

  if (
    !audioSources.length ||
    !audioSource ||
    !selectedArtist ||
    !selectedFilename ||
    !selectedAudioType
  ) {
    return null;
  }

  const _isExpanded = isExpanded && audioSources.length > 1;

  if (isTablet) {
    return createPortal(
      <MobileGlobalAudioPlayer
        artists={artists}
        audioPlayerRef={audioPlayerRef}
        audioSource={audioSource}
        audioSources={audioSources}
        audioTypes={audioTypes}
        filenames={filenames}
        isExpanded={_isExpanded}
        onNextClick={onNextTrackClick}
        onPrevClick={onPreviousTrackClick}
        selectedArtist={selectedArtist}
        selectedAudioType={selectedAudioType}
        selectedFilename={selectedFilename}
        setSelectedAudioSourceIndex={setSelectedAudioSourceIndex}
        setIsExpanded={setIsExpanded}
        autoPlay={autoPlay}
      />,
      document.getElementById("global-audio-player-container") ?? document.body,
      "global-audio-player"
    );
  }

  return createPortal(
    <DekstopGlobalAudioPlayer
      artists={artists}
      audioPlayerRef={audioPlayerRef}
      audioSource={audioSource}
      audioSources={audioSources}
      audioTypes={audioTypes}
      filenames={filenames}
      isExpanded={_isExpanded}
      onNextClick={onNextTrackClick}
      onPrevClick={onPreviousTrackClick}
      selectedArtist={selectedArtist}
      selectedAudioType={selectedAudioType}
      selectedFilename={selectedFilename}
      setSelectedAudioSourceIndex={setSelectedAudioSourceIndex}
      setIsExpanded={setIsExpanded}
      autoPlay={autoPlay}
    />,
    document.getElementById("global-audio-player-container") ?? document.body,
    "global-audio-player"
  );
};

export default GlobalAudioPlayer;
