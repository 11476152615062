import Slider from "@musicfy/components/Slider";

interface ITrackProps {
  audio: HTMLAudioElement | null;
  pause: () => void;
  play: () => void;
}

const Track = ({ audio, pause, play }: ITrackProps): JSX.Element => {
  const currentTime = audio?.currentTime || 0;
  const duration = audio?.duration || 0;
  const sliderValue = duration ? currentTime / duration : 0;

  return (
    <Slider
      max={1}
      min={0}
      onChange={(e, value) => {
        if (!audio?.duration) {
          return;
        }
        pause();
        audio.currentTime = (value as number) * audio.duration;
      }}
      onChangeCommitted={() => {
        play();
      }}
      size="small"
      step={0.01}
      value={sliderValue}
    />
  );
};

export default Track;
