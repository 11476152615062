import MUIPopover, { type PopoverProps } from "@mui/material/Popover";

import { roobert } from "@musicfy/assets/fonts";

interface MusicfyPopoverProps extends PopoverProps {
  children: React.ReactNode;
}

const Popover = ({
  children,
  ...popoverProps
}: MusicfyPopoverProps): JSX.Element => {
  return (
    <MUIPopover
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...popoverProps}
      className={`${roobert.variable} font-sans`}
      slotProps={{
        paper: {
          style: {
            width: "fit-content",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <div className="relative p-[1px] flex items-center justify-center bg-gradient-to-br from-pink-300 to-pink-400 overflow-hidden rounded-8 text-14 text-white">
        <div className="bg-gray-1000 rounded-8 w-full flex flex-col gap-1 py-2 px-4">
          {children}
        </div>
      </div>
    </MUIPopover>
  );
};

export default Popover;
