import { useEffect, useState } from "react";

import { SubscriptionPlans } from "@prisma/client";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import Icon, { Icons } from "@musicfy/components/Icon";
import Input from "@musicfy/components/Input";
import PageLoader from "@musicfy/components/PageLoader";
import { MODEL_TRAINING_MAX_QUOTA } from "@musicfy/contants/Features";
import {
  AdditionalVoicesId,
  ProductId,
  type TAdditionalVoicesId,
  type TPriceId,
} from "@musicfy/contants/Subscriptions";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { usePaddleUpgrade } from "@musicfy/libs/PaddleProvider/hooks/usePaddleUpgrade";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { formatPrice } from "@musicfy/utils";

import FullScreenWrapper from "./shared/FullScreenWrapper";

interface UpgradePreviewProps {
  onUpgradeClick: (
    priceId: TPriceId,
    numberOfAdditionalVoices?: number
  ) => Promise<void>;
}

const AdditionalVoices = ({
  localNumberOfAdditionalVoices,
  setLocalNumberOfAdditionalVoices,
}: {
  localNumberOfAdditionalVoices: number;
  setLocalNumberOfAdditionalVoices: (value: number) => void;
}): JSX.Element | null => {
  const {
    upgradePreviewContext: { upgradePreviewData },
  } = usePaddleContext();
  const { updateNumberOfAdditionalVoices } = usePaddleUpgrade();

  const isEligibleForAdditionalVoices = upgradePreviewData?.items.some(
    (item) => item.price.product_id === ProductId.label
  );

  const additiontalVoicesLineItem = upgradePreviewData?.items.find((item) =>
    Object.values(AdditionalVoicesId).includes(
      item.price.id as TAdditionalVoicesId
    )
  );

  useEffect(() => {
    setLocalNumberOfAdditionalVoices(additiontalVoicesLineItem?.quantity ?? 0);
  }, [additiontalVoicesLineItem, setLocalNumberOfAdditionalVoices]);

  if (!isEligibleForAdditionalVoices) {
    return null;
  }

  const totalNumberOfCustomVoices =
    MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.label] +
    localNumberOfAdditionalVoices;

  return (
    <div className="w-full py-6 border-b border-solid border-gray-400">
      <div className="flex items-center justify-between">
        <div className="text-white/90">Additional Voices</div>
        <div className="flex items-center gap-2">
          <div
            role="button"
            className="text-24"
            onClick={() => {
              if (!additiontalVoicesLineItem) {
                return;
              }
              updateNumberOfAdditionalVoices(
                additiontalVoicesLineItem.quantity - 1
              );
            }}
          >
            -
          </div>
          <Input
            containerClassName="!max-w-20 flex !py-2 !px-0"
            className="text-center !text-16"
            value={localNumberOfAdditionalVoices}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setLocalNumberOfAdditionalVoices(isNaN(value) ? 0 : value);
            }}
            onBlur={(e) => {
              const value = isNaN(parseInt(e.target.value))
                ? 0
                : parseInt(e.target.value);
              if (
                value === additiontalVoicesLineItem?.quantity ||
                (value === 0 && !additiontalVoicesLineItem)
              ) {
                return;
              }
              updateNumberOfAdditionalVoices(value);
            }}
            onSubmit={() => {
              const value = isNaN(localNumberOfAdditionalVoices)
                ? 0
                : localNumberOfAdditionalVoices + 1;
              if (
                value === additiontalVoicesLineItem?.quantity ||
                (value === 0 && !additiontalVoicesLineItem)
              ) {
                return;
              }
              updateNumberOfAdditionalVoices(value);
            }}
          />
          <div
            role="button"
            className="text-24"
            onClick={() => {
              const value = (additiontalVoicesLineItem?.quantity ?? 0) + 1;
              updateNumberOfAdditionalVoices(value);
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="flex font-bold text-20 items-center justify-between mt-6">
        <div>Total # of Custom Voices</div>
        <div>{totalNumberOfCustomVoices}</div>
      </div>
    </div>
  );
};

const UpgradePreview = ({
  onUpgradeClick,
}: UpgradePreviewProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    upgradePreviewContext: { upgradePreviewData },
  } = usePaddleContext();
  const { refetchPaddleSubscription } = useSubscriptionContext();

  const [localNumberOfAdditionalVoices, setLocalNumberOfAdditionalVoices] =
    useState(0);

  if (!upgradePreviewData) {
    return <PageLoader />;
  }

  const {
    immediate_transaction: immediateTransaction,
    recurring_transaction_details: recurringTransaction,
  } = upgradePreviewData;

  const priceDetails = recurringTransaction.line_items.find((item) =>
    Object.values(ProductId).includes(item.product.id)
  );

  const additiontalVoicesLineItem = upgradePreviewData?.items.find((item) =>
    Object.values(AdditionalVoicesId).includes(
      item.price.id as TAdditionalVoicesId
    )
  );

  if (!priceDetails) {
    return null;
  }

  const priceId = priceDetails.price_id;

  const handleUpgradeClick = async () => {
    if (
      !!additiontalVoicesLineItem &&
      localNumberOfAdditionalVoices !== additiontalVoicesLineItem.quantity
    ) {
      return;
    }

    setIsLoading(true);
    await onUpgradeClick(priceId, additiontalVoicesLineItem?.quantity);
    refetchPaddleSubscription();
    setIsLoading(false);
  };

  const interval = upgradePreviewData.billing_cycle.interval;
  const currencyCode = upgradePreviewData.currency_code;

  const immediateTotalsStrings = immediateTransaction?.details.totals;

  const immediateTotals = !!immediateTotalsStrings
    ? (Object.entries(immediateTotalsStrings).reduce((acc, [key, value]) => {
        const isNumber = /^\d+$/.test(value);
        if (!isNumber) return acc;
        return {
          ...acc,
          [key as keyof typeof immediateTotalsStrings]:
            parseInt(value, 10) / 100,
        };
      }, {}) as {
        [key in keyof typeof immediateTotalsStrings]: number;
      })
    : {
        balance: 0,
        credit: 0,
        discount: 0,
        tax: 0,
        total: 0,
      };

  // Immediate balance is immediate total - immediate credit - immediate discount
  const immediateBalance = formatPrice(
    immediateTotals.balance,
    upgradePreviewData.currency_code
  );

  /** Recurring Transaction */
  const recurringPrice = formatPrice(
    parseInt(recurringTransaction.totals.total, 10) / 100,
    currencyCode
  );

  /** Product Data */
  const productData = priceDetails.product;
  const { description } = productData;
  const iconNames = [
    Icons.rotation,
    Icons.rocket,
    Icons.sound,
    Icons.cube,
    Icons.rotation,
    Icons.music,
    Icons.cloud,
  ];
  const features = description.split("\n").map((feature, index) => ({
    text: feature,
    iconName: iconNames[index],
  }));
  features.sort((a, b) => b.text.length - a.text.length);

  return (
    <div className="w-full flex flex-col gap-7">
      <div className="flex flex-col gap-4">
        {features.map((feature) => {
          return (
            <div key={feature.text} className="flex gap-3 items-center text-14">
              <Icon name={feature.iconName ?? Icons.rotation} />
              <span>{feature.text}</span>
            </div>
          );
        })}
      </div>
      <Divider className="bg-white/[20%]" />

      <div className="capitalize">
        <span className="text-38 font-bold">{recurringPrice}</span>
        <span className="text-pink-200 text-20">/{interval}</span>
      </div>
      <AdditionalVoices
        localNumberOfAdditionalVoices={localNumberOfAdditionalVoices}
        setLocalNumberOfAdditionalVoices={setLocalNumberOfAdditionalVoices}
      />
      <div className="flex items-center justify-between text-14">
        <div className="text-white">Due Today</div>
        <div className="font-bold">{immediateBalance}</div>
      </div>
      <Button
        className="text-20 !py-5"
        onClick={handleUpgradeClick}
        loading={isLoading}
      >
        Upgrade
      </Button>
    </div>
  );
};

const Upgrade = (): JSX.Element => {
  const { closeUpgrade, upgradeSubsription } = usePaddleUpgrade();
  const {
    upgradePreviewContext: { upgradePreviewData, isUpgradeUpdating },
  } = usePaddleContext();

  const onUpgradeClick = async (
    priceId: TPriceId,
    numberOfAdditionalVoices?: number
  ) => {
    await upgradeSubsription(priceId, numberOfAdditionalVoices);
  };

  return (
    <FullScreenWrapper
      className="h-full w-full backdrop-blur-xl"
      open
      onClose={closeUpgrade}
      style="transparent"
      isLoading={!upgradePreviewData}
    >
      <div className="p-9 relative flex items-center justify-center flex-grow">
        {isUpgradeUpdating && (
          <div className="absolute rounded-12 z-10 w-full h-full inset-0 bg-black/50">
            <PageLoader />
          </div>
        )}
        <div className="h-full w-full max-w-[436px] flex justify-center flex-col gap-7">
          <span className="text-38 font-bold">Unleash your creativity</span>
          <UpgradePreview onUpgradeClick={onUpgradeClick} />
        </div>
      </div>
    </FullScreenWrapper>
  );
};

export default Upgrade;
