import { getLocale } from "./getLocales";

const formatPrice = (price: number, currencyCode = "USD"): string => {
  return price.toLocaleString(getLocale(), {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export { formatPrice };
