import {
  createContext,
  useContext,
  type ReactNode,
  useCallback,
  useState,
} from "react";

import Toast from "@musicfy/components/Toast";

export type IToastSeverity = "error" | "warning" | "info" | "success";

export interface IToastConfiguration {
  severity?: IToastSeverity;
}

const DEFAULT_TOAST_CONFIGURATION: Required<IToastConfiguration> = {
  severity: "success",
};

interface IToastProviderProps {
  children: ReactNode;
}

interface IToastContext {
  sendToastMessage: (
    toastMessage: string,
    toastConfig?: IToastConfiguration
  ) => void;
}

const ToastContext = createContext<IToastContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sendToastMessage: () => {},
});

export function useToastContext() {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }

  return toastContext;
}

export default function ToastProvider({ children }: IToastProviderProps) {
  /**
   * A context provider for controlling toasts. Intented to be used at the top level
   * and should be available for every app.
   */

  const [toastParams, setToastParams] = useState<{
    message: string;
    severity: IToastSeverity;
  }>({
    message: "",
    severity: "success",
  });

  const sendToastMessage = useCallback(
    (
      incomingToastMessage: string,
      toastConfig: IToastConfiguration = DEFAULT_TOAST_CONFIGURATION
    ) => {
      const incomingToastSeverity =
        toastConfig.severity || DEFAULT_TOAST_CONFIGURATION.severity;

      setToastParams({
        message: incomingToastMessage,
        severity: incomingToastSeverity,
      });
    },
    []
  );

  return (
    <ToastContext.Provider
      value={{
        sendToastMessage,
      }}
    >
      {children}
      <Toast
        severity={toastParams.severity}
        toastMessage={toastParams.message}
        setToastMessage={sendToastMessage}
      />
    </ToastContext.Provider>
  );
}
