import Button, { type IButtonProps } from "@musicfy/components/Button";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";

import Icon from "../Icon";

interface CheckoutFlowButtonProps extends IButtonProps {
  origin?: string;
}

const StartCheckoutFlowButton = ({
  children,
  origin,
  ...buttonProps
}: CheckoutFlowButtonProps) => {
  const { startCheckoutFlow } = usePaddleContext();
  return (
    <>
      <Button onClick={() => startCheckoutFlow(origin)} {...buttonProps}>
        {!!children ? (
          children
        ) : (
          <div className="flex items-center justify-center gap-2">
            <Icon name="crown" /> <div>Upgrade</div>
          </div>
        )}
      </Button>
    </>
  );
};

export default StartCheckoutFlowButton;
