export const isNullOrUndefined = (
  value: unknown
): value is null | undefined => {
  return value === null || value === undefined;
};

type ObjectValues<T extends object> = T[keyof T];

export const isNotNullOrUndefined = <T>(
  value: T
): value is Exclude<T, null | undefined> => {
  return value !== null && value !== undefined;
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return isNotNullOrUndefined(value) && typeof value === "object";
};

export const isString = (value: unknown): value is string => {
  return isNotNullOrUndefined(value) && typeof value === "string";
};

export const isBoolean = (value: unknown): value is boolean => {
  return isNotNullOrUndefined(value) && typeof value === "boolean";
};

export const isNumber = (value: unknown): value is number => {
  return isNotNullOrUndefined(value) && typeof value === "number";
};

export const isArray = (value: unknown): value is unknown[] => {
  return isNotNullOrUndefined(value) && Array.isArray(value);
};

export const isKeyIn = <T extends object>(
  item: T,
  key: string | number | symbol | undefined | null
): key is keyof T => {
  if (isNullOrUndefined(item)) return false;
  if (isNullOrUndefined(key)) return false;

  if (!isObject(item)) return false;

  return key in item;
};

export const getObjectValues = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.values(object) as Array<TObject[keyof TObject]>;
};

export const getObjectKeys = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.keys(object) as Array<keyof TObject>;
};

export const getObjectEntries = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.entries(object) as Array<
    [keyof TObject, ObjectValues<TObject>]
  >;
};
