import { useState, useCallback } from "react";

import { useSession } from "next-auth/react";

import Button from "@musicfy/components/Button";
import Input from "@musicfy/components/Input";
import { api, isValidEmail } from "@musicfy/utils";
import { useIsAdmin } from "@musicfy/utils/hooks";

const AdminController: React.FC = () => {
  const { data, update } = useSession();

  const isAdmin = useIsAdmin();
  const user = data?.user;

  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const doesUserExistMutation = api.users.doesUserExist.useMutation();

  const updateSession = useCallback(async () => {
    if (!isValidEmail(email)) {
      setError("Please enter a valid email");
      return;
    }
    const doesUserExist = await doesUserExistMutation.mutateAsync({
      email,
    });
    if (!doesUserExist) {
      setError("User does not exist");
      return;
    }
    update({ email: email, shouldAdminIntoUser: true });
  }, [doesUserExistMutation, email, update]);

  if (!user || !isAdmin) {
    return null;
  }

  const isInAdminMode =
    isAdmin && user.email !== user.adminEmail && !!user.adminEmail;

  if (isInAdminMode) {
    return (
      <div className="fixed bottom-4 right-4 z-50 bg-white/5 backdrop-blur-2xl border border-white/20 p-2 rounded-12">
        <Button
          className="min-h-min !text-14 !py-2"
          fullWidth
          onClick={() =>
            update({ email: user.adminEmail, shouldAdminIntoUser: true })
          }
        >
          Exit Admin Mode
        </Button>
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 z-50 bg-white/5 backdrop-blur-2xl border border-white/20 p-2 rounded-12">
      <Input
        onChange={(e) => {
          setError("");
          setEmail(e.target.value);
        }}
        className="!text-12"
        containerClassName="!py-1 !px-2 lg:!w-[200px] !rounded-8"
        placeholder="Email"
        onSubmit={updateSession}
        errorMessage={error}
      />
      <Button
        fullWidth
        loading={doesUserExistMutation.isLoading}
        className="whitespace-nowrap mt-2 !py-2 !text-14"
        onClick={updateSession}
        loaderSize={14}
      >
        Switch User
      </Button>
    </div>
  );
};

export default AdminController;
