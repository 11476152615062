import { useCallback, useEffect, useState } from "react";

import {
  type PaddleEventData,
  type UpdateCheckoutParams,
  type ExtendedPaddle,
  CheckoutEventNames,
} from "@paddle/paddle-js";
import { SubscriptionIntervals } from "@prisma/client";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import {
  AdditionalVoicesId,
  PRICE_ID_TO_INTERVAL_MAP,
  PRICE_ID_TO_PLAN_MAP,
  PRICE_ID_TO_PRICE_MAP,
  ProductId,
  type TProductId,
  type TPriceId,
} from "@musicfy/contants/Subscriptions";
import { useAnalytics } from "@musicfy/libs/AnalyticsProvider";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import {
  PaddleScreens,
  type CheckoutEvents,
} from "@musicfy/libs/PaddleProvider/types";
import { api } from "@musicfy/utils";

export const usePaddleCheckout = ({
  onLoad,
  onClose,
  onCheckoutCompleted,
  onCheckoutUpdated,
  onPaymentInitiated,
  onError,
}: CheckoutEvents = {}) => {
  const { trackEvent } = useAnalytics();
  const router = useRouter();

  const {
    checkoutContext,
    subscribeToPaddleEvents,
    unsubscribeFromPaddleEvents,
    setActivePaddleScreen,
    origin,
    setOrigin,
  } = usePaddleContext();

  const {
    checkoutData,
    setCheckoutData,
    setCheckoutError,
    isFetchingSubscriptionRef,
    isCheckoutUpdating,
    setIsCheckoutUpdating,
  } = checkoutContext;

  const { data } = useSession();
  const user = data?.user;

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [canCloseCheckout, setCanCloseCheckout] = useState(true);

  const { mutateAsync: checkIsValidDiscountCode } =
    api.subscription.checkIsValidDiscountCode.useMutation();

  const eventsCallback = useCallback(
    (event: PaddleEventData) => {
      switch (event.type) {
        case "checkout.error":
          onError?.(event);
          setCheckoutError(event);
          break;
      }

      if (!event.data) {
        return;
      }

      switch (event.name) {
        case CheckoutEventNames.CHECKOUT_LOADED:
          onLoad?.(event.data);
          setCheckoutData(event.data);
          break;
        case CheckoutEventNames.CHECKOUT_UPDATED:
          onCheckoutUpdated?.(event.data);
          setCheckoutData(event.data);
          setIsCheckoutUpdating(false);
          break;
        case CheckoutEventNames.CHECKOUT_COMPLETED:
          onCheckoutCompleted?.(event.data);
          setCheckoutData(event.data);
          setIsPaymentProcessing(false);
          setCanCloseCheckout(false);

          const priceId = event.data.items[0]?.price_id as TPriceId;
          const plan = PRICE_ID_TO_PLAN_MAP[priceId];
          const interval = PRICE_ID_TO_INTERVAL_MAP[priceId];
          const mrr =
            interval === SubscriptionIntervals.month
              ? PRICE_ID_TO_PRICE_MAP[priceId] / 100
              : PRICE_ID_TO_PRICE_MAP[priceId] / 12 / 100;

          if (!isFetchingSubscriptionRef.current) {
            isFetchingSubscriptionRef.current = true;
            trackEvent("Subscription Upgrade", {
              plan: plan,
              interval: interval,
              price: mrr,
              origin: origin,
            });
          }
          setTimeout(() => {
            router.reload();
            setCanCloseCheckout(false);
          }, 4000);

          break;
        case CheckoutEventNames.CHECKOUT_PAYMENT_INITIATED:
          onPaymentInitiated?.(event.data);
          setCheckoutData(event.data);
          setIsPaymentProcessing(true);
          break;
        case CheckoutEventNames.CHECKOUT_DISCOUNT_REMOVED:
        case CheckoutEventNames.CHECKOUT_DISCOUNT_APPLIED:
          setCheckoutData(event.data);
          break;
        case CheckoutEventNames.CHECKOUT_PAYMENT_FAILED:
          setIsPaymentProcessing(false);
          break;
        case CheckoutEventNames.CHECKOUT_CLOSED:
          onClose?.(undefined);
          setCheckoutData(undefined);
          setCheckoutError(undefined);
          setIsPaymentProcessing(false);
          break;
        default:
          setIsPaymentProcessing(false);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onCheckoutCompleted,
      onCheckoutUpdated,
      onClose,
      onError,
      onLoad,
      onPaymentInitiated,
      origin,
      trackEvent,
      router,
      setCheckoutData,
      setCheckoutError,
    ]
  );

  useEffect(() => {
    subscribeToPaddleEvents(eventsCallback);

    return () => {
      unsubscribeFromPaddleEvents(eventsCallback);
    };
  }, [eventsCallback, subscribeToPaddleEvents, unsubscribeFromPaddleEvents]);

  const startCheckout = useCallback(
    (priceId: TPriceId, discount?: { code?: string; id?: string }) => {
      setActivePaddleScreen(PaddleScreens.CHECKOUT);

      setTimeout(() => {
        if (!window.Paddle || !user) {
          return;
        }

        const items = [
          {
            priceId: priceId,
          },
        ];

        window.Paddle.Checkout.open({
          customer: {
            email: user.email,
          },
          customData: {
            userId: user.id,
            ...(window.tolt_referral && {
              tolt_referral: window.tolt_referral,
            }),
          },

          ...(discount?.code
            ? { discountCode: discount.code }
            : discount?.id
              ? { discountId: discount.id }
              : {}),
          items: items,
          settings: {
            allowLogout: false,
          },
        });

        /* Analytics */
        const product = PRICE_ID_TO_PLAN_MAP[priceId];
        const interval = PRICE_ID_TO_INTERVAL_MAP[priceId];
        trackEvent("Checkout Started", {
          plan: product,
          interval: interval,
          origin: origin,
        });
      }, 0);
    },
    [origin, trackEvent, setActivePaddleScreen, user]
  );

  const closeCheckout = useCallback(() => {
    if (!window.Paddle || isPaymentProcessing || !canCloseCheckout) {
      return;
    }
    window.Paddle.Checkout.close();
    setOrigin(undefined);
    setActivePaddleScreen(null);
    unsubscribeFromPaddleEvents(eventsCallback);
  }, [
    canCloseCheckout,
    eventsCallback,
    isPaymentProcessing,
    setActivePaddleScreen,
    setOrigin,
    unsubscribeFromPaddleEvents,
  ]);

  const updateCheckout = useCallback(
    (checkout: UpdateCheckoutParams) => {
      if (!window.Paddle || !user) {
        return;
      }

      const updatedCheckout = {
        ...checkout,
        customer: {
          email: user.email,
        },
      };

      (window.Paddle as ExtendedPaddle).Checkout.updateCheckout(
        updatedCheckout
      );
    },
    [user]
  );

  const applyDiscountCode = useCallback(
    async (discountCode: string) => {
      if (!checkoutData || !window.Paddle || !user) {
        return {
          success: false,
        };
      }

      const priceIds = checkoutData.items.map((item) => item.price_id);

      const isValidDiscountCode = await checkIsValidDiscountCode({
        priceIds: priceIds,
        discountCode: discountCode,
      });

      if (!isValidDiscountCode) {
        return {
          success: false,
        };
      }

      const items = checkoutData.items.map((item) => {
        return {
          priceId: item.price_id,
          quantity: item.quantity,
        };
      });

      const customer = {
        email: user.email,
      };

      updateCheckout({
        customer,
        items,
        discountCode,
      });

      return {
        success: true,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutData, updateCheckout, user]
  );

  const updateNumberOfAdditionalVoices = useCallback(
    (numberOfVoices: number) => {
      if (!checkoutData || !window.Paddle || !user) {
        return;
      }

      setIsCheckoutUpdating(true);

      const customer = {
        email: user.email,
      };

      const discountCode = checkoutData.discount?.code;

      // Get the subscription plan checkout item
      const subscriptionPlanCheckoutItem = checkoutData.items.find((item) =>
        Object.values(ProductId).includes(item.product.id as TProductId)
      );

      // Get the price id of the additional voices item
      const additionalVoicePriceId =
        subscriptionPlanCheckoutItem?.billing_cycle?.interval ===
        SubscriptionIntervals.month
          ? (AdditionalVoicesId.voices_month as string)
          : (AdditionalVoicesId.voices_year as string);

      if (!subscriptionPlanCheckoutItem) {
        return;
      }

      // Create the items array with the subscription plan and additional voices items
      const items = [
        {
          priceId: subscriptionPlanCheckoutItem.price_id,
          quantity: subscriptionPlanCheckoutItem.quantity,
        },
        {
          priceId: additionalVoicePriceId,
          quantity: numberOfVoices,
        },
      ];

      // Filter out items with a quantity of 0
      const validatedItems = items.filter((item) => item.quantity > 0);

      // Update the checkout with the new items and discount code
      updateCheckout({
        customer,
        items: validatedItems,
        discountCode: discountCode ?? null,
      });
    },
    [checkoutData, setIsCheckoutUpdating, updateCheckout, user]
  );

  const removeDiscountCode = useCallback(() => {
    if (!checkoutData || !window.Paddle || !user) {
      return;
    }

    const discountCode = checkoutData.discount?.code;

    if (!discountCode) {
      return;
    }

    const items = checkoutData.items.map((item) => {
      return {
        priceId: item.price_id,
        quantity: item.quantity,
      };
    });

    const customer = {
      email: user.email,
    };

    updateCheckout({
      customer: customer,
      items: items,
      discountCode: null,
    });
  }, [checkoutData, updateCheckout, user]);

  return {
    startCheckout,
    closeCheckout,
    updateCheckout,
    applyDiscountCode,
    removeDiscountCode,
    updateNumberOfAdditionalVoices,
    isPaymentProcessing,
    eventsCallback,
    isCheckoutUpdating,
  };
};
