import { type CheckoutEventsItem } from "@paddle/paddle-js";

import { formatPrice } from "@musicfy/utils";
const CheckoutItem = ({
  item,
  currencyCode,
}: {
  item: CheckoutEventsItem;
  currencyCode: string;
}): JSX.Element => {
  const label = item.product.name;
  const priceId = item.price_id;
  const formattedItemSubtotal = formatPrice(item.totals.subtotal, currencyCode);
  const quantity = item.quantity;
  let itemBillingInterval = item.billing_cycle?.interval ?? "month";
  itemBillingInterval = itemBillingInterval === "month" ? "Monthly" : "Yearly";

  return (
    <div
      key={priceId}
      className="w-full py-6 border-b border-solid border-gray-400"
    >
      <div className="flex items-center justify-between">
        <span className="font-bold text-20">{label}</span>
        <div className="text-right font-bold text-20">
          {formattedItemSubtotal}
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <span className="text-16">Qty: {quantity}</span>
        <div className="text-right text-16">{itemBillingInterval}</div>
      </div>
    </div>
  );
};

export default CheckoutItem;
