import { useState } from "react";

import FullScreenTakeover from "@musicfy/components/FullScreenTakeover";

import {
  SETTINGS_PANEL_INFO,
  SettingsPanel,
  type TSettingsPanel,
} from "./types";

interface AccountSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountSettings = ({
  isOpen,
  onClose,
}: AccountSettingsProps): JSX.Element => {
  const [activePanel, setActivePanel] = useState<TSettingsPanel>(
    SettingsPanel.general
  );

  const Panel = SETTINGS_PANEL_INFO[activePanel].Panel;

  return (
    <FullScreenTakeover open={isOpen} onClose={onClose}>
      <Panel setActivePanel={setActivePanel} onClose={onClose} />
    </FullScreenTakeover>
  );
};

export default AccountSettings;
