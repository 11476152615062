import React, { createContext, useContext } from "react";

import { useSession } from "next-auth/react";

import { api } from "@musicfy/utils";
import { useArray } from "@musicfy/utils/hooks";
import {
  type IUseArrayUpdateIndex,
  type IUseArrayAppend,
  type IUseArrayRemoveIndex,
} from "@musicfy/utils/hooks/useArray";

import { type TMusicGeneration } from "./IMusicGenerations";

interface IMusicGenerationsContext {
  musicGenerations: TMusicGeneration[];
  updateMusicGenerationAtIndex: IUseArrayUpdateIndex<
    TMusicGeneration,
    TMusicGeneration[]
  >;
  appendMusicGeneration: IUseArrayAppend<TMusicGeneration, TMusicGeneration[]>;
  removeMusicGenerationAtIndex: IUseArrayRemoveIndex<TMusicGeneration[]>;
  setMusicGenerations: React.Dispatch<React.SetStateAction<TMusicGeneration[]>>;
  isMusicGenerationsLoading: boolean;
}

const MusicGenerationsContext = createContext<IMusicGenerationsContext | null>(
  null
);

export function useMusicGenerationsContext() {
  const musicGenerationsContext = useContext(MusicGenerationsContext);

  if (!musicGenerationsContext) {
    throw new Error(
      "useMusicGenerationsContext must be used within a MusicGenerationsProvider"
    );
  }

  return musicGenerationsContext;
}

const MusicGenerationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status } = useSession();
  const {
    value: musicGenerations,
    setValue: setMusicGenerations,
    updateIndex: updateMusicGenerationAtIndex,
    removeIndex: removeMusicGenerationAtIndex,
    append: appendMusicGeneration,
  } = useArray<TMusicGeneration>([]);

  const { isFetching: isMusicGenerationsLoading } =
    api.musicGenerations.getMusicGenerationsForUser.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setMusicGenerations(data);
      },
      retry: false,
      enabled: status === "authenticated",
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
    });

  const musicGenerationsContextValue = {
    musicGenerations: musicGenerations,
    updateMusicGenerationAtIndex: updateMusicGenerationAtIndex,
    appendMusicGeneration: appendMusicGeneration,
    removeMusicGenerationAtIndex: removeMusicGenerationAtIndex,
    setMusicGenerations: setMusicGenerations,
    isMusicGenerationsLoading: isMusicGenerationsLoading,
  };

  return (
    <MusicGenerationsContext.Provider value={musicGenerationsContextValue}>
      {children}
    </MusicGenerationsContext.Provider>
  );
};

export default MusicGenerationsProvider;
