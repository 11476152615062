import React, { useEffect } from "react";

import { GoogleTagManager } from "@next/third-parties/google";
import { SubscriptionPlans } from "@prisma/client";
import Lottie from "lottie-react";
import { type AppProps } from "next/app";
import Head from "next/head";
import { type Session } from "next-auth";
import { SessionProvider, useSession } from "next-auth/react";

import PlayMusic from "@musicfy/assets/animations/play-music.json";
import PageLoader from "@musicfy/components/PageLoader";
import NavigationLayout from "@musicfy/layouts/NavigationLayout";
import AnalyticsProvider, {
  useAnalytics,
} from "@musicfy/libs/AnalyticsProvider";
import ApiAccessProvider, {
  useApiAccessContext,
} from "@musicfy/libs/ApiAccessProvider";
import AudioGenerationsProvider from "@musicfy/libs/AudioGenerationsProvider";
import FFmpegProvider from "@musicfy/libs/FFmpegProvider";
import FreeUsageCounterContext from "@musicfy/libs/FreeUsageCounterProvider";
import GlobalAudioPlayerProvider from "@musicfy/libs/GlobalAudioPlayerProvider";
import ModelsProvider, { useModelsContext } from "@musicfy/libs/ModelsProvider";
import MusicGenerationsProvider from "@musicfy/libs/MusicGenerationsProvider";
import PaddleProvider from "@musicfy/libs/PaddleProvider";
import RequireAuthProvider from "@musicfy/libs/RequireAuthProvider";
import StemGenerationsProvider from "@musicfy/libs/StemGenerationsProvider";
import SubscriptionProvider, {
  useSubscriptionContext,
} from "@musicfy/libs/SubscriptionProvider";
import ToastProvider from "@musicfy/libs/ToastProvider";
import ToltLoader from "@musicfy/scripts/ToltLoader";
import { api } from "@musicfy/utils/api";

import "@musicfy/styles/globals.css";

const GTM_ID = "GTM-WL73MKTT";

type IAppProps = {
  Component: INextPage;
  pageProps: AppProps & { session: Session | null };
};

type IComponentProps = {
  Component: INextPage;
  pageProps: AppProps;
};

const MusicfyComponent = ({
  Component,
  pageProps,
}: IComponentProps): JSX.Element => {
  const { data } = useSession();

  const { identify } = useAnalytics();

  const user = data?.user;

  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();
  const { isLoading: isModelsLoading, models } = useModelsContext();
  const { isLoading: isApiAccessLoading } = useApiAccessContext();

  useEffect(() => {
    if (!isSubscriptionLoading && !!user) {
      identify(
        user.id,
        user.email,
        subscription?.plan ?? SubscriptionPlans.free
      );

      if (!!window.tolt_referral) {
        window.tolt.signup(user.email || "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isSubscriptionLoading, subscription, identify]);

  const shouldRankForSEO = Component.seo ?? false;
  const isLoading =
    (isModelsLoading ||
      isSubscriptionLoading ||
      !models.length ||
      isApiAccessLoading) &&
    !shouldRankForSEO;

  if (isLoading) {
    return <PageLoader />;
  }

  return <Component {...pageProps} />;
};

const Musicfy = ({ Component, pageProps }: IComponentProps): JSX.Element => {
  const { status } = useSession();

  const Layout = Component.layout ?? NavigationLayout;
  const shouldRankForSEO = Component.seo ?? false;

  const isLoading = status === "loading" && !shouldRankForSEO;

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Lottie
          color="pink"
          animationData={PlayMusic as unknown}
          style={{
            width: 200,
            height: 200,
          }}
        />
      </div>
    );
  }

  return (
    <RequireAuthProvider>
      <SubscriptionProvider>
        <PaddleProvider>
          <FFmpegProvider>
            <ModelsProvider>
              <ApiAccessProvider>
                <AudioGenerationsProvider>
                  <StemGenerationsProvider>
                    <MusicGenerationsProvider>
                      <FreeUsageCounterContext>
                        <GlobalAudioPlayerProvider>
                          <Layout>
                            <MusicfyComponent
                              Component={Component}
                              pageProps={pageProps}
                            />
                          </Layout>
                        </GlobalAudioPlayerProvider>
                      </FreeUsageCounterContext>
                    </MusicGenerationsProvider>
                  </StemGenerationsProvider>
                </AudioGenerationsProvider>
              </ApiAccessProvider>
            </ModelsProvider>
          </FFmpegProvider>
        </PaddleProvider>
      </SubscriptionProvider>
    </RequireAuthProvider>
  );
};

const Background = () => {
  return (
    <>
      <div className="circle-1 blur-3xl" />
      <div className="circle-2 blur-3xl" />
      <div className="fixed z-[1] w-full h-full bg-white/2" />
    </>
  );
};

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: IAppProps) => {
  return (
    <SessionProvider session={session}>
      <GoogleTagManager gtmId={GTM_ID} />
      <Background />
      <Head>
        <title>Musicfy</title>
      </Head>
      <ToltLoader />
      <AnalyticsProvider>
        <ToastProvider>
          <Musicfy Component={Component} pageProps={pageProps} />
        </ToastProvider>
      </AnalyticsProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(MyApp);
