import { useCallback } from "react";

import Alert from "@mui/material/Alert";
import type { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import type { SnackbarProps } from "@mui/material/Snackbar";

interface IToast {
  toastMessage: string;
  setToastMessage: (message: string) => void;
  autoHide?: boolean;
  severity?: AlertColor;
}

export default function Toast({
  toastMessage,
  setToastMessage,
  autoHide = true,
  severity = "success",
}: IToast) {
  /* Raise a toast.

  props:
    autoHide: if true, hides the snackbar after 3 seconds
    toastMessage: message to show in toast, if empty, toast will hide
    setToastMessage: set toast message
    severity: determines the background color of the snackbar
   */

  const handleToastClose = useCallback(
    (event: unknown, reason: string) => {
      if (reason === "clickaway") {
        return;
      }
      setToastMessage("");
    },
    [setToastMessage]
  );

  const handleAlertClose = useCallback(() => {
    setToastMessage("");
  }, [setToastMessage]);

  const snackbarProps: Partial<SnackbarProps> = {
    anchorOrigin: {
      horizontal: "center",
      vertical: "bottom",
    },
    open: !!toastMessage,
    onClose: handleToastClose,
  };

  if (autoHide) {
    snackbarProps.autoHideDuration = 3000;
  }

  return (
    <Snackbar {...snackbarProps}>
      <Alert onClose={handleAlertClose} severity={severity} variant="filled">
        {toastMessage}
      </Alert>
    </Snackbar>
  );
}
