import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import clsx from "clsx";

import { roobert } from "@musicfy/assets/fonts";
import PageLoader from "@musicfy/components/PageLoader";

export interface IFullScreenWrapperProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  style?: "gradient" | "transparent";
  className?: string;
}

const FullScreenWrapper = ({
  open,
  onClose,
  children,
  isLoading = false,
  style = "gradient",
  className,
}: IFullScreenWrapperProps) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      className={`${className ?? ""} ${roobert.variable} font-sans`}
      PaperProps={{
        className: "!bg-gray-1000/70 !backdrop-blur-2xl",
        style: {
          background:
            style === "gradient"
              ? `
              radial-gradient(500px 500px at 0% 0%, #452844 0%, rgba(40, 48, 69, 0.00) 100%),
              radial-gradient(500px 500px at 100% 100%, #283045 0%, rgba(40, 48, 69, 0.00) 100%),
              #050409
          `
              : "rgba(0,0,0,0)",
          color: "white",
        },
      }}
    >
      {isLoading && (
        <div className="absolute h-screen bg-gray-1000 z-30 w-screen top-0 bottom-0 left-0 bg-gradient-radial from-pink-100/2 to-pink-200/2">
          <PageLoader />
        </div>
      )}
      <div
        role="button"
        tabIndex={0}
        onClick={onClose}
        className="flex self-end xl:m-0 mx-6 mt-9 z-20 xl:absolute top-8 right-8 h-10 p-2 w-10 cursor-pointer items-center justify-center rounded-full bg-white/5 transition-all xl:hover:bg-white/10 border border-white/15"
      >
        <CloseIcon className="!text-22 !h-full !w-full" />
      </div>

      <div
        className={clsx("w-full flex flex-col", {
          "opacity-100  h-full": !isLoading,
          "opacity-0 h-0": isLoading,
        })}
      >
        {children}
      </div>
    </Dialog>
  );
};

export default FullScreenWrapper;
