import {
  type CheckoutLineItem,
  type CheckoutEventsData,
  type PaddleEventData,
  type CheckoutCustomer,
  type PricePreviewResponse,
} from "@paddle/paddle-js";

import {
  type PaddleTransaction,
  type PaddleSubscriptionUpdatePreview,
} from "@musicfy/types/paddle-server";
import { type InferQuery } from "@musicfy/utils";

declare module "@paddle/paddle-js" {
  /** Override values from the @paddle/paddle-js library */
  enum CheckoutEventNames {
    SUBSCRIPTION_FETCHED = "subscription.created",
  }

  interface BaseUpdateCheckoutParams {
    items: CheckoutLineItem[];
    customer: CheckoutCustomer;
  }

  interface CodeUpdateCheckoutParams extends BaseUpdateCheckoutParams {
    discountCode: string | null;
    discountId?: never;
  }

  interface DiscountUpdateCheckoutParams extends BaseUpdateCheckoutParams {
    discountCode?: never;
    discountId: string | null;
  }

  type UpdateCheckoutParams =
    | CodeUpdateCheckoutParams
    | DiscountUpdateCheckoutParams;

  interface ExtendedPaddle extends Paddle {
    Checkout: Paddle["Checkout"] & {
      updateCheckout: (checkout: UpdateCheckoutParams) => void;
    };
    Environment: Paddle["Environment"] & {
      detect: () => Environments | undefined;
      get: () => Environments;
    };
    active?: boolean;
  }

  function initializePaddle(
    options?: InitializePaddleOptions
  ): Promise<ExtendedPaddle | undefined>;
}

/** Custom types for working with our Paddle provider */

export enum PaddleScreens {
  CHECKOUT = "checkout",
  UPDATE_PAYMENT = "update-payment",
  PRICING = "pricing",
  UPGRADE = "upgrade",
}
export type PaddleScreen = `${PaddleScreens}`;
export type PaddleEventCallback = (event: PaddleEventData) => void;

/** Checkout */
type CheckoutEventCallback = (event: CheckoutEventsData) => void;
export type CheckoutEvents = {
  onLoad?: CheckoutEventCallback;
  onClose?: (data: undefined) => void;
  onCheckoutCompleted?: CheckoutEventCallback;
  onCheckoutUpdated?: CheckoutEventCallback;
  onPaymentInitiated?: CheckoutEventCallback;
  onError?: (error: PaddleEventData) => void;
  onSubscriptionCreated?: CheckoutEventCallback;
};
export interface PaddleCheckoutContext {
  checkoutData: CheckoutEventsData | undefined;
  setCheckoutData: (data: CheckoutEventsData | undefined) => void;
  checkoutError: PaddleEventData | undefined;
  setCheckoutError: (data: PaddleEventData | undefined) => void;
  isFetchingSubscriptionRef: React.MutableRefObject<boolean>;
  isCheckoutUpdating: boolean;
  setIsCheckoutUpdating: (isUpdating: boolean) => void;
}

/** Update Payment */
export type UpdatePaymentEventData = CheckoutEventsData;
type UpdatePaymentEventCallback = CheckoutEventCallback;
export type UpdatePaymentEvents = {
  onLoad?: UpdatePaymentEventCallback;
  onClose?: (data: undefined) => void;
  onError?: (error: PaddleEventData) => void;
  onPaymentUpdateUpdated?: UpdatePaymentEventCallback;
  onPaymentUpdateCompleted?: UpdatePaymentEventCallback;
  onPaymentUpdateInitiated?: UpdatePaymentEventCallback;
};
export interface PaddleUpdatePaymentContext {
  updatePaymentData: UpdatePaymentEventData | undefined;
  setUpdatePaymentData: (data: UpdatePaymentEventData | undefined) => void;
  updatePaymentError: PaddleEventData | undefined;
  setUpdatePaymentError: (data: PaddleEventData | undefined) => void;
}

/** Upgrade Preview */
export interface PaddleUpgradePreviewContext {
  upgradePreviewData: PaddleSubscriptionUpdatePreview | undefined;
  setUpgradePreviewData: (
    data: PaddleSubscriptionUpdatePreview | undefined
  ) => void;
  isUpgradeUpdating: boolean;
  setIsUpgradeUpdating: (isUpdating: boolean) => void;
}

/** Prices */

export type PricePreviewData = PricePreviewResponse["data"];

export interface PaddlePricesContext {
  pricesData: PricePreviewData | undefined;
}

/** Transactions */
export interface PaddleTransactionsContext {
  transactions: PaddleTransaction[] | null;
  isLoading: boolean;
  getCustomerTransactions: InferQuery<
    "subscription",
    "getCustomerTransactions",
    "useMutation"
  >;
}

export interface PaddleContext {
  checkoutContext: PaddleCheckoutContext;
  pricesContext: PaddlePricesContext;
  updatePaymentContext: PaddleUpdatePaymentContext;
  upgradePreviewContext: PaddleUpgradePreviewContext;
  transactionsContext: PaddleTransactionsContext;
  subscribeToPaddleEvents: (eventCallback: PaddleEventCallback) => void;
  unsubscribeFromPaddleEvents: (eventCallback: PaddleEventCallback) => void;
  setActivePaddleScreen: (screen: PaddleScreen | null) => void;
  startCheckoutFlow: (origin?: string) => void;
  origin: string | undefined;
  setOrigin: (origin: string | undefined) => void;
}
