import { useCallback, useEffect, useState } from "react";

import { CheckoutEventNames, type PaddleEventData } from "@paddle/paddle-js";

import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import {
  PaddleScreens,
  type UpdatePaymentEvents,
} from "@musicfy/libs/PaddleProvider/types";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { api } from "@musicfy/utils";

/**
 * Events will only be listened to once startUpdatePayment is called
 * In order to listen to events without calling startUpdatePayment, set listenForEvents to true
 * @param {UpdatePaymentEvents} events @default {}
 * @param {boolean} listenForEvents @default false
 */
export const usePaddleUpdatePayment = (
  {
    onLoad,
    onClose,
    onPaymentUpdateCompleted,
    onPaymentUpdateUpdated,
    onPaymentUpdateInitiated,
    onError,
  }: UpdatePaymentEvents = {},
  listenForEvents = false
) => {
  const {
    updatePaymentContext,
    subscribeToPaddleEvents,
    unsubscribeFromPaddleEvents,
    setActivePaddleScreen,
  } = usePaddleContext();
  const { subscription } = useSubscriptionContext();

  const [isUpdatePaymentProcessing, setIsUpdatePaymentProcessing] =
    useState(false);

  const { setUpdatePaymentData, setUpdatePaymentError } = updatePaymentContext;

  const { mutateAsync: fetchTransaction } =
    api.subscription.getUpdatePaymentTransaction.useMutation();

  const eventsCallback = useCallback(
    (event: PaddleEventData) => {
      // Paddle Update Payments
      switch (event.type) {
        case "checkout.error":
          onError?.(event);
          setUpdatePaymentError(event);
          break;
      }

      if (!event.data) {
        return;
      }

      switch (event.name) {
        case CheckoutEventNames.CHECKOUT_LOADED:
          onLoad?.(event.data);
          setUpdatePaymentData(event.data);
          break;
        case CheckoutEventNames.CHECKOUT_UPDATED:
          onPaymentUpdateUpdated?.(event.data);
          setUpdatePaymentData(event.data);
          break;
        case CheckoutEventNames.CHECKOUT_COMPLETED:
          onPaymentUpdateCompleted?.(event.data);
          setUpdatePaymentData(event.data);
          setIsUpdatePaymentProcessing(false);
          window.location.reload();
          break;
        case CheckoutEventNames.CHECKOUT_PAYMENT_INITIATED:
          onPaymentUpdateInitiated?.(event.data);
          setIsUpdatePaymentProcessing(true);
          setUpdatePaymentData(event.data);
          break;
        case CheckoutEventNames.CHECKOUT_PAYMENT_FAILED:
          setIsUpdatePaymentProcessing(false);
          break;
        case CheckoutEventNames.CHECKOUT_CLOSED:
          if (isUpdatePaymentProcessing) {
            break;
          }
          onClose?.(undefined);
          setUpdatePaymentError(undefined);
          setUpdatePaymentData(undefined);
          setIsUpdatePaymentProcessing(false);
          break;
        default:
          setIsUpdatePaymentProcessing(false);
          break;
      }
    },
    [
      onError,
      setUpdatePaymentError,
      onLoad,
      setUpdatePaymentData,
      onPaymentUpdateUpdated,
      onPaymentUpdateCompleted,
      onPaymentUpdateInitiated,
      isUpdatePaymentProcessing,
      onClose,
    ]
  );

  useEffect(() => {
    if (!!listenForEvents) {
      subscribeToPaddleEvents(eventsCallback);
    }

    return () => {
      unsubscribeFromPaddleEvents(eventsCallback);
    };
  }, [
    eventsCallback,
    listenForEvents,
    subscribeToPaddleEvents,
    unsubscribeFromPaddleEvents,
  ]);

  const startUpdatePayment = useCallback(async (): Promise<void> => {
    /** We skip subscribing since we already subscribed to paddle events on mount */
    if (!subscription || !window.Paddle) {
      return;
    }

    if (!listenForEvents) {
      subscribeToPaddleEvents(eventsCallback);
    }

    setActivePaddleScreen(PaddleScreens.UPDATE_PAYMENT);

    const transaction = await fetchTransaction({
      subscriptionId: subscription.subscriptionId,
    });

    if (!transaction) {
      setActivePaddleScreen(null);
      return;
    }

    window.Paddle.Checkout.open({
      customer: {
        id: subscription.customerId,
      },
      transactionId: transaction.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventsCallback,
    fetchTransaction,
    listenForEvents,
    setActivePaddleScreen,
    subscribeToPaddleEvents,
    subscription,
    subscription?.subscriptionId,
  ]);

  const closeUpdatePayment = useCallback(() => {
    if (!window.Paddle || isUpdatePaymentProcessing) {
      return;
    }
    window.Paddle.Checkout.close();
    setActivePaddleScreen(null);
    unsubscribeFromPaddleEvents(eventsCallback);
  }, [
    eventsCallback,
    isUpdatePaymentProcessing,
    setActivePaddleScreen,
    unsubscribeFromPaddleEvents,
  ]);

  return {
    startUpdatePayment,
    closeUpdatePayment,
    eventsCallback,
  };
};
