import React, { createContext, useContext } from "react";

import { useSession } from "next-auth/react";

import { api } from "@musicfy/utils";
import { useArray } from "@musicfy/utils/hooks";

import { type TAudioGeneration } from "./IAudioGenerations";

interface IAudioGenerationsContext {
  audioGenerations: TAudioGeneration[];
  setAudioGenerations: React.Dispatch<React.SetStateAction<TAudioGeneration[]>>;
  isAudioGenerationsLoading: boolean;
}

const AudioGenerationsContext = createContext<IAudioGenerationsContext | null>(
  null
);

export function useAudioGenerationsContext() {
  const audioGenerationsContext = useContext(AudioGenerationsContext);

  if (!audioGenerationsContext) {
    throw new Error(
      "useAudioGenerationsContext must be used within a AudioGenerationsProvider"
    );
  }

  return audioGenerationsContext;
}

const AudioGenerationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status } = useSession();

  const { value: audioGenerations, setValue: setAudioGenerations } =
    useArray<TAudioGeneration>([]);

  const { isFetching: isAudioGenerationsLoading } =
    api.audioGenerations.getAudioGenerations.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setAudioGenerations(data);
      },
      retry: false,
      enabled: status === "authenticated",
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
    });

  const audioGenerationsContextValue = {
    audioGenerations: audioGenerations,
    setAudioGenerations: setAudioGenerations,
    isAudioGenerationsLoading: isAudioGenerationsLoading,
  };

  return (
    <AudioGenerationsContext.Provider value={audioGenerationsContextValue}>
      {children}
    </AudioGenerationsContext.Provider>
  );
};

export default AudioGenerationsProvider;
