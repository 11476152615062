/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";

import { env } from "@musicfy/env.mjs";
import { type AppRouter } from "@musicfy/server/api/root";

export const config = {
  api: {
    responseLimit: "40mb",
  },
};

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (env.VERCEL_URL) return `https://${env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${env.PORT ?? 3000}`; // dev SSR should use localhost
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: superjson,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            opts.direction === "down" && opts.result instanceof Error,
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

/**
 * Inference helper for query outputs.
 *
 * @example type HelloQueryOutput = TQueryOutput<'example', 'hello'>
 */
export type TQueryOutput<
  TRoute extends keyof RouterOutputs,
  TQuery extends keyof RouterOutputs[TRoute],
> = RouterOutputs[TRoute][TQuery];

type API = typeof api;

type InferProcedure<
  TRoute extends keyof API,
  TQuery extends keyof API[TRoute],
> = {
  [K in keyof API[TRoute][TQuery]]: API[TRoute][TQuery][K];
};

type IsCallable<T> = T extends (...args: never[]) => unknown
  ? ReturnType<T>
  : T[];

export type InferQuery<
  TRoute extends keyof API,
  TQuery extends keyof API[TRoute],
  TProcedure extends keyof InferProcedure<TRoute, TQuery>,
> = IsCallable<API[TRoute][TQuery][TProcedure]>;
