import React, { useRef, useState } from "react";
import { createContext, useContext, type ReactNode } from "react";

import { type AudioPlayerRef } from "@musicfy/components/AudioPlayer";
import { useArray } from "@musicfy/utils/hooks";

import GlobalAudioPlayer from "./GlobalAudioPlayer";
import { type IAudioSource } from "./types";

interface IGlobalAudioPlayerProviderProps {
  children: ReactNode;
}

interface IGlobalAudioPlayerContext {
  audioSources: IAudioSource[];
  setAudioSources: (
    state: React.SetStateAction<IAudioSource[]>,
    autoPlay?: boolean
  ) => void;
  isPlaying: boolean;
  play: AudioPlayerRef["play"] | undefined;
  pause: AudioPlayerRef["pause"] | undefined;
}

const GlobalAudioPlayerContext =
  createContext<IGlobalAudioPlayerContext | null>(null);

export const useGlobalAudioPlayerContext = () => {
  const toastContext = useContext(GlobalAudioPlayerContext);

  if (!toastContext) {
    throw new Error(
      "useGlobalAudioPlayerContext must be used within a GlobalAudioPlayerProvider"
    );
  }

  return toastContext;
};

const GlobalAudioPlayerProvider: React.FC<IGlobalAudioPlayerProviderProps> = ({
  children,
}) => {
  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  const audioPlayerRef = useRef<AudioPlayerRef>(null);

  const { value: audioSources, setValue: _setAudioSources } =
    useArray<IAudioSource>([]);

  const setAudioSources = (
    state: React.SetStateAction<IAudioSource[]>,
    autoPlay = false
  ) => {
    _setAudioSources(state);
    setAutoPlay(autoPlay);
  };

  const globalAudioPlayerContextValue = {
    audioSources: audioSources,
    setAudioSources: setAudioSources,
    isPlaying: !audioPlayerRef.current?.audio?.paused,
    play: audioPlayerRef.current?.play,
    pause: audioPlayerRef.current?.pause,
  };

  return (
    <GlobalAudioPlayerContext.Provider value={globalAudioPlayerContextValue}>
      {children}
      <GlobalAudioPlayer
        audioSources={audioSources}
        autoPlay={autoPlay}
        audioPlayerRef={audioPlayerRef}
      />
    </GlobalAudioPlayerContext.Provider>
  );
};

export default GlobalAudioPlayerProvider;
