import { BeatLoader } from "react-spinners";

const PageLoader = (): JSX.Element => {
  return (
    <div className="min-h-full w-full flex-grow flex items-center justify-center">
      <BeatLoader color="white" />
    </div>
  );
};

export default PageLoader;
