interface CardProps {
  children?: React.ReactNode;
}

const Card = ({ children }: CardProps): JSX.Element => {
  return (
    <div className="bg-white/3 border border-pink-200/50 relative flex items-center overflow-hidden rounded-12 gap-3 p-3">
      <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
      <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
      <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
      <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
      <div className="relative z-20 w-full">{children}</div>
    </div>
  );
};

export default Card;
