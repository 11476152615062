import clsx from "clsx";

import { isString } from "../utils";

export interface ISwitcherOption<T> {
  value: T;
  label: string | React.ReactNode;
  "aria-label"?: string;
}

interface ISwitcherProps<T> {
  value: T;
  setValue: (newVal: T) => void;
  options: ISwitcherOption<T>[];
  className?: string;
  labelClassName?: string;
  sliderClassName?: string;
}

function Switcher<T>({
  value,
  setValue,
  options,
  className = "",
  labelClassName = "",
  sliderClassName = "",
}: ISwitcherProps<T>) {
  const activeOptionIndex = options.findIndex(
    ({ value: optionValue }) => optionValue === value
  );

  return (
    <div
      className={`relative grid w-full rounded-full overflow-hidden border border-pink-200/80 ${className}`}
      style={{
        gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))`,
      }}
    >
      {options.map(
        ({ value: optionValue, label, "aria-label": ariaLabel }, index) => {
          const isSelected = optionValue === value;
          return (
            <div
              key={isString(label) ? label : index}
              role="button"
              aria-label={`${isSelected ? "Selected" : "Switch To"} ${
                ariaLabel || label?.toString() || ""
              }`}
              tabIndex={0}
              onClick={() => setValue(optionValue)}
              className={clsx(
                "relative z-20 bg-white/1 xl:hover:bg-white/5 text-12 capitalize w-full cursor-pointer items-center flex justify-center px-6 py-3 text-center font-bold transition-all duration-300",
                labelClassName,
                {
                  "border-r border-pink-200/80": index !== options.length - 1,
                }
              )}
            >
              {label}
            </div>
          );
        }
      )}
      <div
        className="absolute h-full transition-all duration-300"
        style={{
          width: `calc(100% / ${options.length})`,
          left: `calc(100% / ${options.length} * ${activeOptionIndex})`,
        }}
      >
        <div
          className={clsx(
            "h-full w-full bg-gradient-to-r from-pink-300 to-pink-400",
            sliderClassName
          )}
        />
      </div>
    </div>
  );
}

export default Switcher;
