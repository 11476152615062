import { type ForwardedRef, forwardRef } from "react";

import clsx from "clsx";
import Link from "next/link";
import { BeatLoader } from "react-spinners";

enum ButtonVariants {
  contained = "contained",
  outlined = "outlined",
  text = "text",
}

enum ButtonStyles {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  danger = "danger",
  solid = "solid",
}

type TButtonStyles = keyof typeof ButtonStyles;
type TButtonVariants = keyof typeof ButtonVariants;

export interface IButtonProps {
  children?: React.ReactNode;
  variant?: TButtonVariants;
  style?: TButtonStyles;
  className?: string;
  childClassName?: string;
  hoverClassName?: string;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  loaderColor?: string;
  path?: string;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonStyle?: React.CSSProperties;
  buttonProps?: React.HTMLProps<HTMLButtonElement>;
  fullWidth?: boolean;
  loaderSize?: number;
}

const ButtonBase: React.FC<IButtonProps> = forwardRef(
  (
    {
      children,
      variant = "contained",
      className = "",
      childClassName = "",
      hoverClassName = "",
      preIcon,
      postIcon,
      loading,
      disabled,
      loaderColor = "white",
      style = "primary",
      fullWidth,
      buttonStyle,
      loaderSize = 16,
      ...buttonProps
    },
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const isDisabled = disabled || loading;
    let buttonClassName = "";
    let hoverClass = "";

    if (variant === "contained") {
      buttonClassName = `${buttonClassName} px-6 rounded-8`;
      hoverClass =
        "absolute z-10 transition-all duration-300 opacity-0 xl:group-hover/button:opacity-100 h-full w-full duration-300 top-0 left-0";
      if (style === "primary") {
        const backgroundColor = "bg-gradient-to-r from-pink-400 to-pink-300";
        const textColor = "text-white";
        const hoverTextColor = `text-white`;
        const hoverColor = "bg-gradient-to-l from-purple to-pink-300";
        buttonClassName = `${buttonClassName} ${backgroundColor} ${textColor} ${
          isDisabled ? "" : `xl:hover:${hoverTextColor}`
        }`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      } else if (style === "secondary") {
        const backgroundColor = "bg-gradient-to-br from-green to-green-dark";
        const textColor = "text-gray-1000";
        const hoverColor = "bg-gradient-to-tl from-green to-gold";
        buttonClassName = `${buttonClassName} ${backgroundColor} ${textColor}`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      } else if (style === "tertiary") {
        const backgroundColor =
          "bg-gradient-radial from-white/0 to-white/5 border border-white/[20%] border-opacity-20";
        const textColor = "text-white";
        const hoverColor =
          "bg-gradient-to-r from-gray-900/[70%] to-gray-600/[10%]";
        buttonClassName = `${buttonClassName} ${backgroundColor} ${textColor}`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      } else if (style === "danger") {
        const backgroundColor = "bg-gradient-to-br from-red to-red-dark";
        const textColor = "text-white";
        const hoverTextColor = "xl:hover:text-red";
        const hoverColor = "bg-gradient-to-tl from-white to-gray-400";
        buttonClassName = `${buttonClassName} ${backgroundColor} ${textColor} ${
          isDisabled ? "" : hoverTextColor
        }`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      } else if (style === "solid") {
        const backgroundColor = "bg-gray-1000";
        const textColor = "text-gray-200";
        const hoverTextColor = "xl:hover:text-white";
        const hoverColor = "bg-gray-800";
        buttonClassName = `${buttonClassName} ${backgroundColor} ${textColor} ${
          isDisabled ? "" : hoverTextColor
        }`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      }
    } else if (variant === "outlined") {
      buttonClassName = `${buttonClassName} outline outline-1 rounded-8 px-6 group/button text-white`;
      hoverClass =
        "absolute transition-all duration-300 opacity-0 xl:group-hover/button:opacity-100 h-full w-full duration-300 top-0 left-0";
      if (style === "primary") {
        buttonClassName = `${buttonClassName} outline-2 outline-pink-200/30 bg-white/2 xl:hover:bg-white/5 backdrop-blur-xl font-light`;
      } else if (style === "secondary") {
        buttonClassName = `${buttonClassName} outline-white/30 bg-white/5 xl:hover:bg-white/15 backdrop-blur`;
      } else if (style === "tertiary") {
        const outlineColor = "!outline-gray-100";
        const backgroundColor = "bg-gray-900";
        const hoverColor = "bg-black";
        buttonClassName = `${buttonClassName} ${outlineColor} ${backgroundColor}`;
        hoverClass = `${hoverClass} ${hoverColor}`;
      } else if (style === "danger") {
        buttonClassName = `${buttonClassName} before:from-red before:to-red-dark`;
      }
    } else if (variant === "text") {
      buttonClassName = `${buttonClassName} text-16 px-0`;
      hoverClass =
        "absolute transition-all w-[0%] xl:group-hover/button:w-full h-px -bottom-1 left-0";
      if (style === "primary") {
        buttonClassName = `${buttonClassName} text-purple ${
          isDisabled ? "" : "xl:hover:text-purple"
        }`;
        childClassName = `${childClassName} text-transparent bg-clip-text bg-gradient-to-r from-purple to-pink-100`;
        hoverClass = `${hoverClass} bg-gradient-to-r from-purple to-pink-100`;
      } else if (style === "secondary") {
        buttonClassName = `${buttonClassName} text-green ${
          isDisabled ? "" : "xl:hover:text-green"
        }`;
        childClassName = `${childClassName} text-transparent bg-clip-text bg-gradient-to-r from-green to-green-light`;
        hoverClass = `${hoverClass} bg-gradient-to-r from-green to-green-light`;
      } else if (style === "tertiary") {
        buttonClassName = `${buttonClassName} text-white ${
          isDisabled ? "" : "xl:hover:text-white"
        }`;
        childClassName = `${childClassName} text-transparent bg-clip-text bg-gradient-to-tr from-white to-gray-400`;
        hoverClass = `${hoverClass} bg-gradient-to-r from-white to-gray-400`;
      } else if (style === "danger") {
        buttonClassName = `${buttonClassName} text-red ${
          isDisabled ? "" : "xl:hover:text-red"
        }`;
        hoverClass = `${hoverClass} bg-gradient-to-r from-red to-red-dark`;
      }
    }

    buttonClassName = `${buttonClassName} ${className} ${
      fullWidth ? "w-full" : ""
    } transition-all duration-350`;

    return (
      <button
        ref={ref}
        {...buttonProps}
        disabled={isDisabled}
        className={clsx(
          "transition-all duration-300 relative box-border flex items-center justify-center gap-3 group/button isolate overflow-hidden min-h-fit py-3 font-sans normal-case font-bold",
          className,
          buttonClassName,
          {
            "w-full": fullWidth,
          }
        )}
        style={buttonStyle}
      >
        {loading ? (
          <BeatLoader
            color={loaderColor}
            className="m-auto"
            size={loaderSize}
          />
        ) : (
          <>
            {!!preIcon && (
              <div className="z-30 mr-auto flex items-center">{preIcon}</div>
            )}

            <div className={clsx("z-20 relative flex-grow", childClassName)}>
              {children}
              {!isDisabled && variant === ButtonVariants.text && (
                <span
                  className={clsx(hoverClass, hoverClassName)}
                  style={{
                    transitionDuration: "350ms",
                  }}
                ></span>
              )}
            </div>

            {!!postIcon && (
              <div className="z-30 ml-auto flex items-center">{postIcon}</div>
            )}
          </>
        )}
        {!isDisabled && variant !== ButtonVariants.text && (
          <span className={clsx(hoverClass, hoverClassName)}></span>
        )}
      </button>
    );
  }
);

ButtonBase.displayName = "ButtonBase";

const Button: React.FC<IButtonProps> = ({ path, target, ...props }) => {
  if (path) {
    if (!!target) {
      return (
        <a href={path} target={target}>
          <ButtonBase {...props} />
        </a>
      );
    }
    return (
      <Link href={path} className="block w-fit">
        <ButtonBase {...props} />
      </Link>
    );
  }

  return <ButtonBase {...props} />;
};

export default Button;
