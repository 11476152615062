import { useCallback, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import MUIDialog from "@mui/material/Dialog";

import { roobert } from "@musicfy/assets/fonts";
import { useIsMobile } from "@musicfy/utils/hooks";

import { type Reason, Step } from "./constants";
import CancelReason from "./Dialogs/CancelReason";
import ConfirmCancellation from "./Dialogs/ConfirmCancellation";
import ConfirmPauseSubscription from "./Dialogs/ConfirmPauseSubscription";
import ContactSupport from "./Dialogs/ContactSupport";
import Discount from "./Dialogs/Discount";
import PauseSubscription from "./Dialogs/PauseSubscription";

interface CancellationWizardProps {
  isOpen: boolean;
  onClose: () => void;
  cancellationReason: Reason | string;
  setCancellationReason: (reason: Reason | string) => void;
}

const CancellationWizard = ({
  isOpen,
  onClose,
  cancellationReason,
  setCancellationReason,
}: CancellationWizardProps): JSX.Element => {
  const isMobile = useIsMobile();

  const [step, setStep] = useState<Step>(Step.PAUSE_SUBSCRIPTION);

  const handleClose = useCallback(() => {
    setStep(Step.PAUSE_SUBSCRIPTION);
    setCancellationReason("");
    onClose();
  }, [onClose, setCancellationReason]);

  return (
    <MUIDialog
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={isOpen}
      onClose={handleClose}
      className={`${roobert.variable} font-sans backdrop-blur-md`}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: isMobile ? "none" : "450px",
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: "12px",
          color: "white",
        },
      }}
    >
      <div className="relative bg-gray-1000/70 backdrop-blur-3xl justify-center border border-pink-200/60 rounded-12 text-14 text-white">
        <div
          role="button"
          tabIndex={0}
          onClick={handleClose}
          className="flex absolute z-20 right-3 top-3 h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white/10 transition-all xl:hover:-bg-white/10 xl:hover:bg-white/20"
        >
          <CloseIcon className="!text-16" />
        </div>
        {step === Step.PAUSE_SUBSCRIPTION && (
          <PauseSubscription onClose={handleClose} setStep={setStep} />
        )}
        {step === Step.CONFIRM_PAUSE_SUBSCRIPTION && (
          <ConfirmPauseSubscription onClose={handleClose} setStep={setStep} />
        )}
        {step === Step.CANCEL_REASON && (
          <CancelReason
            cancellationReason={cancellationReason}
            setCancellationReason={setCancellationReason}
            setStep={setStep}
          />
        )}
        {step === Step.CONTACT_SUPPORT && (
          <ContactSupport onClose={handleClose} setStep={setStep} />
        )}
        {step === Step.DISCOUNT && (
          <Discount onClose={handleClose} setStep={setStep} />
        )}
        {step === Step.CONFIRM && (
          <ConfirmCancellation
            onClose={handleClose}
            setStep={setStep}
            cancellationReason={cancellationReason}
            setCancellationReason={setCancellationReason}
          />
        )}
      </div>
    </MUIDialog>
  );
};

export default CancellationWizard;
