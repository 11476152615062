import { useCallback, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import EmailAuthButton from "@musicfy/components/EmailAuthButton";
import GoogleAuthButton from "@musicfy/components/GoogleAuthButton";
import Icon from "@musicfy/components/Icon";
import Input from "@musicfy/components/Input";
import { api, isValidEmail } from "@musicfy/utils";

import { useRequireAuthContext } from ".";

const RequireAuthTakeover = () => {
  const { hideAuthModal } = useRequireAuthContext();
  const router = useRouter();

  const [email, setEmail] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const doesUserExistMutation = api.users.doesUserExist.useMutation();

  const handleEmailAuthClick = useCallback(async () => {
    if (email === "") {
      setValidationError("Email required");
      return;
    }

    if (!isValidEmail(email)) {
      setValidationError("Please enter a valid email");
      return;
    }
    setIsLoading(true);

    const doesUserExist = await doesUserExistMutation.mutateAsync({
      email,
    });

    if (!doesUserExist) {
      setValidationError("Account does not exist");
      setIsLoading(false);
      return;
    }

    signIn("email", {
      email: email,
      callbackUrl: router.query.callbackPath
        ? (router.query.callbackPath as string)
        : window.location.origin,
    });
  }, [router, doesUserExistMutation, email]);

  return (
    <div className="z-[10000] lg:bg-gray-900/5 bg-gray-1000/40 px-6 py-9 lg:px-9 h-full w-full flex flex-col items-center top-0 fixed backdrop-blur-xl">
      <div className="lg:hidden w-full flex relative items-center justify-center box-border">
        <div />
        <div className="text-center text-20">Login</div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => hideAuthModal()}
          className="absolute right-0 h-10 w-10 p-2 cursor-pointer lg:flex items-center justify-center rounded-full border border-white/15 xl:hover:border-white/50 bg-violet transition-all"
        >
          <CloseIcon className="!text-22 !pb-1" />
        </div>
      </div>
      <div className="flex gap-4 items-center cursor-pointer lg:relative absolute top-auto bottom-12 lg:bottom-auto lg:top-auto">
        <Icon name="logo" className="w-7" />
        <Icon name="logo-text" className="w-24" />
      </div>
      <div className="w-full relative lg:max-w-3xl my-auto rounded-8 lg:border border-white/15 lg:px-28 lg:py-16 lg:bg-gray-1000/90 lg:backdrop-blur-lg lg:bg-gradient-to-b from-navy/40 to-navy/5">
        <div
          role="button"
          tabIndex={0}
          onClick={() => hideAuthModal()}
          className="hidden absolute -translate-y-1/2 translate-x-1/2 top-1 right-1 h-10 w-10 p-2 cursor-pointer lg:flex items-center justify-center rounded-full border border-white/15 xl:hover:border-white/50 bg-violet transition-all"
        >
          <CloseIcon className="!text-22 !h-full !w-full" />
        </div>
        <div className="text-42 font-bold lg:block hidden text-pink-200">
          Login
        </div>
        <div className="lg:mt-9 mt-4">
          <Input
            label="Email"
            value={email}
            onChange={(e) => {
              e.preventDefault();
              setValidationError("");
              setEmail(e.target.value);
            }}
            onSubmit={handleEmailAuthClick}
            placeholder="johnsmith@gmail.com"
            type="email"
            autoComplete="email"
            containerClassName="mt-3 !bg-white/1"
            errorMessage={validationError}
          />
        </div>
        <div className="lg:mt-8 mt-4 flex flex-col lg:gap-6 gap-4">
          <EmailAuthButton onClick={handleEmailAuthClick} loading={isLoading} />
          <div className="flex items-center gap-3">
            <div className="h-px w-full bg-white/15" />
            <div>Or</div>
            <div className="h-px w-full bg-white/15" />
          </div>
          <GoogleAuthButton />
        </div>
        <div className="mt-9 text-center">
          Don&apos;t have an account yet?{" "}
          <Link
            className="text-pink-200 font-bold underline"
            href={{
              pathname: `/auth/signup`,
              query: router.query.callbackPath && {
                callbackPath: router.query.callbackPath,
              },
            }}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequireAuthTakeover;
