import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import type { DialogProps } from "@mui/material/Dialog";

import { roobert } from "@musicfy/assets/fonts";

export interface IFullScreenTakeoverProps extends DialogProps {
  children?: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  title?: string;
}

const FullScreenTakeover: React.FC<IFullScreenTakeoverProps> = ({
  children,
  open,
  onClose,
  title,
  ...dialogProps
}) => {
  return (
    <Dialog
      {...dialogProps}
      fullScreen
      open={open}
      onClose={onClose}
      className={`${roobert.variable} font-sans`}
      PaperProps={{
        className: "!bg-black/5 !backdrop-blur-2xl !text-white",
        style: {
          boxShadow: "none",
        },
      }}
    >
      <div
        role="button"
        aria-label="Close Modal"
        tabIndex={0}
        onClick={onClose}
        className="flex xl:sticky self-end xl:m-0 mx-6 mt-4 z-30 top-8 right-8 h-10 p-2 w-10 cursor-pointer items-center justify-center rounded-full border border-white/15 xl:hover:border-white/30 bg-white/5 transition-all xl:hover:bg-white/20"
      >
        <CloseIcon className="!text-22 !h-full !w-full" />
      </div>
      <div className="relative mx-auto w-full p-6 xl:max-w-screen-xxl xl:p-9">
        {!!title && (
          <div className="z-20 flex items-center justify-between xl:pt-6 pb-6">
            <div className="truncate text-32 font-bold capitalize">{title}</div>
          </div>
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default FullScreenTakeover;
