import React, { createContext, useContext } from "react";

import { useSession } from "next-auth/react";

import { api } from "@musicfy/utils";
import { useArray } from "@musicfy/utils/hooks";

import { type TStemGeneration } from "./IStemGenerations";

interface IStemGenerationsContext {
  stemGenerations: TStemGeneration[];
  setStemGenerations: React.Dispatch<React.SetStateAction<TStemGeneration[]>>;
  isStemGenerationsLoading: boolean;
}

const StemGenerationsContext = createContext<IStemGenerationsContext | null>(
  null
);

export function useStemGenerationsContext() {
  const stemGenerationsContext = useContext(StemGenerationsContext);

  if (!stemGenerationsContext) {
    throw new Error(
      "useStemGenerationsContext must be used within a StemGenerationsProvider"
    );
  }

  return stemGenerationsContext;
}

const StemGenerationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status } = useSession();

  const { value: stemGenerations, setValue: setStemGenerations } =
    useArray<TStemGeneration>([]);

  const { isFetching: isStemGenerationsLoading } =
    api.stemGenerations.getStemGenerations.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setStemGenerations(data);
      },
      retry: false,
      enabled: status === "authenticated",
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
    });

  const stemGenerationsContextValue = {
    stemGenerations: stemGenerations,
    setStemGenerations: setStemGenerations,
    isStemGenerationsLoading: isStemGenerationsLoading,
  };

  return (
    <StemGenerationsContext.Provider value={stemGenerationsContextValue}>
      {children}
    </StemGenerationsContext.Provider>
  );
};

export default StemGenerationsProvider;
