function cleanFilename(name: string): string {
  let filename = name.replace(/[ '\-()]/g, "_");
  filename = filename.replace(/&/g, "and");
  filename = filename.replace(/[$%]/g, "");
  filename = filename.replace(/[^a-zA-Z0-9.]/g, "_");

  const lastPeriodIndex = filename.lastIndexOf(".");
  if (lastPeriodIndex !== -1) {
    const extension = filename.substr(lastPeriodIndex);
    const nameWithoutExtension = filename.substr(0, lastPeriodIndex);
    const nameWithoutPeriods = nameWithoutExtension.replace(/\./g, "_");
    filename = nameWithoutPeriods + extension;
  }

  return filename;
}

export { cleanFilename };
