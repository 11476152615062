import clsx from "clsx";

import AudioPlayer, {
  type AudioPlayerRef,
} from "@musicfy/components/AudioPlayer";
import Dropdown, { type IDropdownOption } from "@musicfy/components/Dropdown";
import Icon from "@musicfy/components/Icon";
import Switcher from "@musicfy/components/Switcher";
import { downloadFile } from "@musicfy/utils";

import { type IAudioSource } from "../types";

interface IGlobalAudioPlayerProviderProps {
  artists: IDropdownOption<string>[];
  audioPlayerRef: React.MutableRefObject<AudioPlayerRef | null>;
  audioSource: IAudioSource;
  audioSources: IAudioSource[];
  audioTypes: IDropdownOption<string>[];
  filenames: IDropdownOption<string>[];
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  selectedArtist: string;
  selectedAudioType: string;
  selectedFilename: string;
  setSelectedAudioSourceIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  autoPlay?: boolean;
}

type AudioSourceSearchableKeys = keyof Omit<IAudioSource, "value" | "id">;

function findAudioSourceIndex(
  audioSources: IAudioSource[],
  primaryKey: AudioSourceSearchableKeys,
  params: { artist: string; filename: string; label: string }
): number {
  let index = 0;

  let key2: AudioSourceSearchableKeys = primaryKey;
  let key3: AudioSourceSearchableKeys = primaryKey;

  Object.keys(params).forEach((paramKey) => {
    if (paramKey !== primaryKey) {
      key2 = paramKey as AudioSourceSearchableKeys;
    }
  });

  Object.keys(params).forEach((paramKey) => {
    if (paramKey !== primaryKey && paramKey !== key2) {
      key3 = paramKey as AudioSourceSearchableKeys;
    }
  });

  index = audioSources.findIndex((source) => {
    return (
      source[primaryKey] === params[primaryKey] &&
      source[key2] === params[key2] &&
      source[key3] === params[key3]
    );
  });

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return (
        source[primaryKey] === params[primaryKey] &&
        source[key2] === params[key2]
      );
    });
  }

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return (
        source[primaryKey] === params[primaryKey] &&
        source[key3] === params[key3]
      );
    });
  }

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return source[primaryKey] === params[primaryKey];
    });
  }

  return index >= 0 ? index : 0;
}

const MobileGlobalAudioPlayer = ({
  artists,
  audioPlayerRef,
  audioSource,
  audioSources,
  audioTypes,
  filenames,
  isExpanded,
  onNextClick,
  onPrevClick,
  selectedArtist,
  selectedAudioType,
  selectedFilename,
  setSelectedAudioSourceIndex,
  setIsExpanded,
  autoPlay = false,
}: IGlobalAudioPlayerProviderProps): JSX.Element => {
  return (
    <>
      <div className="w-screen overflow-x-hidden relative bg-gray-1000/90 backdrop-blur-2xl border-t border-white/15 px-6 pt-4 pb-3">
        <div className="absolute z-0 w-full h-full bg-gradient-radial from-navy/70 to-navy/10 blur-lg top-0 left-0 pointer-events-none" />
        <div
          className={clsx("transition-all w-full duration-500", {
            "opacity-full max-h-36 mb-3": isExpanded,
            "opacity-0 max-h-0 overflow-hidden mb-0": !isExpanded,
          })}
        >
          <div className="w-full flex items-center gap-4">
            <div className="w-full overflow-x-hidden">
              <Dropdown
                options={filenames}
                value={selectedFilename}
                setValue={(newFilename) => {
                  const audioSourceIndex = findAudioSourceIndex(
                    audioSources,
                    "filename",
                    {
                      artist: selectedArtist,
                      filename: newFilename,
                      label: selectedAudioType,
                    }
                  );

                  setSelectedAudioSourceIndex(audioSourceIndex);
                }}
              />
            </div>
            <div
              onClick={() => setIsExpanded((prev) => !prev)}
              role="button"
              tabIndex={0}
              className={clsx(
                "bg-white/5 border mr-auto rounded-full flex items-center justify-center transition-all",
                {
                  "h-0 w-0 opacity-0 border-transparent": !isExpanded,
                  "h-10 w-10 aspect-square opacity-full border-white/15":
                    isExpanded,
                }
              )}
            >
              <Icon
                name="expand"
                className={clsx("transition-all", {
                  "-rotate-90": isExpanded,
                  "rotate-90": !isExpanded,
                })}
              />
            </div>
          </div>
          {audioTypes.length > 1 && (
            <Switcher
              labelClassName="!px-3 !py-2 !font-light"
              className="mt-4"
              options={audioTypes}
              value={selectedAudioType}
              setValue={(newAudioType) => {
                const audioSourceIndex = findAudioSourceIndex(
                  audioSources,
                  "label",
                  {
                    artist: selectedArtist,
                    filename: selectedFilename,
                    label: newAudioType,
                  }
                );
                setSelectedAudioSourceIndex(audioSourceIndex);
              }}
            />
          )}
        </div>
        <div
          className={clsx(
            "relative rounded-12 border transition-all duration-500 w-full",
            {
              "p-[10px] bg-white/4 border-white/15 gap-6": isExpanded,
              "p-0 bg-white/0 border-transparent": !isExpanded,
            }
          )}
        >
          <div className="flex items-stretch justify-between w-full">
            <div
              className={clsx("flex transition-all truncate", {
                "gap-4": !isExpanded || artists.length === 1,
                "gap-0": isExpanded && artists.length > 1,
              })}
            >
              <div
                className={clsx(
                  "h-10 rounded-4 bg-gradient-radial from-white/0 to-white/3 border border-white/10 flex items-center justify-center",
                  {
                    "w-10 opacity-100 p-3": !isExpanded || artists.length === 1,
                    "w-0 opacity-0 p-0": isExpanded && artists.length > 1,
                  }
                )}
              >
                <Icon name="logo" />
              </div>
              <div className="w-full max-w-[65%]">
                <div className="flex items-center">
                  <div
                    className={clsx("transition-all whitespace-nowrap", {
                      "opacity-0 max-w-0 max-h-0":
                        isExpanded && artists.length > 1,
                      "opacity-full max-w-32 max-h-10":
                        !isExpanded || artists.length === 1,
                    })}
                  >
                    {selectedArtist}
                  </div>
                  <div
                    className={clsx({
                      "opacity-0 max-w-0 max-h-0 overflow-hidden":
                        !isExpanded || artists.length === 1,
                      "opacity-full max-w-[60vw] max-h-10":
                        isExpanded && artists.length > 1,
                    })}
                  >
                    <Dropdown
                      variant="text"
                      options={artists}
                      value={selectedArtist}
                      setValue={(newArtist) => {
                        const audioSourceIndex = findAudioSourceIndex(
                          audioSources,
                          "artist",
                          {
                            artist: newArtist,
                            filename: selectedFilename,
                            label: selectedAudioType,
                          }
                        );
                        setSelectedAudioSourceIndex(audioSourceIndex);
                      }}
                    />
                  </div>
                </div>
                <div className="text-12 text-white/70 truncate">
                  {selectedFilename}
                </div>
              </div>
            </div>
            <div
              className={clsx(
                "flex flex-grow flex-shrink-0 justify-end transition-all",
                {
                  "gap-4": !isExpanded,
                  "gap-0": isExpanded,
                }
              )}
            >
              <div
                aria-label={`Download ${selectedFilename}`}
                onClick={() => downloadFile({ url: audioSource.value })}
                role="button"
                tabIndex={0}
                className="h-full aspect-square bg-white/5 rounded-full flex items-center justify-center border border-pink-200 transition-all"
              >
                <Icon name="download" className="w-[12px]" />
              </div>
              {audioSources.length > 1 && (
                <div
                  onClick={() => setIsExpanded((prev) => !prev)}
                  role="button"
                  tabIndex={0}
                  className={clsx(
                    "bg-white/5 border rounded-full flex items-center justify-center transition-all duration-500",
                    {
                      "h-0 w-0 opacity-0 border-transparent": isExpanded,
                      "h-10 aspect-square opacity-full border-white/15":
                        !isExpanded,
                    }
                  )}
                >
                  <Icon
                    name="expand"
                    className={clsx("transition-all", {
                      "-rotate-90": isExpanded,
                      "rotate-90": !isExpanded,
                    })}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-1">
            <AudioPlayer
              ref={audioPlayerRef}
              audioSrc={audioSource.value}
              onNextClick={isExpanded ? onNextClick : undefined}
              onPrevClick={isExpanded ? onPrevClick : undefined}
              controlsPosition={isExpanded ? "bottom" : "left"}
              timePosition={isExpanded ? "bottom" : "right"}
              controlSize="large"
              autoPlay={autoPlay}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileGlobalAudioPlayer;
