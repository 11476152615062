import { type HTMLAttributes, useState } from "react";

import clsx from "clsx";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  className?: string;
  labelClassName?: string;
  name?: string;
  label?: string;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  errorMessage?: string;
  errorClassName?: HTMLAttributes<HTMLDivElement>["className"];
  onSubmit?: () => void;
}

const Input: React.FC<InputProps> = ({
  className = "",
  label,
  containerClassName = "",
  labelClassName = "",
  name,
  preIcon,
  postIcon,
  onSubmit,
  errorMessage,
  errorClassName,
  ...inputProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "relative flex rounded-12 border px-5 py-4 transition-all duration-300 bg-white/5 xl:hover:border-gray-400",
          containerClassName,
          {
            "border-white/50": isFocused,
            "border-white/20": !isFocused,
            "!border-red": !!errorMessage,
          }
        )}
      >
        {!!preIcon && (
          <div className="absolute left-4 top-0 flex h-full items-center justify-center self-center">
            {preIcon}
          </div>
        )}

        <div
          className={clsx("w-full", {
            "pl-8": !!preIcon,
            "pr-8": !!postIcon,
          })}
        >
          {!!label && (
            <div className={clsx("text-12 font-light mb-2", labelClassName)}>
              {label}
            </div>
          )}
          <input
            className={clsx("bg-transparent w-full text-20", className)}
            name={name}
            {...inputProps}
            onFocus={(e) => {
              setIsFocused(true);
              inputProps?.onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              inputProps?.onBlur?.(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit?.();
              }
            }}
          />
        </div>

        {!!postIcon && (
          <div className="absolute right-4 top-0 flex h-full items-center justify-center self-center">
            {postIcon}
          </div>
        )}
      </div>
      {!!errorMessage && (
        <div className={clsx("text-red text-center mt-2", errorClassName)}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default Input;
