import { useCallback, useEffect, useState } from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SubscriptionPlans } from "@prisma/client";
import clsx from "clsx";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import Icon from "@musicfy/components/Icon";
import Input from "@musicfy/components/Input";
import PageLoader from "@musicfy/components/PageLoader";
import { MODEL_TRAINING_MAX_QUOTA } from "@musicfy/contants/Features";
import {
  AdditionalVoicesId,
  PriceId,
  ProductId,
  type TAdditionalVoicesId,
  type TPriceId,
} from "@musicfy/contants/Subscriptions";
import { formatPrice } from "@musicfy/utils";

import CheckoutItem from "./shared/CheckoutItem";
import PaddleWrapper from "./shared/PaddleWrapper";
import { usePaddleContext } from "..";
import { usePaddleCheckout } from "../hooks";

const AdditionalVoices = (): JSX.Element | null => {
  const {
    checkoutContext: { checkoutData },
  } = usePaddleContext();
  const { updateNumberOfAdditionalVoices } = usePaddleCheckout();

  const [localNumberOfAdditionalVoices, setLocalNumberOfAdditionalVoices] =
    useState(0);

  const isEligibleForAdditionalVoices = checkoutData?.items.some(
    (item) => item.product.id === ProductId.label
  );

  const additiontalVoicesLineItem = checkoutData?.items.find((item) =>
    Object.values(AdditionalVoicesId).includes(
      item.price_id as TAdditionalVoicesId
    )
  );

  useEffect(() => {
    setLocalNumberOfAdditionalVoices(additiontalVoicesLineItem?.quantity ?? 0);
  }, [additiontalVoicesLineItem]);

  if (!isEligibleForAdditionalVoices) {
    return null;
  }

  const totalNumberOfCustomVoices =
    MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.label] +
    localNumberOfAdditionalVoices;

  return (
    <div className="w-full py-6 border-b border-solid border-gray-400">
      <div className="flex items-center justify-between">
        <div className="text-white/90">Additional Voices</div>
        <div className="flex items-center gap-2">
          <div
            role="button"
            className="text-24"
            onClick={() => {
              if (!additiontalVoicesLineItem) {
                return;
              }
              updateNumberOfAdditionalVoices(
                additiontalVoicesLineItem.quantity - 1
              );
            }}
          >
            -
          </div>
          <Input
            containerClassName="!max-w-20 flex !py-2 !px-0"
            className="text-center !text-16"
            value={localNumberOfAdditionalVoices}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setLocalNumberOfAdditionalVoices(isNaN(value) ? 0 : value);
            }}
            onBlur={(e) => {
              const value = isNaN(parseInt(e.target.value))
                ? 0
                : parseInt(e.target.value);
              if (
                value === additiontalVoicesLineItem?.quantity ||
                (value === 0 && !additiontalVoicesLineItem)
              ) {
                return;
              }
              updateNumberOfAdditionalVoices(value);
            }}
            onSubmit={() => {
              const value = isNaN(localNumberOfAdditionalVoices)
                ? 0
                : localNumberOfAdditionalVoices + 1;
              if (
                value === additiontalVoicesLineItem?.quantity ||
                (value === 0 && !additiontalVoicesLineItem)
              ) {
                return;
              }
              updateNumberOfAdditionalVoices(value);
            }}
          />
          <div
            role="button"
            className="text-24"
            onClick={() => {
              const value = (additiontalVoicesLineItem?.quantity ?? 0) + 1;
              updateNumberOfAdditionalVoices(value);
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="flex font-bold text-20 items-center justify-between mt-6">
        <div>Total # of Custom Voices</div>
        <div>{totalNumberOfCustomVoices}</div>
      </div>
    </div>
  );
};

const PromoCode = (): JSX.Element | null => {
  const {
    checkoutContext: { checkoutData, checkoutError },
  } = usePaddleContext();
  const { applyDiscountCode, removeDiscountCode } = usePaddleCheckout();

  const [shouldAddPromoCode, setShouldAddPromoCode] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [isUpdatingDiscountCode, setIsUpdatingDiscountCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = useCallback(async () => {
    const formattedDiscountCode = discountCode.trim().toUpperCase();
    if (!formattedDiscountCode || !!errorMessage) {
      return;
    }
    setIsUpdatingDiscountCode(true);
    const { success } = await applyDiscountCode(formattedDiscountCode);

    if (!success) {
      setErrorMessage("Invalid promo code");
      setIsUpdatingDiscountCode(false);
    }
  }, [applyDiscountCode, discountCode, errorMessage]);

  const handleRemoveDiscountCode = useCallback(() => {
    setIsUpdatingDiscountCode(true);
    removeDiscountCode();
  }, [removeDiscountCode]);

  useEffect(() => {
    if (isUpdatingDiscountCode && !!checkoutData) {
      setIsUpdatingDiscountCode(false);
      return;
    }

    if (checkoutError && isUpdatingDiscountCode) {
      setIsUpdatingDiscountCode(false);
      setErrorMessage("Invalid promo code");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData, checkoutError]);

  if (!checkoutData) {
    return null;
  }

  const formattedDiscount = formatPrice(
    checkoutData.totals.discount,
    checkoutData.currency_code
  );

  if (!!checkoutData.discount?.code) {
    return (
      <>
        <div className="flex items-center justify-between">
          <div>
            <div className="font-light text-gray-400 text-12">Promo Code</div>
            <div className="font-bold mt-1">{checkoutData.discount.code}</div>
          </div>
          <div className="flex items-center gap-1">
            <Button
              style="solid"
              variant="text"
              onClick={handleRemoveDiscountCode}
              loading={isUpdatingDiscountCode}
              preIcon={<DeleteOutlineIcon className="text-white" />}
            >
              <div className="font-bold pt-1 text-white">
                {formattedDiscount}
              </div>
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="relative">
      <Button
        onClick={() => setShouldAddPromoCode(true)}
        className={clsx(
          "!text-white underline text-20 !absolute pointer-events-none",
          {
            "opacity-0 ml-4": shouldAddPromoCode,
            "opacity-100 ml-0": !shouldAddPromoCode,
          }
        )}
        variant="text"
        style="solid"
      >
        Add Promo Code
      </Button>
      <div
        className={clsx("transition-all", {
          "opacity-0": !shouldAddPromoCode,
          "opacity-100": shouldAddPromoCode,
        })}
      >
        <Input
          onClick={() => {
            setShouldAddPromoCode(true);
          }}
          autoFocus
          value={discountCode}
          onChange={(e) => {
            setDiscountCode(e.target.value.toLocaleUpperCase());
            setErrorMessage("");
          }}
          errorMessage={errorMessage}
          onBlur={(e) => {
            if (e.target.value.trim().length === 0) {
              setShouldAddPromoCode(false);
            }
          }}
          onSubmit={handleSubmit}
          placeholder="Add promotion code"
          className={clsx("!text-14 py-1", {
            "cursor-pointer": !shouldAddPromoCode,
          })}
          errorClassName="text-12"
          postIcon={
            !!discountCode &&
            !errorMessage && (
              <Button
                loading={isUpdatingDiscountCode}
                variant="text"
                style="solid"
                className="!min-h-min !text-white"
                onClick={handleSubmit}
              >
                Apply
              </Button>
            )
          }
        />
      </div>
    </div>
  );
};

const CheckoutPreview = (): JSX.Element | null => {
  const {
    checkoutContext: { checkoutData, isCheckoutUpdating },
  } = usePaddleContext();

  if (!checkoutData) {
    return null;
  }

  const {
    totals,
    items,
    recurring_totals,
    currency_code: currencyCode,
  } = checkoutData;
  const formattedRecurringPrice = !!recurring_totals
    ? formatPrice(recurring_totals.total, currencyCode)
    : null;
  const formattedTotalPrice = formatPrice(totals.total, currencyCode);
  const formattedSubtotal = formatPrice(totals.subtotal, currencyCode);
  const formattedTax = formatPrice(totals.tax, currencyCode);

  const checkoutPlanLineItem = items.find((item) =>
    Object.values(PriceId).includes(item.price_id as TPriceId)
  );

  let billingInterval =
    checkoutPlanLineItem?.billing_cycle?.interval ?? "month";
  billingInterval = billingInterval === "month" ? "Monthly" : "Yearly";

  return (
    <div
      className={clsx("w-full", {
        "pointer-events-none": isCheckoutUpdating,
      })}
    >
      <div className="flex gap-2 items-center justify-center max-xxl:pt-6">
        <Icon name="logo-white" className="text-white fill-current w-5 h-5" />
        <Icon name="logo-text" />
      </div>
      <div className="mt-[120px]">
        <div className="heading flex flex-col gap-2 font-bold text-48">
          <span>{formattedRecurringPrice || formattedTotalPrice} </span>
          {recurring_totals && (
            <span className="text-16 font-bold text-white tracking-wide">
              Billed {billingInterval}
            </span>
          )}
        </div>
      </div>
      <div className="mt-12">
        {!!checkoutPlanLineItem && (
          <CheckoutItem
            currencyCode={currencyCode}
            item={checkoutPlanLineItem}
          />
        )}
        <AdditionalVoices />
      </div>
      <div className="mt-6 flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="text-white font-bold text-20">Subtotal</div>
          <div className="font-bold">{formattedSubtotal}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-white font-bold text-20">Tax</div>
          <div className="font-bold">{formattedTax}</div>
        </div>
        <Divider />
        <PromoCode />
        <Divider />
        <div className="flex items-center justify-between">
          <div className="text-white font-bold text-20">Due Today</div>
          <div className="font-bold">{formattedTotalPrice}</div>
        </div>
      </div>
    </div>
  );
};

const Checkout = (): JSX.Element => {
  const {
    checkoutContext: { checkoutData, isCheckoutUpdating },
  } = usePaddleContext();

  const { closeCheckout } = usePaddleCheckout();

  return (
    <PaddleWrapper open onClose={closeCheckout} isLoading={!checkoutData}>
      {isCheckoutUpdating && (
        <div className="absolute rounded-12 z-10 w-full h-full inset-0 bg-black/50">
          <PageLoader />
        </div>
      )}
      <CheckoutPreview />
    </PaddleWrapper>
  );
};

export default Checkout;
