import { useCallback, useState } from "react";

import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import Button, { type IButtonProps } from "@musicfy/components/Button";
import Icon from "@musicfy/components/Icon";

import { FEATURE_ROUTES } from "./Navigation/constants";

type IGoogleAuthButtonProps = IButtonProps;

const GoogleAuthButton: React.FC<IGoogleAuthButtonProps> = ({
  ...buttonProps
}) => {
  const router = useRouter();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const handleGoogleAuthClick = useCallback(() => {
    const callbackPath = router.query.callbackPath
      ? (router.query.callbackPath as string)
      : router.asPath;

    setIsGoogleLoading(true);

    signIn("google", {
      callbackUrl:
        !callbackPath || callbackPath === "/"
          ? FEATURE_ROUTES.voice
          : callbackPath,
    });
  }, [router.asPath, router.query.callbackPath]);

  return (
    <Button
      preIcon={<Icon name="google" className="w-6 h-6" />}
      onClick={handleGoogleAuthClick}
      loading={isGoogleLoading}
      {...buttonProps}
      variant="outlined"
      className="!text-white !bg-transparent"
    >
      Continue with Google
    </Button>
  );
};

export default GoogleAuthButton;
