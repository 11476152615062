import React, { useState } from "react";

import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

import Icon from "@musicfy/components/Icon";
import {
  APP_ROUTES,
  APPS,
  EApps,
  findActiveSection,
  type IAppInfo,
  type IFeatureInfo,
} from "@musicfy/components/Navigation/constants";
import { useIsAdmin } from "@musicfy/utils/hooks";

import UserAccount from "../UserAccount";

const NavigationItem: React.FC<{
  isActiveApp: boolean;
  activeSection: IFeatureInfo | undefined;
  isNavOpen: boolean;
  appInfo: IAppInfo;
}> = ({ isActiveApp, isNavOpen, appInfo, activeSection }) => {
  const {
    icon,
    displayName,
    key,
    features: sections,
    isPremium: isAppPremium,
    isNew: isAppNew,
  } = appInfo;
  const appRoute = appInfo.mainRoute || "/";

  return (
    <div
      className={clsx("border-b transition-all py-4", {
        "border-white/0": !isNavOpen,
        "border-white/20": isNavOpen,
      })}
    >
      <Link key={key} href={appRoute}>
        <div className="flex items-center gap-3 group">
          <div
            className={clsx(
              "group flex h-fit cursor-pointer items-center gap-4 font-bold"
            )}
          >
            <div
              className={clsx(
                "h-8 w-8 rounded-full relative bg-violet flex items-center justify-center transition-all",
                {
                  "bg-opacity-100": isActiveApp,
                  "transition-all bg-opacity-0 xl:group-hover:bg-opacity-30":
                    !isActiveApp,
                  "ml-1": !isNavOpen,
                }
              )}
            >
              <Icon name={icon} />
            </div>
          </div>

          <div
            className={clsx(
              "whitespace-nowrap font-bold transition-all text-16 flex items-center gap-2",
              {
                "opacity-100": isNavOpen,
                "opacity-0": !isNavOpen,
                "text-white": isActiveApp,
                "text-gray-500 group-hover:!text-gray-300": !isActiveApp,
              }
            )}
          >
            <div>{displayName}</div>
            {isAppPremium && <Icon name="crown" className="w-4 h-auto" />}
            {isAppNew && <Icon name="new" className="w-12 h-auto" />}
          </div>
        </div>
      </Link>
      <div
        className="translate-x-11 overflow-hidden transition-all"
        style={{
          height: isNavOpen ? sections.length * 28 : 0,
          opacity: isNavOpen ? 1 : 0,
        }}
      >
        {sections.map((section) => {
          const { displayName, key } = section;
          const sectionRoute = section.route || "/";
          const isActiveSection = key === activeSection?.key;
          const isFeaturePremium = section.isPremium;

          return (
            <Link key={displayName} href={sectionRoute}>
              <div
                className={clsx(
                  "my-2 h-5 cursor-pointer text-14 transition-all flex items-center gap-2",
                  {
                    "text-gray-500 transition-all xl:hover:text-white":
                      !isActiveSection,
                  }
                )}
              >
                <div>{displayName}</div>
                {isFeaturePremium && (
                  <Icon name="crown" className="w-4 h-auto grayscale" />
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const MainNavigation: React.FC<{
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  activeApp: IAppInfo | undefined;
  activeSection: IFeatureInfo | undefined;
  toggleNavPinned: () => void;
  isNavPinned: boolean;
}> = ({
  isNavOpen,
  setIsNavOpen,
  activeApp,
  isNavPinned,
  toggleNavPinned,
  activeSection,
}) => {
  const isAdmin = useIsAdmin();

  const isOpen = isNavOpen || isNavPinned;

  return (
    <div
      onMouseEnter={() => {
        setIsNavOpen(true);
      }}
      onMouseLeave={() => {
        setIsNavOpen(false);
      }}
      className={clsx(
        "sticky overflow-y-auto top-0 hidden xl:flex px-4 z-50 backdrop-blur-md box-border h-screen flex-col overflow-hidden shadow-2xl rounded-r-8 transition-all bg-gradient-to-t from-teal to-transparent",
        {
          "w-52": isOpen,
          "w-20": !isOpen,
        }
      )}
    >
      <div
        className={clsx(
          "flex transition-all h-16 w-full items-center justify-between py-4 border-b border-solid border-white",
          {
            "border-opacity-30": isOpen,
            "border-opacity-0": !isOpen,
          }
        )}
      >
        <div>
          <Link href={APP_ROUTES[EApps.DASHBOARD]}>
            <div className="flex items-center gap-1">
              <Icon
                name="logo"
                className={clsx("w-6 h-6 transition-all", {
                  "ml-0": isOpen,
                  "ml-2": !isOpen,
                })}
              />
              <div
                className={clsx(
                  "overflow-hidden scale-[.9] font-bold transition-all",
                  {
                    "w-[0%] opacity-0": !isOpen,
                    "w-full opacity-100": isOpen,
                  }
                )}
              >
                <Icon name="logo-text" className="ml-2" />
              </div>
            </div>
          </Link>
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={toggleNavPinned}
          className={clsx(
            "flex h-full cursor-pointer items-center transition-all",
            {
              "opacity-0": !isOpen,
              "opacity-100": isOpen,
              "-scale-x-100": !isNavPinned,
            }
          )}
        >
          <Icon name="expand" />
        </div>
      </div>
      <div className="flex flex-grow flex-col">
        {APPS.map((appInfo) => {
          const { key, isAdminProtected } = appInfo;
          if (isAdminProtected && !isAdmin) {
            return null;
          }

          const isActiveApp = activeApp?.key === key;

          return (
            <NavigationItem
              key={key}
              isActiveApp={isActiveApp}
              activeSection={activeSection}
              isNavOpen={isOpen}
              appInfo={appInfo}
            />
          );
        })}
      </div>
    </div>
  );
};

const DesktopNavigation: React.FC<{
  toggleNavPinned: () => void;
  isNavPinned: boolean;
}> = ({ toggleNavPinned, isNavPinned }) => {
  const router = useRouter();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const currentAppRoute = router.pathname.split("/")[1];
  const activeApp = !currentAppRoute
    ? undefined
    : APPS.find(
        ({ route }) =>
          currentAppRoute === route.split("/")[1] && !route.includes("http")
      ) ?? (APPS[0] as IAppInfo);

  const activeSection = !activeApp
    ? undefined
    : findActiveSection(router.asPath, activeApp.features);

  return (
    <>
      <div className="fixed justify-between overflow-hidden w-96 px-6 hidden top-4 right-0 z-50 transition-all xl:flex items-center gap-4">
        {router.asPath !== "/" && (
          <Link
            href="/"
            className="rounded-full font-medium py-2 backdrop-blur-xl bg-gray-1000/40 xl:hover:bg-gray-1000/60 text-pink-200 text-14 min-w-fit px-6 border border-white/20 xl:hover:border-white/40 transition-all flex gap-2"
          >
            <Icon name="explore" className="w-4 h-auto" />
            <div>Explore</div>
          </Link>
        )}
        <div className="ml-auto p-1 flex items-center justify-center rounded-full w-fit overflow-hidden backdrop-blur-xl">
          <UserAccount />
        </div>
      </div>
      <MainNavigation
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
        activeApp={activeApp}
        activeSection={activeSection}
        toggleNavPinned={toggleNavPinned}
        isNavPinned={isNavPinned}
      />
    </>
  );
};

export default DesktopNavigation;
