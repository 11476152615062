import {
  type Subscription,
  SubscriptionPlans,
  SubscriptionStatus,
} from "@prisma/client";

import {
  ENABLED_PLANS_BY_FEATURE,
  type Features,
} from "@musicfy/contants/Features";

export const canUseFeature = (
  feature: Features,
  subscription: Subscription | null
): boolean => {
  const plan = subscription?.plan || SubscriptionPlans.free;
  const status = !subscription
    ? SubscriptionStatus.active
    : subscription?.status;

  const isActiveSubscription =
    status === SubscriptionStatus.active ||
    status === SubscriptionStatus.trialing;

  return (
    (ENABLED_PLANS_BY_FEATURE[feature]?.includes(plan) &&
      isActiveSubscription) ??
    false
  );
};
