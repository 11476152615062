import { useCallback, useEffect, useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Skeleton } from "@mui/material";
import clsx from "clsx";

import Button from "@musicfy/components/Button";
import Dropdown from "@musicfy/components/Dropdown";
import Icon from "@musicfy/components/Icon";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { type PaddleTransaction } from "@musicfy/types/paddle-server";
import { api, formatPrice, getLocale } from "@musicfy/utils";

import { SettingsPanel, type TSettingsPanel } from "../../types";

interface PaymentsProps {
  setActivePanel: (panel: TSettingsPanel) => void;
  onClose: () => void;
}

interface InvoiceMenuProps {
  transactions: PaddleTransaction[];
  selectedTransactionIndex: number;
  onInvoiceClick: (index: number) => void;
}

const MobileInvoiceMenu = ({
  transactions,
  selectedTransactionIndex,
  onInvoiceClick,
}: InvoiceMenuProps): JSX.Element => {
  const options = transactions.map((transaction, index) => {
    const transactionDate = new Date(
      transaction.billed_at || transaction.created_at
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return {
      label: transactionDate,
      value: index,
    };
  });

  return (
    <div className="xl:hidden">
      <Dropdown
        options={options}
        value={selectedTransactionIndex}
        setValue={(index) => onInvoiceClick(index)}
      />
    </div>
  );
};

const DesktopInvoiceMenu = ({
  transactions,
  selectedTransactionIndex,
  onInvoiceClick,
}: InvoiceMenuProps): JSX.Element => {
  return (
    <div className="xl:flex hidden flex-col w-full">
      {transactions.map((transaction, index) => {
        const isSelected = index === selectedTransactionIndex;

        const transactionDate = new Date(
          transaction.billed_at || transaction.created_at
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        return (
          <div
            key={transaction.id}
            role="button"
            tabIndex={0}
            onClick={() => onInvoiceClick(index)}
            className={clsx("transition-all border-b py-5 cursor-pointer", {
              "border-white/90": isSelected,
              "border-white/20 text-white/40 xl:hover:border-white/50 xl:hover:text-white/80":
                !isSelected,
              "!pt-0": index === 0,
            })}
          >
            {transactionDate}
          </div>
        );
      })}
    </div>
  );
};

const InvoiceCard = ({
  transaction,
  downloadPDF,
  isGeneratinngPDF,
}: {
  transaction: PaddleTransaction;
  downloadPDF: (transactionId: string) => void;
  isGeneratinngPDF: boolean;
}): JSX.Element => {
  const locale = getLocale();

  const invoiceNumber = transaction.invoice_number;

  const transactionDate = new Date(
    transaction.billed_at || transaction.created_at
  ).toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const periodStart = !!transaction.billing_period
    ? new Date(transaction.billing_period.starts_at).toLocaleDateString(
        locale,
        {
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      )
    : null;
  const periodEnd = !!transaction.billing_period
    ? new Date(transaction.billing_period.ends_at).toLocaleDateString(locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : null;
  const billingPeriod =
    !!periodStart && !!periodEnd ? `${periodStart}   —   ${periodEnd}` : null;

  const product = transaction.items[0]?.price.name;

  const totals = transaction.details.totals;
  const currencyCode = totals.currency_code;

  const totalAmount = formatPrice(
    parseInt(totals.total, 10) / 100,
    currencyCode
  );

  const amountCharged = formatPrice(
    parseInt(totals.grand_total, 10) / 100,
    currencyCode
  );

  const discount = formatPrice(
    parseInt(totals.discount, 10) / 100,
    currencyCode
  );

  const credit = formatPrice(parseInt(totals.credit, 10) / 100, currencyCode);

  return (
    <div className="xl:col-span-5 w-full rounded-8 border border-white/15 lg:p-12 p-6 bg-gradient-to-b from-navy/20 to-navy flex xl:flex-row flex-col">
      <div className="grid w-fit gap-y-7 grid-cols-2">
        {!!invoiceNumber && (
          <>
            <div className="text-gray-500">
              Invoice {!!transaction.invoice_number ? "Number" : "ID"}:
            </div>
            <div className="capitalize text-right font-light text-white/90">
              {invoiceNumber}
            </div>
          </>
        )}
        <div className="text-gray-500">Date:</div>
        <div className="capitalize text-right font-light text-white/90">
          {transactionDate}
        </div>
        {!!billingPeriod && (
          <>
            <div className="text-gray-500">Billing Period:</div>
            <div className="capitalize text-right break-words xl:whitespace-pre font-light text-white/90">
              {billingPeriod}
            </div>
          </>
        )}
        {!!product && (
          <>
            <div className="text-gray-500">Product/Service:</div>
            <div className="capitalize text-right font-light text-white/90">
              {product}
            </div>
          </>
        )}
        <div className="text-gray-500 pt-6 border-t border-white/20">
          Discounts:
        </div>
        <div className="capitalize text-right pt-6 border-t border-white/20 font-light text-white/90">
          {discount}
        </div>
        <div className="text-gray-500">Credits:</div>
        <div className="capitalize text-right font-light text-white/90">
          {credit}
        </div>
        <div className="text-white">Total Amount:</div>
        <div className="capitalize text-right">{totalAmount}</div>
        <div className="text-white">Total Charged:</div>
        <div className="capitalize text-right">{amountCharged}</div>
      </div>
      <div className="xl:ml-auto xl:mt-0 mt-6">
        <Button
          onClick={() => downloadPDF(transaction.id)}
          className="rounded-full xl:!min-h-[26px] xl:!w-44 !w-full"
          variant="outlined"
          style="primary"
          loading={isGeneratinngPDF}
          loaderSize={8}
        >
          <div className="flex w-full justify-center items-center gap-2 xl:text-12">
            <Icon name="download" className="xl:w-2 w-4" />
            <div>Download PDF</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

const Payments = ({ setActivePanel }: PaymentsProps): JSX.Element | null => {
  const {
    transactionsContext: {
      transactions,
      isLoading: isTransactionsLoading,
      getCustomerTransactions,
    },
  } = usePaddleContext();
  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();

  const [isGeneratinngPDF, setIsGeneratingPDF] = useState<boolean>(false);

  const { mutateAsync: generatePDF } =
    api.subscription.generateTransactionPDF.useMutation({
      onMutate: () => {
        setIsGeneratingPDF(true);
      },
      onSettled: () => {
        setIsGeneratingPDF(false);
      },
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
    });

  const [selectedTransactionIndex, setSelectedTransactionIndex] =
    useState<number>(0);

  const transaction = useMemo(() => {
    return transactions?.[selectedTransactionIndex] || null;
  }, [selectedTransactionIndex, transactions]);

  const downloadPDF = useCallback(
    (transactionId: string) => {
      generatePDF({ transactionId });
    },
    [generatePDF]
  );

  useEffect(() => {
    if (!transactions && !!subscription && !isSubscriptionLoading) {
      getCustomerTransactions.mutate({ customerId: subscription.customerId });
    }

    if (!isSubscriptionLoading && !subscription) {
      setActivePanel(SettingsPanel.general);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriptionLoading, transactions]);

  if (isTransactionsLoading || !transactions?.length || !transaction) {
    return (
      <>
        <Button
          variant="text"
          style="tertiary"
          className="text-22"
          onClick={() => setActivePanel(SettingsPanel.general)}
          preIcon={<ArrowBackIcon />}
        >
          Account Settings
        </Button>

        <div className="text-32 font-medium mt-9">Payment History:</div>

        <div className="grid grid-cols-6 gap-9 mt-9">
          <Skeleton
            className="xl:!block !hidden"
            height={250}
            variant="rounded"
            width="100%"
            animation="wave"
          />
          <Skeleton
            className="col-span-6 xl:col-span-5"
            height={250}
            variant="rounded"
            width="100%"
            animation="wave"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Button
        variant="text"
        style="tertiary"
        className="text-22"
        onClick={() => setActivePanel(SettingsPanel.general)}
        preIcon={<ArrowBackIcon />}
      >
        Account Settings
      </Button>

      <div className="text-32 font-medium xl:mt-9 mt-4">Payment History:</div>
      <div className="xl:grid xl:grid-cols-6 xl:gap-9 xl:mt-9 flex flex-col mt-6 gap-6">
        <DesktopInvoiceMenu
          transactions={transactions}
          selectedTransactionIndex={selectedTransactionIndex}
          onInvoiceClick={(index) => setSelectedTransactionIndex(index)}
        />
        <MobileInvoiceMenu
          transactions={transactions}
          selectedTransactionIndex={selectedTransactionIndex}
          onInvoiceClick={(index) => setSelectedTransactionIndex(index)}
        />
        <InvoiceCard
          transaction={transaction}
          downloadPDF={downloadPDF}
          isGeneratinngPDF={isGeneratinngPDF}
        />
      </div>
    </>
  );
};

export default Payments;
