import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, Select, type SelectProps } from "@mui/material";
import clsx from "clsx";

import Icon from "@musicfy/components/Icon";

export interface IDropdownOption<T> {
  value: T;
  label: string | React.ReactNode;
}

interface IDropDownProps<T> extends Omit<SelectProps, "variant"> {
  value: T;
  setValue: (newVal: T) => void;
  options: IDropdownOption<T>[];
  variant?: "outlined" | "text" | "secondary" | "tertiary";
  placeholder?: string;
}

function Dropdown<T>({
  value,
  setValue,
  options,
  placeholder = "",
  variant = "outlined",
  ...selectProps
}: IDropDownProps<T>) {
  const valueIndex = options.findIndex(
    ({ value: optionValue }) => optionValue === value
  );

  return (
    <Select
      {...selectProps}
      variant="outlined"
      value={valueIndex >= 0 ? valueIndex : -1}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              "& .MuiMenuItem-root": {
                padding: "8px 12px",
                margin: "4px 8px",
                borderRadius: 2,
                transition: "all 0.2s ease",
                fontSize: 14,
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.05)",
              },
              "& .Mui-selected": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                borderRadius: 2,
              },
              "& .Mui-selected:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.15)",
              },
            },
            className:
              "!bg-gray-1000/15 border !border-white/30 !text-white backdrop-blur-xl",
          },
        },
      }}
      renderValue={(val) => {
        const option = options[Number(val)];
        const label =
          valueIndex < 0 && placeholder ? placeholder : option?.label;

        return (
          <div
            aria-label={valueIndex > 0 ? (label as string) : ""}
            style={{
              gap:
                variant === "outlined" || variant === "secondary" ? "8px" : "0",
            }}
            className={clsx("flex items-center h-full pr-4", {
              "bg-gradient-radial  from-[rgb(20,20,20)] to-[rgb(50,50,50)] justify-center rounded-8 px-6 py-3":
                variant === "secondary",
              "bg-gradient-to-t from-navy to-navy/20 justify-between rounded-8 px-6 py-3":
                variant === "tertiary",
            })}
          >
            {variant === "outlined" && (
              <div className="border-r !border-white/15 px-2 py-2">
                <ArrowDropDownIcon />
              </div>
            )}
            <div
              style={{
                marginLeft:
                  variant === "outlined" || variant === "secondary"
                    ? "8px"
                    : "0",
                fontSize:
                  variant === "outlined" || variant === "secondary"
                    ? "14px"
                    : "18px",
              }}
              className="truncate"
            >
              {label}
            </div>
            {(variant === "secondary" || variant === "tertiary") && (
              <div className="px-2 py-2">
                <Icon name="arrow-chevron-down" />
              </div>
            )}
            {variant === "text" && (
              <div>
                <ArrowDropDownIcon />
              </div>
            )}
          </div>
        );
      }}
      sx={{
        boxShadow: "none",
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        "& .MuiSelect-iconOutlined": {
          display: "none !important",
        },
        "& .MuiSelect-outlined": {
          padding: "0 !important",
        },
      }}
      slotProps={{
        root: {
          className: `${
            variant === "outlined"
              ? "!border !border-white/20 xl:hover:!border-white/50 !bg-white/3 backdrop-blur-md"
              : ""
          } w-full !rounded-8 !text-white !py-0 transition-all`,
        },
      }}
      className="!font-sans"
      onChange={(event) => {
        const index = Number(event.target.value);
        const option = options[index];
        if (!option) {
          return;
        }
        const value = option.value;
        setValue(value);
      }}
    >
      {options.map(({ label }, index) => {
        return (
          <MenuItem key={index} value={index}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default Dropdown;
