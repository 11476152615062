import { forwardRef, useImperativeHandle } from "react";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Skeleton from "@mui/material/Skeleton";

import Controls, { type IControlsProps } from "./Controls";
import Track from "./Track";
import useAudioPlayer from "./useAudioPlayer";

export type TControlsPosition = "right" | "left" | "top" | "bottom";
export type TTimePosition = "right" | "left" | "top" | "bottom";

interface IAudioPlayerProps
  extends Omit<IControlsProps, "isPlaying" | "handlePlayPause"> {
  audioSrc: string;
  type?: "circle" | "full";
  containerClassName?: string;
  time?: number;
  lazyLoad?: boolean;
  showControls?: boolean;
  controlsPosition?: TControlsPosition;
  timePosition?: TTimePosition;
  autoPlay?: boolean;
  onPlay?: () => void;
  onError?: () => void;
  onPause?: () => void;
  onLoad?: (audio: HTMLAudioElement) => void;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onAudioEnd?: () => void;
}

export interface AudioPlayerRef {
  play: () => void;
  pause: () => void;
  audio: HTMLAudioElement | null;
  handlePlayPause: (e: React.MouseEvent) => void;
}

const AudioPlayer = forwardRef<AudioPlayerRef, IAudioPlayerProps>(
  (
    {
      audioSrc,
      onPlay,
      onError,
      onPause,
      onLoad,
      onNextClick,
      onPrevClick,
      onAudioEnd,
      showControls = true,
      controlsPosition = "right",
      timePosition = "right",
      type = "full",
      containerClassName = "",
      lazyLoad = false,
      autoPlay = false,
      ...controlProps
    },
    ref
  ) => {
    const {
      audio,
      isLoading,
      play,
      pause,
      restart,
      togglePlayPause,
      currentTime,
      duration,
      getCurrentTime,
      isPlaying,
    } = useAudioPlayer(audioSrc, lazyLoad, autoPlay, {
      onPlay,
      onError,
      onPause,
      onLoad,
      onAudioEnd,
    });

    useImperativeHandle(ref, () => ({
      play,
      pause,
      getCurrentTime,
      audio,
      handlePlayPause: togglePlayPause,
    }));

    if (isLoading && type !== "circle") {
      return (
        <Skeleton
          className="!bg-white/8"
          height={28}
          variant="rounded"
          width="100%"
        />
      );
    }

    if (isLoading && type === "circle") {
      return (
        <div className="w-10 h-10 relative backdrop-blur rounded-full">
          <div className="rounded-full w-full aspect-square animate-spin border-[3px] border-white/30 border-t-white"></div>
          <div className="absolute rounded-full w-full h-full top-0 left-0 flex items-center justify-center">
            <PlayArrowIcon fontSize="medium" />
          </div>
        </div>
      );
    }

    if ((!audio || !audioSrc || !duration) && !lazyLoad) {
      audio && audio.pause();
      return null;
    }

    const elapsedTime = new Date(currentTime * 1000)
      .toISOString()
      .slice(15, 19);
    const _duration = duration === Infinity ? 10000000 : duration;
    const totalTime = new Date((_duration || 0) * 1000)
      .toISOString()
      .slice(15, 19);

    if (type === "circle") {
      return (
        <div>
          <div
            className="flex items-center justify-center text-white w-10 h-10 rounded-full cursor-pointer backdrop-blur"
            onClick={(e) => {
              togglePlayPause(e);
            }}
            role="button"
            tabIndex={0}
            aria-label={`${isPlaying ? "Pause" : "Play"}`}
          >
            {isPlaying ? (
              <PauseIcon fontSize="medium" />
            ) : (
              <PlayArrowIcon fontSize="medium" />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={`w-full ${containerClassName}`}>
        <div className="flex flex-col items-center">
          <div className="flex items-center w-full">
            {timePosition === "top" && (
              <div className="text-10 mr-auto">{elapsedTime}</div>
            )}
            {showControls && controlsPosition === "top" && (
              <div className="mx-auto">
                <Controls
                  {...controlProps}
                  onNextClick={onNextClick}
                  onPrevClick={onPrevClick}
                  onResetClick={restart}
                  isPlaying={isPlaying}
                  handlePlayPause={togglePlayPause}
                />
              </div>
            )}
            {timePosition === "top" && (
              <div className="text-10 ml-auto">{totalTime}</div>
            )}
          </div>
          <div className="flex w-full items-center gap-6">
            {showControls && controlsPosition === "left" && (
              <Controls
                {...controlProps}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onResetClick={restart}
                isPlaying={isPlaying}
                handlePlayPause={togglePlayPause}
              />
            )}
            <div className="flex-grow flex items-center transition-all">
              {timePosition === "left" && (
                <div className="text-10 mr-2">{elapsedTime}</div>
              )}
              <Track audio={audio} pause={pause} play={play} />
              {timePosition === "right" && (
                <div className="text-10 ml-2">{elapsedTime}</div>
              )}
            </div>
            {showControls && controlsPosition === "right" && (
              <Controls
                {...controlProps}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onResetClick={restart}
                isPlaying={isPlaying}
                handlePlayPause={togglePlayPause}
              />
            )}
          </div>
          <div className="flex items-center w-full">
            {timePosition === "bottom" && (
              <div className="text-10 mr-auto">{elapsedTime}</div>
            )}
            {showControls && controlsPosition === "bottom" && (
              <div className="mx-auto">
                <Controls
                  {...controlProps}
                  onNextClick={onNextClick}
                  onPrevClick={onPrevClick}
                  onResetClick={restart}
                  isPlaying={isPlaying}
                  handlePlayPause={togglePlayPause}
                />
              </div>
            )}
            {timePosition === "bottom" && (
              <div className="text-10 ml-auto">{totalTime}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

AudioPlayer.displayName = "AudioPlayer";

export default AudioPlayer;
