export enum Step {
  DISCOUNT = "discount",
  CONFIRM = "confirm",
  PAUSE_SUBSCRIPTION = "pauseSubscription",
  CANCEL_REASON = "cancelReason",
  CONFIRM_PAUSE_SUBSCRIPTION = "confirmPauseSubscription",
  CONTACT_SUPPORT = "contactSupport",
}

export enum Reason {
  NO_LONGER_NEED = "No longer needed",
  TECHNICAL_ISSUES = "Technical issues",
  MISSING_FEATURES = "Missing features",
  BUDGET = "Budget",
  OTHER = "Other",
}

export const CANCELATION_REASONS = [
  { value: Reason.NO_LONGER_NEED, letter: "A" },
  { value: Reason.TECHNICAL_ISSUES, letter: "B" },
  { value: Reason.MISSING_FEATURES, letter: "C" },
  { value: Reason.BUDGET, letter: "D" },
  { value: Reason.OTHER, letter: "E" },
];
