import React from "react";

import MailIcon from "@mui/icons-material/Mail";

import Button, { type IButtonProps } from "@musicfy/components/Button";

type IEmailAuthButtonProps = IButtonProps;

const EmailAuthButton: React.FC<IEmailAuthButtonProps> = ({
  children,
  ...buttonProps
}) => {
  return (
    <Button preIcon={<MailIcon />} {...buttonProps} fullWidth>
      {children || "Continue with Email"}
    </Button>
  );
};

export default EmailAuthButton;
