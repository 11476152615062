import clsx from "clsx";
import Image from "next/image";

import pinkOrb from "@musicfy/assets/images/pink_orb.webp";
import purpleOrb from "@musicfy/assets/images/purple_orb.webp";
import { PADDLE_TARGET_FRAME } from "@musicfy/libs/PaddleProvider";

import FullScreenWrapper from "./FullScreenWrapper";

const PaddleWrapper = ({
  open,
  onClose,
  isLoading,
  children,
}: {
  open: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <FullScreenWrapper open={open} onClose={onClose} isLoading={isLoading}>
      <div className="h-full xl:overflow-y-hidden flex flex-grow xl:items-center w-full relative xl:before:content-[''] xl:before:absolute xl:before:z-0  xl:before:w-1/2 xl:before:h-full">
        <div className="xl:grid xl:grid-cols-2 w-full z-1 h-full items-center">
          <div className="overflow-x-hidden bg-cover bg-no-repeat xl:bg-[url('/paddle_bg.webp')] xl:h-full flex items-center justify-center">
            <div className="w-full h-fit p-6">
              <div className="xl:max-w-2xl z-10 w-full h-fit mx-auto flex justify-center xl:px-16 xl:pb-[100px] lg:p-9 px-6 pb-6 bg-gradient-to-t from-[#191619]/50 to-[#191619]/0 rounded-[18px] border border-white/[20%] border-opacity-20 backdrop-blur-3xl relative">
                <div className="absolute top-[-210px] left-[70px] max-xl:hidden">
                  <Image
                    src={pinkOrb}
                    width={226}
                    height={226}
                    alt="pink orb"
                  />
                </div>
                <div className="z-[1] absolute top-[200px] right-[-135px] max-xl:hidden">
                  <Image
                    src={purpleOrb}
                    width={162}
                    height={162}
                    alt="purple orb"
                  />
                </div>
                {children}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              PADDLE_TARGET_FRAME,
              "z-10 w-full max-h-min xl:h-full mx-auto flex justify-center bg-gray-1000/80 backdrop-blur-md items-center",
              {
                "animate-fade-in": !isLoading,
              }
            )}
          />
        </div>
      </div>
    </FullScreenWrapper>
  );
};

export default PaddleWrapper;
