import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";

import {
  AUDIO_GENERATION_FEATURES,
  AUDIO_GENERATION_HEADERS,
} from "../constants";

const VoiceTrainingFeatures = (): JSX.Element => {
  return (
    <div className="overflow-x-auto mt-8 lg:pb-0 pb-3 lg:bg-transparent overflow-hidden">
      <div className="grid lg:grid-cols-6 grid-cols-5 lg:w-full w-[200vw]">
        <div className="lg:col-span-2 col-span-1 flex flex-col items-start gap-2 sticky -left-1">
          {AUDIO_GENERATION_HEADERS.map((header, i) => {
            return (
              <div
                key={header}
                className={clsx("w-full lg:rounded-l-12 overflow-hidden", {
                  "lg:bg-transparent lg:rounded-[0] lg:text-left text-center rounded-8 bg-pink-200 mb-4":
                    i === 0,
                  "lg:bg-neutral-800/40 bg-neutral-800 lg:border-y lg:border-l lg:border-r-[0] border-r lg:border-white/15 border-white/20":
                    i !== 0,
                })}
              >
                <div
                  className={clsx("lg:w-3/4 w-full p-2 whitespace-nowrap", {
                    "lg:bg-neutral-800/60 px-3 text-white/70": i !== 0,
                    "lg:text-20 font-bold": i === 0,
                  })}
                >
                  {header}
                </div>
              </div>
            );
          })}
        </div>
        {AUDIO_GENERATION_FEATURES.map((feature, i) => {
          const {
            plan,
            voiceCloning,
            textToMusic,
            speed,
            voiceAccess,
            masterQuality,
            removeInstrumentals,
            enhanceVocals,
            color,
          } = feature;
          return (
            <div
              className="flex flex-col lg:items-start lg:text-left items-center text-center gap-2"
              key={plan}
              style={{
                color: color,
              }}
            >
              <div className="capitalize font-bold mb-4 p-2 text-20">
                {plan}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {voiceCloning}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {textToMusic || (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {speed || (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {removeInstrumentals ? (
                  <CheckIcon
                    className="-ml-1 !text-20"
                    style={{
                      color: color,
                    }}
                  />
                ) : (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {voiceAccess ? (
                  <CheckIcon
                    className="-ml-1 !text-20"
                    style={{
                      color: color,
                    }}
                  />
                ) : (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>
              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {enhanceVocals ? (
                  <CheckIcon
                    className="-ml-1 !text-20"
                    style={{
                      color: color,
                    }}
                  />
                ) : (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>

              <div
                className={clsx(
                  "w-full p-2 bg-neutral-800/40 lg:border-y border-white/15 whitespace-nowrap",
                  {
                    "lg:rounded-r-12 lg:border-r":
                      i === AUDIO_GENERATION_FEATURES.length - 1,
                  }
                )}
              >
                {masterQuality ? (
                  <CheckIcon
                    className="-ml-1 !text-20"
                    style={{
                      color: color,
                    }}
                  />
                ) : (
                  <CloseIcon className="-ml-1 !text-20 !text-white/50" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VoiceTrainingFeatures;
