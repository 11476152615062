import React from "react";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import clsx from "clsx";

import Icon from "@musicfy/components/Icon";

export type TPlayButtonStyle = "circle" | "full";

export interface IControlsProps {
  isPlaying: boolean;
  handlePlayPause: (e: React.MouseEvent) => void;
  showNext?: boolean;
  showPrev?: boolean;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onResetClick?: () => void;
  playButtonStyle?: TPlayButtonStyle;
  showRepeatButton?: boolean;
  controlSize?: "small" | "medium" | "large";
}

const Controls = ({
  handlePlayPause,
  isPlaying,
  onNextClick,
  onPrevClick,
  onResetClick,
  playButtonStyle = "full",
  showRepeatButton = false,
  controlSize = "medium",
}: IControlsProps): JSX.Element => {
  return (
    <div className="flex items-center gap-3 group">
      {showRepeatButton && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => onResetClick?.()}
          className="flex items-center justify-center transition-all mr-3 xl:invert-[40%] xl:hover:invert-0"
        >
          <Icon name="restart" className="w-4" />
        </div>
      )}

      {!!onPrevClick && (
        <div role="button" tabIndex={0} onClick={onPrevClick}>
          <Icon
            name="prev-track"
            className={clsx("xl:invert-[40%] xl:hover:invert-0", {
              "w-2": controlSize === "small",
              "w-3": controlSize === "medium",
              "w-4": controlSize === "large",
            })}
          />
        </div>
      )}
      <div
        onClick={handlePlayPause}
        className={clsx(
          "rounded-full cursor-pointer transition-all flex items-center justify-center xl:hover:!text-pink-200",
          {
            "": playButtonStyle === "full",
            "border-2 border-white !text-white xl:hover:border-pink-200":
              playButtonStyle === "circle",
            "w-6 h-6": playButtonStyle === "circle" && controlSize === "small",
            "w-8 h-8": playButtonStyle === "circle" && controlSize === "medium",
            "w-10 h-10":
              playButtonStyle === "circle" && controlSize === "large",
          }
        )}
        role="button"
        tabIndex={0}
        aria-label={`${isPlaying ? "Pause" : "Play"}`}
      >
        {isPlaying ? (
          <PauseIcon
            className={clsx({
              "!text-14":
                controlSize === "small" && playButtonStyle === "circle",
              "!text-16":
                controlSize === "medium" && playButtonStyle === "circle",
              "!text-18":
                controlSize === "large" && playButtonStyle === "circle",
              "!text-24": controlSize === "small" && playButtonStyle === "full",
              "!text-28":
                controlSize === "medium" && playButtonStyle === "full",
              "!text-32": controlSize === "large" && playButtonStyle === "full",
            })}
          />
        ) : (
          <PlayArrowIcon
            className={clsx({
              "!text-14":
                controlSize === "small" && playButtonStyle === "circle",
              "!text-16":
                controlSize === "medium" && playButtonStyle === "circle",
              "!text-18":
                controlSize === "large" && playButtonStyle === "circle",
              "!text-24": controlSize === "small" && playButtonStyle === "full",
              "!text-28":
                controlSize === "medium" && playButtonStyle === "full",
              "!text-32": controlSize === "large" && playButtonStyle === "full",
            })}
          />
        )}
      </div>
      {!!onNextClick && (
        <div role="button" tabIndex={0} onClick={onNextClick}>
          <Icon
            name="next-track"
            className={clsx("xl:invert-[40%] xl:hover:invert-0", {
              "w-2": controlSize === "small",
              "w-3": controlSize === "medium",
              "w-4": controlSize === "large",
            })}
          />
        </div>
      )}
    </div>
  );
};

export default Controls;
