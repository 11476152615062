import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import clsx from "clsx";

import { usePaddleUpdatePayment } from "@musicfy/libs/PaddleProvider/hooks";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

const FailedPaymentBanner = ({
  bannerClassName = "",
}: {
  bannerClassName?: string;
}): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { subscription } = useSubscriptionContext();

  const { startUpdatePayment } = usePaddleUpdatePayment();

  if (
    !subscription ||
    subscription.plan === "free" ||
    subscription.status !== "past_due" ||
    !isOpen
  ) {
    return null;
  }

  return (
    <div
      className={clsx(
        "z-[1000] fixed bottom-0 left-0 right-0 mx-auto mb-4 w-full max-w-screen-xl px-8 py-6 rounded-8 bg-gray-1000/70 backdrop-blur-lg border border-solid border-red",
        bannerClassName
      )}
    >
      <div className="text-red flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <ErrorIcon />
          <div className="font-bold">Payment Past Due</div>
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(false)}
          className="flex h-8 p-2 w-8 cursor-pointer items-center justify-center rounded-full border border-white/15 xl:hover:border-white/30 bg-white/5 transition-all xl:hover:bg-white/20"
        >
          <CloseIcon className="!text-22 !text-white !h-full !w-full" />
        </div>
      </div>
      <div className="text-gray-200 mt-2">
        Please{" "}
        <span
          role="button"
          tabIndex={0}
          className="font-bold underline text-white opacity-90 cursor-pointer"
          onClick={startUpdatePayment}
        >
          update your payment method
        </span>{" "}
        to resume your Musicfy subscription.
      </div>
    </div>
  );
};

export default FailedPaymentBanner;
