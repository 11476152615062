import React, { useCallback } from "react";

import { SubscriptionPlans, SubscriptionIntervals } from "@prisma/client";

import Button from "@musicfy/components/Button";
import {
  getSubscriptionValue,
  type TPriceId,
  getSubscriptionPriceDetails,
  SubscriptionPlanInfo,
} from "@musicfy/contants/Subscriptions";
import { usePaddleCheckout } from "@musicfy/libs/PaddleProvider/hooks";
import { usePaddleUpgrade } from "@musicfy/libs/PaddleProvider/hooks/usePaddleUpgrade";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

const BaseUpdateSubscriptionButton: React.FC<{
  priceId: TPriceId;
  onClick: () => void;
  isLoading: boolean;
}> = ({ priceId, onClick, isLoading }) => {
  const { subscription } = useSubscriptionContext();

  const { plan: pricePlan, interval: priceInterval } =
    getSubscriptionPriceDetails({ priceId });

  const currentPlan = subscription?.plan || SubscriptionPlans.free;
  const currentInterval = subscription?.interval || SubscriptionIntervals.month;

  const priceSubscriptionValue = getSubscriptionValue(pricePlan);
  const activeSubscriptionValue = getSubscriptionValue(currentPlan);

  const isCurrentPlan = pricePlan === currentPlan;
  const isCurrentInterval = priceInterval === currentInterval;

  if (isCurrentPlan && isCurrentInterval) {
    return (
      <div className="w-full">
        <Button
          style={pricePlan === SubscriptionPlans.pro ? "primary" : "tertiary"}
          disabled
          fullWidth
          className="text-20 !py-5"
        >
          Current
        </Button>
      </div>
    );
  }

  if (isCurrentPlan) {
    return (
      <div className="w-full">
        <Button
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          onClick={onClick}
          style={pricePlan === SubscriptionPlans.pro ? "primary" : "tertiary"}
          className="text-20 !py-5"
        >
          Switch to{" "}
          {priceInterval === SubscriptionIntervals.month ? "Monthly" : "Yearly"}
        </Button>
      </div>
    );
  }

  if (priceSubscriptionValue < activeSubscriptionValue) {
    return (
      <div className="w-full">
        <Button
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          onClick={onClick}
          style={pricePlan === SubscriptionPlans.pro ? "primary" : "tertiary"}
          className="text-20 !py-5"
        >
          Downgrade
        </Button>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Button
        loading={isLoading}
        disabled={isLoading}
        fullWidth
        onClick={onClick}
        style={pricePlan === SubscriptionPlans.pro ? "primary" : "tertiary"}
        className="text-20 !py-5"
      >
        Choose {SubscriptionPlanInfo[pricePlan ?? SubscriptionPlans.free].label}
      </Button>
    </div>
  );
};

const StartCheckoutButton: React.FC<{
  priceId: TPriceId;
  discount?: { code?: string; id?: string };
}> = ({ priceId, discount }) => {
  const { subscription } = useSubscriptionContext();

  const { startCheckout } = usePaddleCheckout();
  const { startUpgrade } = usePaddleUpgrade();

  const updateSubscription = useCallback(() => {
    if (!subscription || subscription.plan === SubscriptionPlans.free) {
      startCheckout(priceId, discount);
    } else {
      startUpgrade(priceId);
    }
  }, [discount, priceId, startCheckout, startUpgrade, subscription]);

  return (
    <>
      <BaseUpdateSubscriptionButton
        priceId={priceId}
        onClick={updateSubscription}
        isLoading={false}
      />
    </>
  );
};

export default StartCheckoutButton;
