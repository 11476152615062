import React, { createContext, useContext, useMemo, useState } from "react";

import { type ApiSubscription } from "@prisma/client";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import {
  APP_ROUTES,
  FEATURE_ROUTES,
} from "@musicfy/components/Navigation/constants";
import { api } from "@musicfy/utils";
import { useArray } from "@musicfy/utils/hooks";
import { type IUseArrayNullableReturnType } from "@musicfy/utils/hooks/useArray";

import {
  type TApiUsage,
  type TApiKey,
  type TCurrentBillingCycle,
  type TApiInvoice,
} from "./IApiAccess";

interface IApiAccessContext {
  apiKeys: IUseArrayNullableReturnType<TApiKey>["value"];
  setApiKeys: IUseArrayNullableReturnType<TApiKey>["setValue"];
  apiUsage: TApiUsage[];
  currentBillingCycle: TCurrentBillingCycle | undefined;
  apiInvoices: IUseArrayNullableReturnType<TApiInvoice>["value"];
  isLoading: boolean;
  hasApiAccess: boolean;
  apiSubscription: ApiSubscription | undefined;
}

const ApiAccessContext = createContext<IApiAccessContext | null>(null);

export function useApiAccessContext() {
  const apiAccessContext = useContext(ApiAccessContext);

  if (!apiAccessContext) {
    throw new Error(
      "useApiAccessContext must be used within a ApiAccessProvider"
    );
  }

  return apiAccessContext;
}

const ApiAccessProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status } = useSession();
  const router = useRouter();

  const isOnApiAccessPage = router.asPath.includes(APP_ROUTES.api);
  const isOnBillingPage = router.asPath.includes(FEATURE_ROUTES["api-billing"]);
  const isAuthenticated = status === "authenticated";

  const [apiSubscription, setApiSubscription] = useState<ApiSubscription>();
  const { value: apiKeys, setValue: setApiKeys } = useArray<TApiKey>();
  const { value: apiInvoices, setValue: setApiInvoices } =
    useArray<TApiInvoice>();
  const { value: apiUsage, setValue: setApiUsage } = useArray<TApiUsage>([]);

  const [currentBillingCycle, setCurrentBillingCycle] =
    useState<TCurrentBillingCycle>();

  const [hasApiAccess, setHasApiAccess] = useState(false);

  const { isFetching: isInvoicesLoading } =
    api.apiAccess.getApiInvoices.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setApiInvoices(data);
      },
      enabled:
        isAuthenticated &&
        isOnApiAccessPage &&
        !apiInvoices &&
        hasApiAccess &&
        isOnBillingPage,
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    });

  const { isFetching: isApiSubscriptionLoading } =
    api.apiAccess.getApiSubscription.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          setHasApiAccess(false);
          return;
        }

        setHasApiAccess(true);
        setApiSubscription(data);
      },
      onError: () => {
        setHasApiAccess(false);
      },
      enabled: isAuthenticated && isOnApiAccessPage && !hasApiAccess,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });

  const { isFetching: isApiKeysLoading } = api.apiAccess.getApiKeys.useQuery(
    undefined,
    {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setApiKeys(data);
      },
      retry: false,
      enabled: isAuthenticated && !apiKeys && isOnApiAccessPage && hasApiAccess,
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );

  const { isFetching: isApiUsageLoading } = api.apiAccess.getApiUsage.useQuery(
    undefined,
    {
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        const filteredUsage = data.map((usage) => {
          const timestamp = usage.date;
          const localTimestamp = new Date(timestamp).toLocaleDateString();
          return { ...usage, date: localTimestamp };
        });
        setApiUsage(filteredUsage);
      },
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled:
        isAuthenticated &&
        isOnApiAccessPage &&
        hasApiAccess &&
        apiUsage.length === 0,
    }
  );

  const { isFetching: isCurrentBillingCycleLoading } =
    api.apiAccess.getCurrentBillingCycle.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setCurrentBillingCycle(data);
      },
      refetchInterval: 60 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled:
        isAuthenticated &&
        isOnApiAccessPage &&
        hasApiAccess &&
        !currentBillingCycle,
    });

  const isLoading = useMemo(() => {
    return (
      isApiKeysLoading ||
      isApiSubscriptionLoading ||
      isApiUsageLoading ||
      isCurrentBillingCycleLoading ||
      isInvoicesLoading
    );
  }, [
    isApiKeysLoading,
    isApiSubscriptionLoading,
    isApiUsageLoading,
    isCurrentBillingCycleLoading,
    isInvoicesLoading,
  ]);

  const apiAccessContextValue: IApiAccessContext = {
    apiKeys: apiKeys,
    setApiKeys: setApiKeys,
    isLoading: isLoading,
    hasApiAccess: hasApiAccess,
    currentBillingCycle: currentBillingCycle,
    apiInvoices: apiInvoices,
    apiUsage: apiUsage,
    apiSubscription: apiSubscription,
  };

  return (
    <ApiAccessContext.Provider value={apiAccessContextValue}>
      {children}
    </ApiAccessContext.Provider>
  );
};

export default ApiAccessProvider;
