import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { SubscriptionPlans } from "@prisma/client";
import clsx from "clsx";
import { Sling as Hamburger } from "hamburger-react";
import Link from "next/link";
import { useRouter } from "next/router";

import { roobert } from "@musicfy/assets/fonts";
import {
  APPS,
  type IAppInfo,
  findActiveSection,
  type IFeatureInfo,
  APP_ROUTES,
  EApps,
} from "@musicfy/components/Navigation/constants";
import UserAccount from "@musicfy/components/Navigation/UserAccount";
import StartCheckoutFlowButton from "@musicfy/components/PaymentButtons/StartCheckoutFlowButton";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { useIsAdmin } from "@musicfy/utils/hooks";

import Icon from "../../Icon";

const NavigationItem: React.FC<{
  isActiveApp: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  activeSection: IFeatureInfo | undefined;
  isNavOpen: boolean;
  appInfo: IAppInfo;
}> = ({ isActiveApp, isNavOpen, appInfo, activeSection, setIsNavOpen }) => {
  const [isSubnavOpen, setIsSubnavOpen] = useState(isActiveApp);

  const {
    icon,
    displayName,
    key,
    features: sections,
    isPremium: isAppPremium,
  } = appInfo;
  const appRoute = appInfo.mainRoute || "/";

  return (
    <div className="border-b border-white/20 py-6">
      <div className="flex items-center justify-between">
        <Link key={key} href={appRoute}>
          <div
            className="flex items-center gap-4 group"
            onClick={() => setIsNavOpen(false)}
          >
            <div
              className={clsx(
                "group flex h-fit cursor-pointer items-center gap-4 font-bold"
              )}
            >
              <div
                className={clsx(
                  "h-8 w-8 rounded-full relative bg-violet flex items-center justify-center",
                  {
                    "bg-opacity-100": isActiveApp,
                    "bg-opacity-0": !isActiveApp,
                    "ml-1": !isNavOpen,
                  }
                )}
              >
                <Icon name={icon} />
              </div>
            </div>

            <div
              className={clsx(
                "whitespace-nowrap font-bold text-16 flex items-center gap-2",
                {
                  "text-white": isActiveApp,
                  "text-gray-500": !isActiveApp,
                }
              )}
            >
              <div>{displayName}</div>
              {isAppPremium && <Icon name="crown" className="w-4 h-auto" />}
            </div>
          </div>
        </Link>
        {!!sections.length && (
          <IconButton
            aria-label={`${isSubnavOpen ? "Collapse" : "Expand"} ${
              appInfo.displayName
            } Section`}
            onClick={() => setIsSubnavOpen((prev) => !prev)}
          >
            {isSubnavOpen ? (
              <RemoveIcon className="text-white" />
            ) : (
              <AddIcon className="text-gray-700" />
            )}
          </IconButton>
        )}
      </div>
      <div
        className="translate-x-12 overflow-hidden transition-all"
        style={{
          height: isSubnavOpen ? sections.length * 36 : 0,
        }}
      >
        {sections.map((section) => {
          const { displayName, key } = section;
          const sectionRoute = section.route || "/";
          const isActiveSection = key === activeSection?.key;
          const isFeaturePremium = section.isPremium;

          return (
            <Link key={displayName} href={sectionRoute}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={clsx(
                  "my-4 h-5 cursor-pointer text-14 flex items-center gap-2",
                  {
                    "text-gray-500": !isActiveSection,
                  }
                )}
              >
                <div>{displayName}</div>
                {isFeaturePremium && (
                  <Icon
                    name="crown"
                    className={clsx("w-4 h-auto grayscale transition-opacity", {
                      "opacity-0": !isSubnavOpen,
                      "opacity-100": isSubnavOpen,
                    })}
                  />
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const MainNavigation: React.FC<{
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  activeApp: IAppInfo | undefined;
  activeSection: IFeatureInfo | undefined;
}> = ({ isNavOpen, setIsNavOpen, activeApp, activeSection }) => {
  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();

  const showUpgradeButton =
    (!subscription || subscription.plan === SubscriptionPlans.free) &&
    !isSubscriptionLoading;

  const isAdmin = useIsAdmin();

  const [isAtTopOfPage, setIsAtTopOfPage] = useState(true);

  useEffect(() => {
    function scrollHandler() {
      if (window.scrollY >= 40 && isAtTopOfPage) {
        setIsAtTopOfPage(false);
      }

      if (window.scrollY < 40 && !isAtTopOfPage) {
        setIsAtTopOfPage(true);
      }
    }

    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, [isAtTopOfPage]);

  return (
    <>
      <div className="fixed left-0 top-0 z-10 w-screen px-4 py-6">
        <div
          className={clsx(
            "fixed pointer-events-none w-screen h-[20vh] left-0 top-0 z-0 bg-gradient-to-b from-gray-1000/60 to-gray-1000/0 transition-all duration-500",
            {
              "opacity-0": isAtTopOfPage,
              "opacity-100": !isAtTopOfPage,
            }
          )}
        />
        <div className="relative flex items-center">
          <nav
            onClick={() => setIsNavOpen(!isNavOpen)}
            role="button"
            tabIndex={0}
            aria-label="navigation"
            className="absolute border border-white/15 w-10 h-10 bg-violet bg-opacity-50 backdrop-blur rounded-full flex items-center justify-center"
          >
            <div>
              <Hamburger size={18} toggled={isNavOpen} />
            </div>
          </nav>
          <div
            className={clsx("m-auto text-22 font-bold duration-300", {
              "opacity-0 -translate-y-4": !isAtTopOfPage,
              "opacity-100 translate-y-0": isAtTopOfPage,
            })}
          >
            {activeApp?.displayName ?? "Explore"}
          </div>

          {showUpgradeButton && (
            <div className="absolute right-0">
              <StartCheckoutFlowButton
                className="!py-[6px] !px-[10px]"
                origin="mobile-nav"
              />
            </div>
          )}
        </div>
      </div>

      <Drawer
        className={`${roobert.variable} font-sans`}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
          },
        }}
        open={isNavOpen}
        onClose={() => setIsNavOpen(false)}
      >
        <nav className="flex h-full w-[70vw] flex-col overflow-x-hidden py-4 px-3 text-white lg:w-[60vw] xl:hidden bg-gradient-to-t from-teal/70 to-teal/20 backdrop-blur-2xl">
          <div className="left-0 top-0 flex py-4 w-full items-center justify-between border-b border-solid border-white/20">
            <div role="button" tabIndex={0} onClick={() => setIsNavOpen(false)}>
              <Link href={APP_ROUTES[EApps.DASHBOARD]}>
                <div className="flex items-center gap-1">
                  <Icon name="logo" className="w-6 h-6" />
                  <Icon name="logo-text" className="ml-3" />
                </div>
              </Link>
            </div>
            <div>
              <UserAccount googleButtonClassName="w-fit h-fit" />
            </div>
          </div>

          <div className="flex flex-grow flex-col">
            {APPS.map((appInfo) => {
              const { key, isAdminProtected } = appInfo;
              if (isAdminProtected && !isAdmin) {
                return null;
              }

              const isActiveApp = activeApp?.key === key;

              return (
                <NavigationItem
                  key={key}
                  isActiveApp={isActiveApp}
                  setIsNavOpen={setIsNavOpen}
                  activeSection={activeSection}
                  isNavOpen={isNavOpen}
                  appInfo={appInfo}
                />
              );
            })}
          </div>
        </nav>
      </Drawer>
    </>
  );
};

const MobileNavigation: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const router = useRouter();

  const currentAppRoute = router.pathname.split("/")[1];
  const activeApp = !currentAppRoute
    ? undefined
    : APPS.find(
        ({ route }) =>
          currentAppRoute === route.split("/")[1] && !route.includes("http")
      ) ?? (APPS[0] as IAppInfo);

  const activeSection = !activeApp
    ? undefined
    : findActiveSection(router.asPath, activeApp.features);

  return (
    <div className="relative z-[1000]">
      <MainNavigation
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
        activeApp={activeApp}
        activeSection={activeSection}
      />
    </div>
  );
};

export default MobileNavigation;
