import AudioGenerationFeatures from "./AudioGenerationFeatures";
import VoiceTrainingFeatures from "./VoiceTrainingFeatures";

const FeaturesBreakdown = (): JSX.Element => {
  return (
    <>
      <div className="text-38 lg:text-left text-center font-semibold">
        Features
      </div>
      <VoiceTrainingFeatures />
      <div className="py-2" />
      <AudioGenerationFeatures />
    </>
  );
};

export default FeaturesBreakdown;
