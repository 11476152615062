import Divider from "@musicfy/components/Divider";
import Icon from "@musicfy/components/Icon";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { formatPrice } from "@musicfy/utils";
import { useIsTablet } from "@musicfy/utils/hooks";

import CheckoutItem from "./shared/CheckoutItem";
import PaddleWrapper from "./shared/PaddleWrapper";
import { usePaddleUpdatePayment } from "../hooks";

const SubscriptionPreview = (): JSX.Element | null => {
  const {
    updatePaymentContext: { updatePaymentData },
  } = usePaddleContext();

  const isTablet = useIsTablet();

  if (!updatePaymentData) {
    return null;
  }

  const { totals, items, currency_code: currencyCode } = updatePaymentData;
  const formattedTotalPrice = formatPrice(totals.total, currencyCode);
  const formattedSubtotal = formatPrice(totals.subtotal, currencyCode);
  const formattedTax = formatPrice(totals.tax, currencyCode);

  return (
    <div className="w-full">
      <div className="flex gap-2 items-center justify-center max-xxl:pt-6 mb-5">
        <Icon
          name={isTablet ? "logo" : "logo-white"}
          className="text-white fill-current w-5 h-5"
        />
        <Icon name="logo-text" />
      </div>
      <div>
        <div className="heading font-bold">Update Your Payment</div>
      </div>
      <div className="mt-12">
        {items.map((item) => {
          return (
            <CheckoutItem
              key={item.price_id}
              currencyCode={updatePaymentData.currency_code}
              item={item}
            />
          );
        })}
      </div>
      <div className="mt-6 flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="font-light text-gray-400">Subtotal</div>
          <div className="font-bold">{formattedSubtotal}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="font-light text-gray-400">Tax</div>
          <div className="font-bold">{formattedTax}</div>
        </div>

        <Divider />
        <div className="flex items-center justify-between">
          <div className="font-light text-gray-400">Due Today</div>
          <div className="font-bold">{formattedTotalPrice}</div>
        </div>
      </div>
    </div>
  );
};

const UpdatePayment = (): JSX.Element => {
  const {
    updatePaymentContext: { updatePaymentData },
  } = usePaddleContext();

  const { closeUpdatePayment } = usePaddleUpdatePayment();

  return (
    <PaddleWrapper
      open
      onClose={closeUpdatePayment}
      isLoading={!updatePaymentData}
    >
      <SubscriptionPreview />
    </PaddleWrapper>
  );
};

export default UpdatePayment;
