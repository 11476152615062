import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { FFmpeg } from "@ffmpeg/ffmpeg";

interface IFFmpegContext {
  ffmpeg: FFmpeg | undefined;
  fetchFile: (input: string | Blob | File | undefined) => Promise<Uint8Array>;
  load: () => Promise<void>;
}

const FFmpegContext = createContext<IFFmpegContext | null>(null);

export function useFFmpegContext() {
  const ffmpegContext = useContext(FFmpegContext);

  if (!ffmpegContext) {
    throw new Error("useFFmpegContext must be used within a FFmpegProvider");
  }

  return ffmpegContext;
}

const FFmpegProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const ffmpegRef = useRef<FFmpeg>();
  const [isLoaded, setIsLoaded] = useState(false);

  const load = useCallback(async () => {
    if (isLoaded) return;
    const { toBlobURL } = await import("@ffmpeg/util");
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    ffmpegRef.current = new FFmpeg();
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });
    setIsLoaded(true);
  }, [isLoaded]);

  const fetchFileCallback = useCallback(
    async (input: string | Blob | File | undefined) => {
      const { fetchFile } = await import("@ffmpeg/util");
      return await fetchFile(input);
    },
    []
  );

  useEffect(() => {
    load();
  }, [load]);

  const ffmpegContextValue = {
    ffmpeg: ffmpegRef.current,
    fetchFile: fetchFileCallback,
    load: load,
  };

  return (
    <FFmpegContext.Provider value={ffmpegContextValue}>
      {children}
    </FFmpegContext.Provider>
  );
};

export default FFmpegProvider;
