/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Image from "next/image";
import Link from "next/link";

import addMusicActive from "@musicfy/assets/icons/add-music-active.webp";
import addMusicInactive from "@musicfy/assets/icons/add-music-inactive.webp";
import api from "@musicfy/assets/icons/api.svg";
import arrowChevronDown from "@musicfy/assets/icons/arrow_chevron_down.webp";
import arrowGreen from "@musicfy/assets/icons/arrow_green.webp";
import arrowRed from "@musicfy/assets/icons/arrow_red.webp";
import play from "@musicfy/assets/icons/audioplayer_playicon.webp";
import bookmarkFilled from "@musicfy/assets/icons/bookmark_filled.svg";
import bookmarkUnfilled from "@musicfy/assets/icons/bookmark_unfilled.svg";
import check from "@musicfy/assets/icons/check.webp";
import checkCircle from "@musicfy/assets/icons/check_circle.svg";
import cloud from "@musicfy/assets/icons/cloud.svg";
import crown from "@musicfy/assets/icons/crown.svg";
import cube from "@musicfy/assets/icons/cube.svg";
import download from "@musicfy/assets/icons/download.svg";
import drumActive from "@musicfy/assets/icons/drum-active.webp";
import drumInactive from "@musicfy/assets/icons/drum-inactive.webp";
import enhance from "@musicfy/assets/icons/enhance.svg";
import enhanceWhite from "@musicfy/assets/icons/enhance_white.svg";
import expand from "@musicfy/assets/icons/expand.svg";
import explore from "@musicfy/assets/icons/explore.svg";
import eyeClosed from "@musicfy/assets/icons/eye_close.webp";
import eyeOpened from "@musicfy/assets/icons/eye_open.webp";
import uploadArrow from "@musicfy/assets/icons/file_upload_arrow.svg";
import uploadPlus from "@musicfy/assets/icons/file_upload_plus.webp";
import uploadSpinner from "@musicfy/assets/icons/file_uploading_spinner.webp";
import google from "@musicfy/assets/icons/google.svg";
import guide from "@musicfy/assets/icons/guide.svg";
import guitarActive from "@musicfy/assets/icons/guitar-active.webp";
import guitarInactive from "@musicfy/assets/icons/guitar-inactive.webp";
import help from "@musicfy/assets/icons/help.svg";
import information from "@musicfy/assets/icons/information.svg";
import logoStamp from "@musicfy/assets/icons/logo-stamp.svg";
import logoText from "@musicfy/assets/icons/logo-text.svg";
import logoWhite from "@musicfy/assets/icons/logo-white.svg";
import logo from "@musicfy/assets/icons/logo.svg";
import mail from "@musicfy/assets/icons/mail.svg";
import manage from "@musicfy/assets/icons/manage.svg";
import music from "@musicfy/assets/icons/music.svg";
import musicMenu from "@musicfy/assets/icons/music_menu.svg";
import musicNote from "@musicfy/assets/icons/music_note.svg";
import newIcon from "@musicfy/assets/icons/new.svg";
import nextTrack from "@musicfy/assets/icons/next_track.svg";
import percent from "@musicfy/assets/icons/percent.webp";
import pianoActive from "@musicfy/assets/icons/piano-active.webp";
import pianoInactive from "@musicfy/assets/icons/piano-inactive.webp";
import prevTrack from "@musicfy/assets/icons/prev_track.svg";
import pricingBolt from "@musicfy/assets/icons/pricing_bolt.webp";
import pricingCrown from "@musicfy/assets/icons/pricing_crown.webp";
import pricingStar from "@musicfy/assets/icons/pricing_star.webp";
import pricingTrophy from "@musicfy/assets/icons/pricing_trophy.webp";
import restart from "@musicfy/assets/icons/restart.svg";
import rhythm from "@musicfy/assets/icons/rhythm.svg";
import rocket from "@musicfy/assets/icons/rocket.svg";
import rotation from "@musicfy/assets/icons/rotation.svg";
import search from "@musicfy/assets/icons/search.svg";
import separate from "@musicfy/assets/icons/separate.svg";
import separateWhite from "@musicfy/assets/icons/separate_white.svg";
import sound from "@musicfy/assets/icons/sound.svg";
import trainingConfirm from "@musicfy/assets/icons/training-confirm.svg";
import trainingInfo from "@musicfy/assets/icons/training-info.svg";
import trainingPreview from "@musicfy/assets/icons/training-preview.svg";
import trainingUpload from "@musicfy/assets/icons/training-upload.svg";
import trash from "@musicfy/assets/icons/trash.svg";
import verticalMenu from "@musicfy/assets/icons/vertical_menu.svg";
import vocalSquareActive from "@musicfy/assets/icons/vocal-square-active.webp";
import vocalSquareInactive from "@musicfy/assets/icons/vocal-square-inactive.webp";
import vocalsActive from "@musicfy/assets/icons/vocals-active.webp";
import vocalsInactive from "@musicfy/assets/icons/vocals-inactive.webp";
import volume from "@musicfy/assets/icons/volume.webp";
import volumeMuted from "@musicfy/assets/icons/volume_muted.webp";

export enum Icons {
  check = "check",
  checkCircle = "check-circle",
  crown = "crown",
  download = "download",
  enhance = "enhance",
  enhanceWhite = "enhance-white",
  expand = "expand",
  explore = "explore",
  google = "google",
  guide = "guide",
  help = "help",
  information = "information",
  logo = "logo",
  logoStamp = "logo-stamp",
  logoText = "logo-text",
  logoWhite = "logo-white",
  mail = "mail",
  manage = "manage",
  musicMenu = "music-menu",
  musicNote = "music-note",
  new = "new",
  nextTrack = "next-track",
  play = "play",
  prevTrack = "prev-track",
  trash = "trash",
  uploadArrow = "upload-arrow",
  uploadPlus = "upload-plus",
  uploadSpinner = "upload-spinner",
  percent = "percent",
  pricingStar = "pricing-star",
  pricingBolt = "pricing-bolt",
  pricingCrown = "pricing-crown",
  pricingTrophy = "pricing-trophy",
  restart = "restart",
  rhythm = "rhythm",
  rocket = "rocket",
  rotation = "rotation",
  separate = "separate",
  separateWhite = "separate-white",
  sound = "sound",
  trainingConfirm = "training-confirm",
  trainingInfo = "training-info",
  trainingPreview = "training-preview",
  trainingUpload = "training-upload",
  cloud = "cloud",
  cube = "cube",
  music = "music",
  search = "search",
  bookmarkFilled = "bookmark-filled",
  bookmarkUnfilled = "bookmark-unfilled",
  verticalMenu = "vertical-menu",
  volume = "volume",
  volumeMuted = "volume-muted",
  guitarActive = "guitar-active",
  guitarInactive = "guitar-inactive",
  pianoActive = "piano-active",
  pianoInactive = "piano-inactive",
  drumActive = "drum-active",
  drumInactive = "drum-inactive",
  vocalActive = "vocal-active",
  vocalInactive = "vocal-inactive",
  vocalsSquareActive = "vocals-square-active",
  vocalsSquareInactive = "vocals-square-inactive",
  addMusicActive = "add-music-active",
  addMusicInactive = "add-music-inactive",
  eyeOpened = "eye-opened",
  eyeClosed = "eye-closed",
  arrowRed = "arrow-red",
  arrowGreen = "arrow-green",
  arrowChevronDown = "arrow-chevron-down",
  api = "api",
}

export type IIconName = `${Icons}`;

export const ICON_MAP = {
  [Icons.checkCircle]: checkCircle,
  [Icons.crown]: crown,
  [Icons.download]: download,
  [Icons.enhance]: enhance,
  [Icons.enhanceWhite]: enhanceWhite,
  [Icons.expand]: expand,
  [Icons.explore]: explore,
  [Icons.google]: google,
  [Icons.guide]: guide,
  [Icons.help]: help,
  [Icons.information]: information,
  [Icons.logo]: logo,
  [Icons.logoStamp]: logoStamp,
  [Icons.logoText]: logoText,
  [Icons.logoWhite]: logoWhite,
  [Icons.mail]: mail,
  [Icons.manage]: manage,
  [Icons.musicMenu]: musicMenu,
  [Icons.musicNote]: musicNote,
  [Icons.new]: newIcon,
  [Icons.nextTrack]: nextTrack,
  [Icons.play]: play,
  [Icons.prevTrack]: prevTrack,
  [Icons.trash]: trash,
  [Icons.uploadArrow]: uploadArrow,
  [Icons.uploadPlus]: uploadPlus,
  [Icons.uploadSpinner]: uploadSpinner,
  [Icons.percent]: percent,
  [Icons.pricingStar]: pricingStar,
  [Icons.pricingBolt]: pricingBolt,
  [Icons.pricingCrown]: pricingCrown,
  [Icons.pricingTrophy]: pricingTrophy,
  [Icons.check]: check,
  [Icons.restart]: restart,
  [Icons.rhythm]: rhythm,
  [Icons.rocket]: rocket,
  [Icons.rotation]: rotation,
  [Icons.separate]: separate,
  [Icons.separateWhite]: separateWhite,
  [Icons.sound]: sound,
  [Icons.trainingConfirm]: trainingConfirm,
  [Icons.trainingInfo]: trainingInfo,
  [Icons.trainingPreview]: trainingPreview,
  [Icons.trainingUpload]: trainingUpload,
  [Icons.cloud]: cloud,
  [Icons.cube]: cube,
  [Icons.music]: music,
  [Icons.search]: search,
  [Icons.bookmarkFilled]: bookmarkFilled,
  [Icons.bookmarkUnfilled]: bookmarkUnfilled,
  [Icons.verticalMenu]: verticalMenu,
  [Icons.volume]: volume,
  [Icons.volumeMuted]: volumeMuted,
  [Icons.guitarActive]: guitarActive,
  [Icons.guitarInactive]: guitarInactive,
  [Icons.pianoActive]: pianoActive,
  [Icons.pianoInactive]: pianoInactive,
  [Icons.drumActive]: drumActive,
  [Icons.drumInactive]: drumInactive,
  [Icons.vocalActive]: vocalsActive,
  [Icons.vocalInactive]: vocalsInactive,
  [Icons.vocalsSquareActive]: vocalSquareActive,
  [Icons.vocalsSquareInactive]: vocalSquareInactive,
  [Icons.addMusicActive]: addMusicActive,
  [Icons.addMusicInactive]: addMusicInactive,
  [Icons.eyeOpened]: eyeOpened,
  [Icons.eyeClosed]: eyeClosed,
  [Icons.arrowRed]: arrowRed,
  [Icons.arrowGreen]: arrowGreen,
  [Icons.arrowChevronDown]: arrowChevronDown,
  [Icons.api]: api,
};

interface IIconProps extends React.SVGProps<SVGSVGElement> {
  name: IIconName;
  href?: string;
  className?: string;
}

const Icon: React.FC<IIconProps> = ({ name, href, className = "" }) => {
  const Icon = ICON_MAP[name];

  if (!!href) {
    return (
      <Link href={href}>
        <Image src={Icon} alt="" className={className} />
      </Link>
    );
  }

  return <Image src={Icon} alt="" className={className} />;
};

export default Icon;
