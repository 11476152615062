export function getLocale(): string {
  return navigator.language;
}

export function getLocaleLanguage(): string {
  const locale = getLocale();
  let language = "en";

  if (locale.includes("-")) {
    language = locale.split("-").shift() || language;
  }

  return language;
}

export function getLocaleCountryCode(): string {
  const locale = getLocale();
  let countryCode = "US";

  if (locale.includes("-")) {
    countryCode = locale.split("-").pop() || countryCode;
  }

  return countryCode;
}

export function getLocaleCountry(): string | undefined {
  const language = getLocaleLanguage();
  const regions = new Intl.DisplayNames(["en"], { type: "region" });
  const country = regions.of(language);
  return country;
}
