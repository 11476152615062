import MUISlider from "@mui/material/Slider";

type ISliderProps = React.ComponentProps<typeof MUISlider> & {
  color?: "primary" | "secondary";
};

const Slider = ({ color, ...sliderProps }: ISliderProps): JSX.Element => {
  return (
    <MUISlider
      {...sliderProps}
      slotProps={{
        thumb: {
          className: "text-white",
        },
        track: {
          className:
            !color || color === "primary"
              ? "!bg-gradient-to-r !from-pink-200 !to-pink-300 !h-1"
              : "!bg-white",
        },
        rail: {
          className: "!bg-gray-700 !h-[2px]",
        },
      }}
      sx={{
        "& .MuiSlider-thumb:hover": {
          boxShadow: "0 0 0 8px #FE7ED91A !important",
        },
      }}
    />
  );
};

export default Slider;
