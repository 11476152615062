/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useContext,
  type ReactNode,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";

import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { NON_AUTH_REQUIRED_ROUTES } from "@musicfy/components/Navigation/constants";

import RequireAuthTakeover from "./RequireAuthTakeover";

interface IRequireAuthProviderProps {
  children: ReactNode;
}

interface IRequireAuthContext {
  showAuthModal: () => void;
  hideAuthModal: () => void;
}

const RequireAuthContext = createContext<IRequireAuthContext>({
  showAuthModal: () => {},
  hideAuthModal: () => {},
});

export function useRequireAuthContext() {
  const requireAuthContext = useContext(RequireAuthContext);

  if (!requireAuthContext) {
    throw new Error(
      "useRequireAuthContext must be used within a RequireAuthProvider"
    );
  }

  return requireAuthContext;
}

export default function RequireAuthProvider({
  children,
}: IRequireAuthProviderProps) {
  const { status } = useSession();
  const router = useRouter();

  const shouldShowAuthTakeover = useMemo(() => {
    return () => {
      const isAuthTakeoverRequired =
        !NON_AUTH_REQUIRED_ROUTES.includes(router.pathname) &&
        router.pathname !== "/create/[...voice]" &&
        router.pathname !== "/pro-tools/stem-splitter" &&
        router.pathname !== "/api-access/usage" &&
        status === "unauthenticated";

      return isAuthTakeoverRequired;
    };
  }, [router.pathname, status]);

  const [showRequireAuthTakeover, setShowRequireAuthTakeover] = useState(
    shouldShowAuthTakeover()
  );

  useEffect(() => {
    const isAuthTakeoverRequired = shouldShowAuthTakeover();
    setShowRequireAuthTakeover(isAuthTakeoverRequired);
  }, [shouldShowAuthTakeover]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("callbackPath") && showRequireAuthTakeover) {
      router.replace({
        query: { ...router.query, callbackPath: router.asPath },
      });
    }
  }, [router, showRequireAuthTakeover]);

  const showAuthModal = useCallback(() => {
    setShowRequireAuthTakeover(true);
  }, []);

  const hideAuthModal = useCallback(() => {
    setShowRequireAuthTakeover(false);

    const isPageAuthRequired = !NON_AUTH_REQUIRED_ROUTES.includes(
      router.pathname
    );

    if (isPageAuthRequired) {
      router.push("/");
    } else {
      router.replace({
        query: undefined,
      });
    }
  }, [router]);

  const requireAuthContext = {
    showAuthModal,
    hideAuthModal,
  };

  return (
    <RequireAuthContext.Provider value={requireAuthContext}>
      {children}
      {showRequireAuthTakeover && <RequireAuthTakeover />}
    </RequireAuthContext.Provider>
  );
}
