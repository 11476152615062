import { SubscriptionPlans } from "@prisma/client";

const ONE_MB = 1024 * 1024;

export enum Features {
  UNLIMITED_REQUESTS = "Unlimited Requests",
  MASTER_QUALITY = "Master Quality",
  MODEL_TRAINING = "Model Training",
  LOCKED_MODELS = "Locked Models",
  SIMALTANEOUS_TRAINING = "Simaltaneous Training",
  VOCAL_FUSION = "Vocal Fusion",
  SELECT_MULTIPLE_MODELS = "Select Multiple Models",
  PRO_TOOLS = "Pro Tools",
}

export const MODEL_TRAINING_MAX_QUOTA = {
  [SubscriptionPlans.free]: 0,
  [SubscriptionPlans.starter]: 2,
  [SubscriptionPlans.pro]: 6,
  [SubscriptionPlans.studio]: 30,
  [SubscriptionPlans.label]: 100,
};

export const INFERENCE_MAX_QUOTA: Record<SubscriptionPlans, { limit: number }> =
  {
    [SubscriptionPlans.free]: { limit: 3 },
    [SubscriptionPlans.starter]: {
      limit: Infinity,
    },
    [SubscriptionPlans.pro]: {
      limit: Infinity,
    },
    [SubscriptionPlans.studio]: {
      limit: Infinity,
    },
    [SubscriptionPlans.label]: {
      limit: Infinity,
    },
  };

export const MAX_FILE_SIZE: Record<SubscriptionPlans, number> = {
  [SubscriptionPlans.free]: 20 * ONE_MB,
  [SubscriptionPlans.starter]: 50 * ONE_MB,
  [SubscriptionPlans.pro]: 100 * ONE_MB,
  [SubscriptionPlans.studio]: 150 * ONE_MB,
  [SubscriptionPlans.label]: 200 * ONE_MB,
};

export const ENABLED_PLANS_BY_FEATURE: Record<Features, SubscriptionPlans[]> = {
  [Features.UNLIMITED_REQUESTS]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.MASTER_QUALITY]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.MODEL_TRAINING]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.LOCKED_MODELS]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.SIMALTANEOUS_TRAINING]: [
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.VOCAL_FUSION]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.SELECT_MULTIPLE_MODELS]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
  [Features.PRO_TOOLS]: [
    SubscriptionPlans.starter,
    SubscriptionPlans.pro,
    SubscriptionPlans.studio,
    SubscriptionPlans.label,
  ],
};
