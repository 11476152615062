import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    // Base secrets
    NODE_ENV: z.enum(["development", "preview", "production"]),
    VERCEL_ENV: z.enum(["development", "preview", "production"]),
    PORT:
      process.env.VERCEL_ENV === "development"
        ? z.string().min(1)
        : z.string().min(1).optional(),
    VERCEL_URL: z.string().optional(),

    // Auth secrets
    NEXTAUTH_URL: z.preprocess(
      (str) => process.env.VERCEL_URL ?? str,
      process.env.VERCEL ? z.string().min(1) : z.string().url()
    ),
    NEXTAUTH_SECRET:
      process.env.VERCEL_ENV === "production"
        ? z.string().min(1)
        : z.string().min(1).optional(),
    GOOGLE_CLIENT_ID: z.string().min(1),
    GOOGLE_CLIENT_SECRET: z.string().min(1),

    // Database secrets
    DATABASE_URL: z.string().url(),
    DIRECT_URL: z.string().url(),
    REDIS_HOST: z.string().min(1),
    REDIS_PORT: z.string().min(1),
    REDIS_PASSWORD: z.string().min(1),

    // Paddle secrets
    PADDLE_API_KEY: z.string().min(1),
    PADDLE_WEBHOOK_SECRET: z.string().min(1),

    // Stripe secrets
    STRIPE_SECRET_KEY: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),

    // R2 secrets
    R2_ACCOUNT_ID: z.string().min(1),
    R2_ACCESS_KEY_ID: z.string().min(1),
    R2_SECRET_ACCESS_KEY: z.string().min(1),

    // Email secrets
    EMAIL_USERNAME: z.string().min(1),
    EMAIL_PASSWORD: z.string().min(1),
    EMAIL_FROM: z.string().min(1),

    // Customer IO secrets
    CUSTOMER_IO_SITE_ID: z.string().min(1),
    CUSTOMERIO_KEY: z.string().min(1),
    CUSTOMERIO_APP_KEY: z.string().min(1),

    // Airtable
    AIRTABLE_TOKEN: z.string().min(1),

    // Tinybird
    TINYBIRD_API_KEY: z.string().min(1),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_VERCEL_ENV: z.enum(["development", "preview", "production"]),
    NEXT_PUBLIC_MODAL_DEV: z.string().optional(),
    NEXT_PUBLIC_PADDLE_TOKEN: z.string(),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().optional(),
    NEXT_PUBLIC_RETAIN_TOKEN: z.string().optional(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    // Base secrets
    NODE_ENV: process.env.NODE_ENV,
    VERCEL_ENV: process.env.VERCEL_ENV,
    VERCEL_URL: process.env.VERCEL_URL,
    PORT: process.env.PORT,

    // Auth secrets
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,

    // Database secrets
    DATABASE_URL: process.env.DATABASE_URL,
    DIRECT_URL: process.env.DIRECT_URL,
    REDIS_HOST: process.env.REDIS_HOST,
    REDIS_PORT: process.env.REDIS_PORT,
    REDIS_PASSWORD: process.env.REDIS_PASSWORD,

    // Paddle secrets
    PADDLE_API_KEY: process.env.PADDLE_API_KEY,
    PADDLE_WEBHOOK_SECRET: process.env.PADDLE_WEBHOOK_SECRET,

    // Stripe secrets
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,

    // R2 secrets
    R2_ACCOUNT_ID: process.env.R2_ACCOUNT_ID,
    R2_ACCESS_KEY_ID: process.env.R2_ACCESS_KEY_ID,
    R2_SECRET_ACCESS_KEY: process.env.R2_SECRET_ACCESS_KEY,

    // Email secrets
    EMAIL_USERNAME: process.env.EMAIL_USERNAME,
    EMAIL_PASSWORD: process.env.EMAIL_PASSWORD,
    EMAIL_FROM: process.env.EMAIL_FROM,

    // Customer IO secrets
    CUSTOMER_IO_SITE_ID: process.env.CUSTOMER_IO_SITE_ID,
    CUSTOMERIO_KEY: process.env.CUSTOMERIO_KEY,
    CUSTOMERIO_APP_KEY: process.env.CUSTOMERIO_APP_KEY,

    // Airtable
    AIRTABLE_TOKEN: process.env.AIRTABLE_TOKEN,

    // Tinybird
    TINYBIRD_API_KEY: process.env.TINYBIRD_API_KEY,

    // Client-side secrets
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NEXT_PUBLIC_MODAL_DEV: process.env.NEXT_PUBLIC_MODAL_DEV,
    NEXT_PUBLIC_PADDLE_TOKEN: process.env.NEXT_PUBLIC_PADDLE_TOKEN,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_RETAIN_TOKEN: process.env.NEXT_PUBLIC_RETAIN_TOKEN,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
