import { useEffect, useState } from "react";

export default function useIsTablet(windowBreakpoint = 900) {
  /* A hook for isMobile bool which determines if we should switch to mobile-specific layouts

  Usage:
    const isMobile = useIsMobile();

  Args:
    windowBreakpoint: width in pixels of the window to enable isMobile
   */
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [isTablet, setIsTablet] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0 < windowBreakpoint
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsTablet(windowWidth < windowBreakpoint);
  }, [windowWidth, windowBreakpoint]);

  return isTablet;
}
