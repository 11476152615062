import { useState } from "react";

import clsx from "clsx";

import { roobert } from "@musicfy/assets/fonts";
import AdminController from "@musicfy/components/AdminController";
import Navigation from "@musicfy/components/Navigation";
import NotificationBanners from "@musicfy/components/NotificationBanners";

interface INavigationLayoutProps {
  children: React.ReactNode;
  paddingBottom?: number;
}

const NavigationLayout = ({
  children,
}: INavigationLayoutProps): JSX.Element => {
  const [isNavPinned, setIsNavPinned] = useState<boolean>(true);

  return (
    <div className={`${roobert.variable} font-sans xl:flex max-w-screen`}>
      <AdminController />
      <Navigation
        isNavPinned={isNavPinned}
        toggleNavPinned={() => setIsNavPinned(!isNavPinned)}
      />
      <div className="flex-1 flex flex-col min-h-screen min-w-0 relative">
        <NotificationBanners />
        <div
          id="inner-window-container"
          className="absolute w-full left-0 overflow-x-hidden top-0"
        />
        <div
          className={clsx(
            "relative w-full mx-auto z-10 flex-grow flex flex-col xl:p-6 xl:pt-6 p-4 pt-[72px] xl:max-w-screen-xxl"
          )}
        >
          {children}
        </div>
        <div
          id="global-audio-player-container"
          className="sticky bottom-0 w-full z-50"
        />
      </div>
      <div
        id="sidebar-container"
        className="hidden flex-shrink xl:block sticky top-0 h-screen overflow-y-auto overflow-x-hidden bg-gradient-to-b rounded-l-8 from-teal-dark/60 to-teal-dark/30 backdrop-blur-lg z-10"
      ></div>
    </div>
  );
};

export default NavigationLayout;
