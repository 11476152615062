import axios from "axios";
import { type InputWithMeta } from "client-zip";

import { type IAudioSource } from "@musicfy/libs/GlobalAudioPlayerProvider/types";

const downloadFromAnchor = ({ url, blob }: { url?: string; blob?: Blob }) => {
  const anchor = document.createElement("a");
  const objectUrl = url ?? URL.createObjectURL(blob as Blob);
  anchor.download = blob ? "musicfy-generations.zip" : "";
  anchor.href = objectUrl;
  anchor.click();
  anchor.remove();
  URL.revokeObjectURL(objectUrl);
};

export const downloadFile = async ({
  url,
  audioSources,
}: {
  url?: string;
  audioSources?: IAudioSource[];
}) => {
  if (url) {
    downloadFromAnchor({ url });
  } else if (audioSources) {
    try {
      const files: InputWithMeta[] = await Promise.all(
        audioSources.map(async (item) => {
          return {
            name: `${item.artist ? item.artist + "-" : ""}${
              item.label as string
            }-${item.filename as string}`,
            input: (
              await axios.get(
                `https://corsproxy.io/?${encodeURIComponent(item.value)}`,
                { responseType: "blob" }
              )
            ).data as Response,
          };
        })
      );
      const { downloadZip } = await import("client-zip");
      downloadFromAnchor({ blob: await downloadZip(files).blob() });
    } catch (err) {
      console.error(err);
    }
  }
};
