import CloseIcon from "@mui/icons-material/Close";
import MUIDialog from "@mui/material/Dialog";
import type { DialogProps } from "@mui/material/Dialog";

import { roobert } from "@musicfy/assets/fonts";

interface IDialogProps extends DialogProps {
  onBackClick?: () => void;
  dialogStyle?: React.CSSProperties;
  dialogClassName?: string;
  hideX?: boolean;
  title?: string;
  TitleIcon?: React.ReactNode;
  subtitle?: string;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  width?: string;
}

const Dialog: React.FC<IDialogProps> = ({
  children,
  open,
  onClose,
  title,
  TitleIcon,
  subtitle,
  primaryButton,
  secondaryButton,
  width = "auto",
  ...dialogProps
}) => {
  const hasButtonComponent = !!primaryButton || !!secondaryButton;

  return (
    <MUIDialog
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...dialogProps}
      open={open}
      onClose={onClose}
      className={`${roobert.variable} font-sans backdrop-blur-md`}
      sx={{
        "& .MuiDialog-paper": {
          width: width,
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: "12px",
          color: "white",
        },
      }}
    >
      <div className="relative bg-gray-1000/70 backdrop-blur-3xl justify-center border border-pink-200/60 rounded-12 text-14 text-white">
        <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
          <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
          <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
          <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
          <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
          {!!TitleIcon && (
            <div className="flex items-center z-20 relative">{TitleIcon}</div>
          )}
          <div className="font-bold relative text-18 z-20">{title}</div>
          <div
            aria-label="Close Dialog"
            role="button"
            tabIndex={0}
            onClick={(e) => onClose?.(e, "escapeKeyDown")}
            className="flex z-20 ml-auto h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white/10 transition-all xl:hover:-bg-white/10 xl:hover:bg-white/20"
          >
            <CloseIcon className="!text-16" />
          </div>
        </div>

        {subtitle && (
          <div className="p-4 lg:p-6 text-gray-100 text-16">{subtitle}</div>
        )}
        {children && <div className="p-4 lg:p-6">{children}</div>}
        <div className="p-4 lg:p-6">
          {hasButtonComponent && (
            <div className="mt-4 flex flex-wrap items-center justify-between gap-3">
              {!!secondaryButton && (
                <div className="flex-1">{secondaryButton}</div>
              )}
              <div className="flex-1">{!!primaryButton && primaryButton}</div>
            </div>
          )}
        </div>
      </div>
    </MUIDialog>
  );
};

export default Dialog;
