import { useEffect, useState } from "react";

export default function useIsMobile(windowBreakpoint = 450) {
  /* A hook for isMobile bool which determines if we should switch to mobile-specific layouts

  Usage:
    const isMobile = useIsMobile();

  Args:
    windowBreakpoint: width in pixels of the window to enable isMobile
   */
  const [windowWidth, setWindowWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);
      setIsMobile(window.innerWidth < windowBreakpoint);

      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [windowBreakpoint]);

  useEffect(() => {
    setIsMobile(windowWidth < windowBreakpoint);
  }, [windowWidth, windowBreakpoint]);

  return isMobile;
}
