import { useCallback, useEffect, useState } from "react";

interface IUseAudioPlayerOptions {
  onPlay?: () => void;
  onPause?: () => void;
  onLoad?: (audio: HTMLAudioElement) => void;
  onAudioEnd?: () => void;
  onError?: () => void;
}

const DEFAULT_VOLUME = 0.5;

const useAudioPlayer = (
  audioSrc: string,
  lazyLoad = false,
  autoPlay = false,
  options: IUseAudioPlayerOptions = {}
) => {
  const { onPlay, onPause, onLoad, onAudioEnd, onError } = options;

  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolumeState] = useState(DEFAULT_VOLUME); // Default volume

  const play = useCallback(() => {
    if (!audio && lazyLoad) {
      setAudio(new Audio(audioSrc));
      setIsLoading(true);
      return;
    }
    if (!audio) {
      return;
    }
    if (audio.currentTime === audio.duration) {
      audio.currentTime = 0;
    }
    audio.play();
    onPlay?.();
    setIsPlaying(true);
  }, [audio, audioSrc, lazyLoad, onPlay]);

  const pause = useCallback(() => {
    if (!audio) {
      return;
    }
    audio.pause();
    onPause?.();
    setIsPlaying(false);
  }, [audio, onPause]);

  const handleLoadedMetadata = useCallback(() => {
    if (!audio) {
      return;
    }
    setIsLoading(false);
    onLoad?.(audio);
    if (audio.duration === Infinity) {
      audio.currentTime = 10000000;
      setTimeout(() => {
        audio.currentTime = 0;
      }, 0);
    }
    audio.volume = DEFAULT_VOLUME; // Use state volume
    setDuration(audio.duration);

    if (autoPlay || lazyLoad) {
      play();
    }
  }, [audio, autoPlay, lazyLoad, onLoad, play]);

  const updateTime = useCallback(
    (time: number) => {
      if (!audio || time > audio.duration) {
        return;
      }
      audio.currentTime = time;
    },
    [audio]
  );

  const handleTimeUpdate = useCallback(() => {
    if (!audio) {
      return;
    }
    setCurrentTime(audio.currentTime);
  }, [audio]);

  const getCurrentTime = useCallback(() => {
    if (!audio) {
      return 0;
    }
    return audio.currentTime;
  }, [audio]);

  const handleAudioEnd = useCallback(() => {
    updateTime(0);
    setIsPlaying(false);
    onAudioEnd?.();
  }, [onAudioEnd, updateTime]);

  const handleAudioError = useCallback(() => {
    onError?.();
    setIsLoading(false);
    setIsPlaying(false);
    setAudio(null);
    pause();
  }, [onError, pause]);

  const togglePlayPause = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!audio && lazyLoad) {
        play();
        return;
      }
      if (!audio) {
        return;
      }
      if (audio.paused) {
        play();
      } else {
        pause();
      }
    },
    [audio, lazyLoad, pause, play]
  );

  const restart = useCallback(() => {
    if (!audio) {
      return;
    }
    audio.currentTime = 0;
    play();
  }, [audio, play]);

  const setVolume = useCallback(
    (newVolume: number) => {
      if (!audio) {
        return;
      }
      audio.volume = newVolume;
      setVolumeState(newVolume);
    },
    [audio]
  );

  useEffect(() => {
    if (lazyLoad) {
      return;
    }
    setIsLoading(true);
    pause();
    setAudio(new Audio(audioSrc));
    return () => {
      audio && audio.pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSrc]);

  useEffect(() => {
    if (!audio) {
      return;
    }
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("canplaythrough", handleLoadedMetadata);
    audio.addEventListener("ended", handleAudioEnd);
    audio.addEventListener("error", handleAudioError);
    audio.load();
    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("canplaythrough", handleLoadedMetadata);
      audio.removeEventListener("ended", handleAudioEnd);
      audio.removeEventListener("error", handleAudioError);
      audio.pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return {
    audio,
    isLoading,
    play,
    pause,
    restart,
    togglePlayPause,
    updateTime,
    currentTime,
    duration,
    getCurrentTime,
    isPlaying,
    volume,
    setVolume,
  };
};

export default useAudioPlayer;
