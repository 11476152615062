import React, { useCallback, useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import Image from "next/image";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";

import AccountSettings from "@musicfy/components/AccountSettings";
import Button from "@musicfy/components/Button";
import { FEATURE_ROUTES } from "@musicfy/components/Navigation/constants";
import Popover from "@musicfy/components/Popover";
import { useIsTablet } from "@musicfy/utils/hooks";

const AccountSettingsPopover: React.FC<{
  anchorEl: HTMLDivElement | null;
  open: boolean;
  onClose: () => void;
  onSettingsClick: () => void;
}> = ({ anchorEl, onClose, open, onSettingsClick }) => {
  const isTablet = useIsTablet();

  return (
    <Popover
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        isTablet
          ? {
              vertical: "bottom",
              horizontal: "right",
            }
          : {
              vertical: "bottom",
              horizontal: 12,
            }
      }
      transformOrigin={
        isTablet
          ? {
              vertical: -12,
              horizontal: 100,
            }
          : {
              vertical: "top",
              horizontal: "left",
            }
      }
    >
      <div role="button" tabIndex={0} onClick={onSettingsClick}>
        <div className="p-2 cursor-pointer flex items-center gap-2">
          <SettingsIcon />
          <div>Settings</div>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => signOut({ callbackUrl: "/" })}
        className="p-2 cursor-pointer flex items-center gap-2"
      >
        <LogoutIcon />
        <div>Logout</div>
      </div>
    </Popover>
  );
};

const UserAccount: React.FC<{
  setIsNavOpen?: (isOpen: boolean) => void;
  googleButtonClassName?: string;
}> = ({ setIsNavOpen }) => {
  const { data } = useSession();
  const router = useRouter();

  const accountSettingsAnchorElRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] =
    useState<boolean>(false);

  const onAccountSettingsClick = useCallback(() => {
    setIsPopoverOpen(false);
    setIsAccountSettingsOpen(true);
  }, []);

  const user = data?.user;

  if (!user) {
    return (
      <Button
        variant="outlined"
        className="!min-h-fit !py-2 !text-white !rounded-full"
        path={`${FEATURE_ROUTES.login}?callbackPath=${router.asPath}`}
      >
        Sign In
      </Button>
    );
  }

  return (
    <>
      <div
        aria-label="User Account Settings"
        role="button"
        tabIndex={0}
        onClick={() => setIsPopoverOpen(true)}
        className="cusor-pointer overflow-hidden max-w-full relative flex cursor-pointer items-center justify-between gap-4 transition-all duration-300 xl:hover:outline-gray-300 xl:w-fit xl:justify-start"
      >
        <div className="flex items-center max-w-full truncate">
          <div
            className="flex-shrink flex items-center flex-grow-0 truncate"
            ref={accountSettingsAnchorElRef}
          >
            <ArrowDropDownIcon className="mr-1" />
            <div className="truncate text-14 xl:block hidden">{user.name}</div>
          </div>
          <div className="flex-grow flex-shrink-0 ml-2">
            {!!user.image ? (
              <Image
                unoptimized
                src={user?.image ?? ""}
                alt="User Avatar"
                width={32}
                height={32}
                referrerPolicy="no-referrer"
                className="rounded-full transition-all relative before:absolute before:left-0 before:top-0 before:h-8 before:w-8 before:rounded-4 before:bg-purple before:content-['']"
              />
            ) : (
              <div className="rounded-full font-bold flex items-center justify-center w-10 h-10 bg-purple">
                {user.name?.charAt(0) ?? ""}
              </div>
            )}
          </div>
        </div>
      </div>
      <AccountSettingsPopover
        anchorEl={accountSettingsAnchorElRef.current}
        open={isPopoverOpen}
        onSettingsClick={onAccountSettingsClick}
        onClose={() => {
          setIsPopoverOpen(false);
          setIsNavOpen?.(false);
        }}
      />
      <AccountSettings
        isOpen={isAccountSettingsOpen}
        onClose={() => setIsAccountSettingsOpen(false)}
      />
    </>
  );
};

export default UserAccount;
