import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import clsx from "clsx";

import { QUESTIONS } from "./constants";

const Row = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={clsx("transition-all py-4 border-b", {
        "border-white": isOpen,
        "border-white/20 xl:hover:border-white/80": !isOpen,
      })}
    >
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        role="button"
        tabIndex={0}
        className="flex items-center gap-4 cursor-pointer"
      >
        {isOpen ? (
          <RemoveIcon className="!text-pink-200" />
        ) : (
          <AddIcon className="!text-pink-200" />
        )}
        <div>{question}</div>
      </div>
      <div
        className={clsx(
          "overflow-hidden transition-all border-l-2 px-4 ml-12 py-1 text-white/70 border-pink-200",
          {
            "max-h-[200px] opacity-100 mt-4 mb-2": isOpen,
            "max-h-0 opacity-0 mt-0 mb-0": !isOpen,
          }
        )}
        dangerouslySetInnerHTML={{
          __html: answer,
        }}
      ></div>
    </div>
  );
};

const FAQ = (): JSX.Element => {
  return (
    <div>
      <div className="text-38 font-semibold">FAQ</div>
      <div className="mt-4">
        {QUESTIONS.map(({ question, answer }) => {
          return <Row key={question} question={question} answer={answer} />;
        })}
      </div>
    </div>
  );
};

export default FAQ;
