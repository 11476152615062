import Script from "next/script";

import { env } from "@musicfy/env.mjs";
import { getLocaleLanguage } from "@musicfy/utils";

import { PADDLE_TARGET_FRAME } from "../libs/PaddleProvider";
import { type PaddleEventCallback } from "../libs/PaddleProvider/types";
import { useSubscriptionContext } from "../libs/SubscriptionProvider";

const PADDLE_ENV =
  env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "production" : "sandbox";
const PADDLE_TOKEN = env.NEXT_PUBLIC_PADDLE_TOKEN;

const PaddleLoader = ({
  eventCallback,
  onSetup,
}: {
  eventCallback: PaddleEventCallback;
  onSetup?: () => void;
}) => {
  const { subscription } = useSubscriptionContext();

  const isUserSubscribed = !!subscription && subscription.plan !== "free";

  const profitWellUser = `'user_id': '${subscription?.customerId || ""}'`;

  return (
    <>
      <Script
        id="paddle-js"
        src="https://cdn.paddle.com/paddle/v2/paddle.js"
        onLoad={() => {
          if (!window.Paddle) {
            return;
          }
          window.Paddle.Environment.set(PADDLE_ENV);
          window.Paddle.Setup({
            token: PADDLE_TOKEN,
            eventCallback: eventCallback,
            pwAuth: env.NEXT_PUBLIC_RETAIN_TOKEN,
            ...(isUserSubscribed
              ? {
                  pwCustomer: {
                    id: subscription.customerId,
                  },
                }
              : {}),
            checkout: {
              settings: {
                theme: "dark",
                showAddDiscounts: true,
                showAddTaxId: true,
                locale: getLocaleLanguage(),
                displayMode: "inline",
                frameTarget: PADDLE_TARGET_FRAME,
                frameInitialHeight: 680,
                frameStyle:
                  "width: 100%; scale: .75; min-width: 312px; background-color: transparent; border: none;",
              },
            },
          });
          onSetup?.();
        }}
      />
      {!!subscription && (
        <Script
          id="profitwell-js"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
          (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
          a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
          s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
          })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');

          profitwell('start', { ${profitWellUser} });
          `,
          }}
          data-pw-auth="b0258f5e80576476f282866074cfbc6d"
        />
      )}
    </>
  );
};

export default PaddleLoader;
